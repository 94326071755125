<div
  class="ui inverted dimmer"
  [class.active]="loading == true"
  style="z-index: 1"
>
  <div class="ui medium text loader">Loading</div>
</div>
<form id="mainForm" class="ui raised segment">
  <toolbar-plugin
    [modeToolbar]="modeToolbar"
    [settings]="settingToolbar"
    [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons"
    (eventEmitterClick)="toolbarEvent($event)"
  ></toolbar-plugin>

  <div id="container">
    <dialog-plugin
      [settings]="dialog"
      (eventDialog)="eventMessage($event)"
    ></dialog-plugin>
    <form class="ui form">
      <br />

      <div
        class="ui raised segment"
        style="
          height: auto;
          margin-bottom: 0px;
          margin-right: 0px;
          margin-top: -10px;
        "
      >
        <label class="ui red ribbon small label"
          >Container Load List (CLL)</label
        >
        <br /><br />
        <!-- form -->
        <!--div class="ui equal width padded grid"-->
        <div class="field" style="margin-bottom: 5px">
          <div class="fields" style="margin-bottom: 0px">
            <div
              class="four wide field"
              [class.error]="
                modelForm['error-vesselName'] != null &&
                modelForm['error-vesselName'] != ''
              "
            >
              <label>Vessel</label>
              <combo-plugin
                #cbVessel
                [settings]="settingVessel"
                (change)="changeEventVessel($event)"
                (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                (paste)="errorVesVoy($event, 'vesselName', 'modelForm')"
              ></combo-plugin>
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  modelForm['error-vesselName'] != null &&
                  modelForm['error-vesselName'] != ''
                "
              >
                {{ modelForm["error-vesselName"] }}
              </div>
            </div>

            <div
              class="four wide field"
              [class.error]="
                modelForm['error-baseVoyage'] != null &&
                modelForm['error-baseVoyage'] != ''
              "
            >
              <label>Voyage</label>
              <combo-plugin
                #cbVoyage
                [settings]="settingVoyage"
                (change)="changeEventVoyage($event)"
                (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                (paste)="errorVesVoy($event, 'baseVoyage', 'modelForm')"
              ></combo-plugin>
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  modelForm['error-baseVoyage'] != null &&
                  modelForm['error-baseVoyage'] != ''
                "
              >
                {{ modelForm["error-baseVoyage"] }}
              </div>
            </div>

            <div class="four wide field">
              <div class="fields" style="margin-bottom: 0px">
                <div class="eight wide field">
                  <label>Bound</label>
                  <input
                    #cllBound
                    list="bound"
                    name="bound"
                    placeholder="Bound"
                    [(ngModel)]="modelForm['vesselBound']"
                    (change)="updateVoyageUrl('header')"
                    (keypress)="OIMonly($event)"
                    maxlength="1"
                    required
                    autocomplete="off"
                  />
                  <datalist id="bound">
                    <option value="O"></option>
                    <option value="I"></option>
                    <option value="M"></option>
                  </datalist>
                </div>
              </div>
            </div>

            <div class="four wide field">
              <label>ETD (Load Date @ POL)</label>
              <input
                type="text"
                name="etd"
                placeholder="ETD"
                [(ngModel)]="modelForm['etd']"
                readonly
              />
            </div>
          </div>
        </div>

        <div class="field" style="margin-bottom: 5px">
          <div class="fields" style="margin-bottom: 0px">
            <div class="four wide field">
              <label>Port Of Load</label>
              <combo-plugin
                #pol
                [settings]="settingPOL"
                (keypress)="exceptApos($event)"
                (change)="getDataCombo($event, 'form-pol')"
              ></combo-plugin>
            </div>

            <div class="four wide field">
              <label>Port Of Discharge 1</label>
              <combo-plugin
                #pod
                [settings]="settingPOD"
                (keypress)="exceptApos($event)"
                (change)="getDataCombo($event, 'form-pod')"
              ></combo-plugin>
            </div>

            <div class="four wide field">
              <label>Final Destination</label>
              <combo-plugin
                #fd
                [settings]="settingFD"
                (keypress)="exceptApos($event)"
                (change)="getDataCombo($event, 'form-fdest')"
              ></combo-plugin>
            </div>

            <div class="four wide field">
              <label>ETA (@ POD)</label>
              <input
                type="text"
                name="eta"
                placeholder="ETA"
                [(ngModel)]="modelForm['eta']"
                readonly
              />
            </div>
          </div>
        </div>

        <div class="field" style="margin-bottom: 10px">
          <div class="fields" style="margin-bottom: 0px">
            <div class="four wide field">
              <label>Container Number</label>
              <input
                type="text"
                name="cntrNo"
                (keypress)="exceptApos($event)"
                maxlength="14"
                placeholder="Cntr No."
                [(ngModel)]="modelForm['containerNo']"
              />
            </div>

            <div class="four wide field">
              <label>Booking Number</label>
              <input
                type="text"
                name="bNo"
                (keypress)="CharNumOnly($event)"
                maxlength="20"
                placeholder="Booking No"
                [(ngModel)]="modelForm['bookingNo']"
              />
            </div>

            <div class="four wide field">
              <label>Container Operator Code (OP)</label>
              <input
                type="text"
                name="op"
                (keypress)="CharOnly($event)"
                maxlength="4"
                placeholder="OP"
                [(ngModel)]="modelForm['op']"
              />
            </div>

            <div class="four wide field">
              <label>Slot Owner (SO)</label>
              <input
                type="text"
                name="so"
                (keypress)="CharOnly($event)"
                maxlength="4"
                placeholder="SO"
                [(ngModel)]="modelForm['so']"
              />
            </div>

            <!-- div class="four wide field">
                <label>&nbsp;&nbsp;</label>
                <a class="ui medium image label" (click)="fileDonload('cll');">
                    <img src="assets/images/excel.png">
                    Rec. CLL
                </a>
                <a class="ui medium image label" (click)="fileDonload('cell');">
                    <img src="assets/images/excel.png">
                    Rec. Cell Loc.
                </a>
              </div -->
          </div>
        </div>

        <div class="field" style="margin-bottom: 5px">
          <div class="fields" style="margin-bottom: 0px">
            <div class="eight wide field">
              <!-- label>&nbsp;&nbsp;</label -->
               <!--
              <a class="ui medium image label" (click)="fileDonload('cll')">
                <img src="assets/images/excel.png" />
                Rec. CLL
              </a>
              -->
              <a class="ui medium image label" (click)="fileDonload('cell')">
                <img src="assets/images/excel.png" />
                Rec. Cell Loc.
              </a>
              <a
                class="ui medium image label"
                (click)="fileDonload('cllWithISO')"
              >
                <img src="assets/images/excel.png" />
                Rec. CLL With ISO
              </a>
            </div>

            <div class="eight wide field">&nbsp;</div>
          </div>
        </div>

        <button style="display: none"></button>

        <!-- div class="ui divider"></div>

          <div class="ui accordion">
            <div class="title">
              <i class="dropdown icon"></i> Advance
            </div>
            <div class="content">
              .....
            </div>
          </div -->

        <!-- end of form -->

        <!-- grid -->

        <div style="width: 100%">
          <ag-grid-angular
            #nGrid
            style="width: 100%; height: 400px"
            class="ag-theme-balham"
            [rowData]="nGridStore"
            [columnDefs]="nGridColumn"
            [rowSelection]="nGridSelection"
            [processDataFromClipboard]="processDataFromClipboard"
            [enableSorting]="true"
            [enableFilter]="false"
            [enableColResize]="false"
            [enableRangeSelection]="true"
            [rowMultiSelectWithClick]="true"
            [suppressRowClickSelection]="true"
            (gridReady)="onGridReady($event)"
            (rowDoubleClicked)="rowDoubleClick($event)"
            (rowSelected)="onRowSelected($event)"
          >
          </ag-grid-angular>
        </div>

        <!-- grid-plugin id="cllGridContainer" #cllGrid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin -->
        <!-- end of grid -->

        <!-- summary grid -->
        <!-- grid-plugin #sumGrid [settings]="settingSummaryGrid" (gridEvent)="summaryGridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin -->

        <div
          class="ui raised segment"
          style="
            height: auto;
            margin-bottom: 0px;
            margin-right: 0px;
            margin-top: 1px;
          "
        >
          <label class="ui red ribbon small label">Summary</label>
          <div class="ui equal width padded grid">
            <div class="sixteen wide field">
              <br />
              <table border="0" cellspacing="5" width="100%">
                <tbody>
                  <tr>
                    <td width="3%"><b>D20</b></td>
                    <td width="1%">:</td>
                    <td width="2%" align="right" style="padding-right: 40px">
                      {{ totalD20 }}
                    </td>

                    <td width="3%"><b>D40</b></td>
                    <td width="1%">:</td>
                    <td width="2%" align="right" style="padding-right: 40px">
                      {{ totalD40 }}
                    </td>

                    <td width="3%"><b>D45</b></td>
                    <td width="1%">:</td>
                    <td width="2%" align="right" style="padding-right: 40px">
                      {{ totalD45 }}
                    </td>

                    <td width="3%"><b>OT20</b></td>
                    <td width="1%">:</td>
                    <td width="2%" align="right" style="padding-right: 40px">
                      {{ totalOT20 }}
                    </td>

                    <td width="3%"><b>OT40</b></td>
                    <td width="1%">:</td>
                    <td width="2%" align="right" style="padding-right: 40px">
                      {{ totalOT40 }}
                    </td>

                    <td width="3%"><b>OT45</b></td>
                    <td width="1%">:</td>
                    <td width="2%" align="right" style="padding-right: 40px">
                      {{ totalOT45 }}
                    </td>

                    <td width="8%"><b>Total DG</b></td>
                    <td width="1%">:</td>
                    <td width="2%" align="right" style="padding-right: 40px">
                      {{ totalDG }}
                    </td>

                    <td width="8%"><b>Full</b></td>
                    <td width="1%">:</td>
                    <td width="2%" align="right" style="padding-right: 40px">
                      {{ totalFull }}
                    </td>
                  </tr>

                  <tr>
                    <td><b>HC20</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalHC20 }}
                    </td>

                    <td><b>HC40</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalHC40 }}
                    </td>

                    <td><b>HC45</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalHC45 }}
                    </td>

                    <td><b>OTH20</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalOTH20 }}
                    </td>

                    <td><b>OTH40</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalOTH40 }}
                    </td>

                    <td><b>OTH45</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalOTH45 }}
                    </td>

                    <td><b>Total RF</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalRF }}
                    </td>

                    <td><b>Empty</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalEmpty }}
                    </td>
                  </tr>

                  <tr>
                    <td><b>R20</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalR20 }}
                    </td>

                    <td><b>R40</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalR40 }}
                    </td>

                    <td><b>R45</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalR45 }}
                    </td>

                    <td><b>FR20</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalFR20 }}
                    </td>

                    <td><b>FR40</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalFR40 }}
                    </td>

                    <td><b>FR45</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalFR45 }}
                    </td>

                    <td><b>Total OOG</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalOOG }}
                    </td>

                    <td><b>Final Total</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalFinal }}
                    </td>
                  </tr>

                  <tr>
                    <td><b>RH20</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalRH20 }}
                    </td>

                    <td><b>RH40</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalRH40 }}
                    </td>

                    <td><b>RH45</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalRH45 }}
                    </td>

                    <td><b>FRH20</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalFRH20 }}
                    </td>

                    <td><b>FRH40</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalFRH40 }}
                    </td>

                    <td><b>FRH45</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalFRH45 }}
                    </td>

                    <td><b>Total Tank</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalTank }}
                    </td>

                    <td><b>&nbsp;</b></td>
                    <td>&nbsp;</td>
                    <td align="right">&nbsp;</td>
                  </tr>

                  <tr>
                    <td><b>TK20</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalTK20 }}
                    </td>

                    <td><b>TK40</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalTK40 }}
                    </td>

                    <td><b>TK45</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalTK45 }}
                    </td>

                    <td><b>PL20</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalPL20 }}
                    </td>

                    <td><b>PL40</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalPL40 }}
                    </td>

                    <td><b>PL45</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalPL45 }}
                    </td>

                    <td><b>&nbsp;</b></td>
                    <td>&nbsp;</td>
                    <td align="right">&nbsp;</td>

                    <td><b>&nbsp;</b></td>
                    <td>&nbsp;</td>
                    <td align="right">&nbsp;</td>
                  </tr>

                  <tr>
                    <td><b>TKH20</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalTKH20 }}
                    </td>

                    <td><b>TKH40</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalTKH40 }}
                    </td>

                    <td><b>TKH45</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalTKH45 }}
                    </td>

                    <td><b>PLH20</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalPLH20 }}
                    </td>

                    <td><b>PLH40</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalPLH40 }}
                    </td>

                    <td><b>PLH45</b></td>
                    <td>:</td>
                    <td align="right" style="padding-right: 40px">
                      {{ totalPLH45 }}
                    </td>

                    <td><b>&nbsp;</b></td>
                    <td>&nbsp;</td>
                    <td align="right">&nbsp;</td>

                    <td><b>&nbsp;</b></td>
                    <td>&nbsp;</td>
                    <td align="right">&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- end of summary grid -->
      </div>
    </form>
  </div>
  <!-- end div container -->
</form>

<!-- dialog New Booking -->
<div id="newDialog" style="display: none; z-index: 101">
  <div
    class="ui inverted dimmer"
    [class.active]="loadingDialog == true"
    style="z-index: 1"
  >
    <div class="ui medium text loader">Loading</div>
  </div>

  <div class="content">
    <form class="ui form">
      <button style="display: none"></button>
      <div id="firstForm">
        <div class="ui equal width padded grid">
          <div class="four wide field">
            <label class="cs-lbl">Booking No</label>
            <!-- combo-plugin #newCBBooking [settings]="settingBooking" (change)="changeEventBooking($event)"></combo-plugin -->
            <input
              #mnBNo
              type="text"
              name="bNo"
              (keypress)="CharNumOnly($event)"
              maxlength="20"
              [(ngModel)]="modelNew['bNo']"
              autocomplete="off"
            />
          </div>
          <div class="four wide field">
            <button
              class="ui tiny button"
              style="
                font-size: 12px;
                margin-top: 27px;
                margin-left: -21px;
                margin-right: 5px;
              "
              (click)="process('ok')"
            >
              Ok
            </button>
            <button
              class="ui tiny button"
              style="
                font-size: 12px;
                margin-top: 27px;
                margin-left: -4px;
                padding-right: 6px;
                padding-left: 10px;
              "
              (click)="closeNewContainerLoadList('newDialog')"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      <div id="secondForm">
        <div class="ui buttons">
          <button
            class="ui button positive"
            style="font-size: 10px"
            (click)="saveNewContainerLoadList()"
          >
            Save
          </button>
          <button
            class="ui button"
            style="font-size: 10px; margin-left: 5px"
            (click)="closeNewContainerLoadList('newDialog')"
          >
            Cancel
          </button>
        </div>

        <div
          class="ui basic red prompt label transition error-message-hidden"
          [class.error-message-visible]="errorFormMessage != ''"
          style="margin-top: 10px"
        >
          <span [innerHTML]="errorFormMessage"></span>
        </div>

        <div
          class="ui top attached tabular menu"
          style="background-color: beige"
        >
          <a class="item active" data-tab="ci" style="font-size: 10px"
            >Container Information</a
          >
          <a class="item" data-tab="sa" style="font-size: 10px"
            >Special Arrangement</a
          >
        </div>

        <div
          id="secondFormContent1"
          class="ui bottom attached tab segment active"
          data-tab="ci"
        >
          <div class="ui equal width padded grid">
            <div class="inline fields">
              <div class="five wide field" style="margin-right: 10px">
                <label>OP</label>
                <input
                  #opTf
                  type="text"
                  name="op"
                  (keypress)="CharOnly($event)"
                  [(ngModel)]="modelNew['operatorCode']"
                  maxlength="4"
                  (change)="textFieldEvent('op', opTf.value)"
                />
              </div>
              <div class="five wide field">
                <label>SO</label>
                <input
                  type="text"
                  name="so"
                  (keypress)="CharNumOnly($event)"
                  [(ngModel)]="modelNew['inSlot']"
                  maxlength="4"
                />
              </div>
            </div>
            <div class="fourteen wide field" style="padding-bottom: 8px">
              <grid-plugin
                id="newGridBox"
                #newGrid
                [settings]="settingNewGrid"
                (gridEvent)="newGridEvent($event)"
                (infoGrid)="infoGrid($event)"
              ></grid-plugin>
            </div>
            <div class="two wide field" style="margin-left: -25px">
              <div
                class="ui vertical icon tiny buttons"
                style="vertical-align: top; margin-bottom: 100px"
              >
                <button
                  id="addContainer"
                  class="ui button"
                  (click)="addContainer2()"
                  style="margin-bottom: 5px"
                >
                  <i class="plus icon"></i>
                </button>
                <button class="ui button" (click)="removeContainer()">
                  <i class="minus icon"></i>
                </button>
              </div>
            </div>
          </div>

          <button style="display: none"></button>

          <div class="ui equal width padded grid">
            <!-- line 1 -->
            <div
              class="four wide field"
              [class.error]="
                modelNew['error-vesselName'] != null &&
                modelNew['error-vesselName'] != ''
              "
            >
              <label class="cs-lbl">Vessel Name</label>
              <combo-plugin
                #newCbVessel
                [settings]="settingVesselNew"
                (keypress)="exceptApos($event)"
                (change)="changeEventVesselNew($event)"
                (paste)="errorVesVoy($event, 'vesselName', 'modelNew')"
              ></combo-plugin>
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  modelNew['error-vesselName'] != null &&
                  modelNew['error-vesselName'] != ''
                "
              >
                {{ modelNew["error-vesselName"] }}
              </div>
            </div>
            <div
              class="four wide field"
              [class.error]="
                modelNew['error-baseVoyage'] != null &&
                modelNew['error-baseVoyage'] != ''
              "
            >
              <label class="cs-lbl">Voyage</label>
              <combo-plugin
                #newCbVoyage
                [settings]="settingVoyageNew"
                (keypress)="exceptApos($event)"
                (change)="changeEventVoyageNew($event)"
                (paste)="errorVesVoy($event, 'baseVoyage', 'modelNew')"
              ></combo-plugin>
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  modelNew['error-baseVoyage'] != null &&
                  modelNew['error-baseVoyage'] != ''
                "
              >
                {{ modelNew["error-baseVoyage"] }}
              </div>
            </div>
            <div class="four wide field">
              <label class="cs-lbl">Bkg Ref.</label>
              <input
                type="text"
                name="b"
                (keypress)="CharNumOnly($event)"
                maxlength="20"
                [(ngModel)]="modelNew['bNo']"
                placeholder="Bkg Ref"
                [readonly]="bkgRefMode"
              />
            </div>
            <div class="four wide field">
              <label class="cs-lbl">BL Ref.</label>
              <input
                type="text"
                name="bl"
                (keypress)="CharNumOnly($event)"
                maxlength="20"
                [(ngModel)]="modelNew['blNo']"
                placeholder="BL Ref"
                readonly
              />
            </div>
            <!-- end of Line 1 -->

            <!-- line 2 -->
            <div class="four wide field required">
              <label class="cs-lbl" style="color: red">Port of Load</label>
              <combo-plugin
                #newPol
                [settings]="settingNewPOL"
                (keypress)="exceptApos($event)"
                (change)="getDataCombo($event, 'POL')"
              ></combo-plugin>
            </div>
            <div class="four wide field">
              <label class="cs-lbl">Port of Discharge 1</label>
              <combo-plugin
                #newPod1
                [settings]="settingNewPOD1"
                (keypress)="exceptApos($event)"
                (change)="getDataCombo($event, 'POD1')"
              ></combo-plugin>
            </div>
            <div class="four wide field">
              <label class="cs-lbl">Port of Discharge 2</label>
              <combo-plugin
                #newPod2
                [settings]="settingNewPOD2"
                (keypress)="exceptApos($event)"
                (change)="getDataCombo($event, 'POD2')"
              ></combo-plugin>
            </div>
            <div class="four wide field">
              <label class="cs-lbl">Port of Discharge 3</label>
              <combo-plugin
                #newPod3
                [settings]="settingNewPOD3"
                (keypress)="exceptApos($event)"
                (change)="getDataCombo($event, 'POD3')"
              ></combo-plugin>
            </div>
            <!-- end of Line 2 -->

            <!-- line 3 -->
            <div class="four wide field">
              <label class="cs-lbl">Port of Tranship</label>
              <combo-plugin
                #pot
                [settings]="settingPOT"
                (keypress)="exceptApos($event)"
                (change)="getDataCombo($event, 'POT')"
              ></combo-plugin>
            </div>
            <div class="four wide field">
              <label class="cs-lbl">Final Destination</label>
              <combo-plugin
                #newFd
                [settings]="settingNewFD"
                (keypress)="exceptApos($event)"
                (change)="getDataCombo($event, 'FDest')"
              ></combo-plugin>
            </div>
            <div
              class="four wide field"
              [class.error]="
                modelNew['error-nextVesselCode'] != null &&
                modelNew['error-nextVesselCode'] != ''
              "
            >
              <label class="cs-lbl">Connecting Vessel</label>
              <input
                type="text"
                name="cvessel"
                (keypress)="exceptApos($event)"
                maxlength="50"
                [(ngModel)]="modelNew['nextVesselCode']"
                placeholder="connecting vessel"
                (ngModelChange)="checkIfError('nextVesselCode', 'modelNew')"
                (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                (paste)="errorVesVoy($event, 'nextVesselCode', 'modelNew')"
              />
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  modelNew['error-nextVesselCode'] != null &&
                  modelNew['error-nextVesselCode'] != ''
                "
              >
                {{ modelNew["error-nextVesselCode"] }}
              </div>
            </div>
            <div
              class="four wide field"
              [class.error]="
                modelNew['error-nextVoyage'] != null &&
                modelNew['error-nextVoyage'] != ''
              "
            >
              <label class="cs-lbl">Connecting Voyage</label>
              <input
                type="text"
                name="vvoyage"
                (keypress)="exceptApos($event)"
                maxlength="20"
                [(ngModel)]="modelNew['nextVoyage']"
                placeholder="connecting voyage"
                (ngModelChange)="checkIfError('nextVoyage', 'modelNew')"
                (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                (paste)="errorVesVoy($event, 'nextVoyage', 'modelNew')"
              />
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  modelNew['error-nextVoyage'] != null &&
                  modelNew['error-nextVoyage'] != ''
                "
              >
                {{ modelNew["error-nextVoyage"] }}
              </div>
            </div>
            <!-- end of Line 3 -->

            <!-- line 4 -->
            <div class="four wide field">
              <label class="cs-lbl">Status (F/E)</label>
              <div
                id="feCombo"
                class="ui selection dropdown"
                [class.disabled]="lock"
                style="font-size: 10px; margin-top: -5px"
              >
                <input
                  type="hidden"
                  name="dtFE"
                  [(ngModel)]="modelNew['status']"
                />
                <i class="dropdown icon"></i>
                <div class="default text" style="font-size: 10px">Select</div>
                <div class="menu">
                  <div class="item" style="font-size: 10px" data-value="FULL">
                    F
                  </div>
                  <div class="item" style="font-size: 10px" data-value="EMPTY">
                    E
                  </div>
                </div>
              </div>
            </div>
            <div class="four wide field">
              <label class="cs-lbl">Tranship (Y/N)</label>
              <div
                id="tsCombo"
                class="ui selection dropdown"
                [class.disabled]="lock"
                style="font-size: 10px; margin-top: -5px"
              >
                <input
                  type="hidden"
                  name="dtTS"
                  [(ngModel)]="modelNew['tranship']"
                />
                <i class="dropdown icon"></i>
                <div class="default text" style="font-size: 10px">Select</div>
                <div class="menu">
                  <div class="item" style="font-size: 10px" data-value="Y">
                    Y
                  </div>
                  <div class="item" style="font-size: 10px" data-value="N">
                    N
                  </div>
                </div>
              </div>
            </div>
            <div class="four wide field">
              <label class="cs-lbl">Thru Shpt (Y/N)</label>
              <div
                id="tuCombo"
                class="ui selection dropdown"
                [class.disabled]="lock"
                style="font-size: 10px; margin-top: -5px"
              >
                <input
                  type="hidden"
                  name="dtThru"
                  [(ngModel)]="modelNew['thruSpt']"
                />
                <i class="dropdown icon"></i>
                <div class="default text" style="font-size: 10px">Select</div>
                <div class="menu">
                  <div class="item" style="font-size: 10px" data-value="Y">
                    Y
                  </div>
                  <div class="item" style="font-size: 10px" data-value="N">
                    N
                  </div>
                </div>
              </div>
            </div>
            <div class="four wide field">
              <label class="cs-lbl">Stack Date/Time</label>
              <!-- calendar-plugin #calSDT id="sDT"  [pluginType]="calendarType" [settings]="settingCalendar"> </calendar-plugin -->
              <input
                id="stdTime"
                type="text"
                name="stdatetime"
                (keypress)="timeOnly($event)"
                maxlength="20"
              />
            </div>

            <!-- line 5 -->
            <div class="four wide field">
              <label class="cs-lbl">Shipper</label>
              <combo-plugin
                id="nShipper"
                #newShipperName
                [settings]="settingShipper"
                (keypress)="exceptApos($event)"
                (change)="getDataCombo($event, 'Shipper')"
              ></combo-plugin>
            </div>
            <div class="four wide field">
              <label class="cs-lbl">Commodity Desc</label>
              <!-- combo-plugin #newCommodity [settings]="settingCommodity" (change)="getDataCombo($event,'Commodity')"></combo-plugin -->
              <input
                type="text"
                name="commodity"
                (keypress)="exceptApos($event)"
                maxlength="70"
                [(ngModel)]="modelNew['commodityName']"
                placeholder="commodity"
              />
            </div>
            <div class="four wide field">
              <label class="cs-lbl">Special Details</label>
              <combo-plugin
                #newSpecialDetail
                [settings]="settingSpecialDetail"
                (keypress)="CharOnly($event)"
                (change)="getDataCombo($event, 'SpecialDetail')"
              ></combo-plugin>
              <!--input type="text" name="specialDetails" [(ngModel)]="modelNew['spDetails']" placeholder="Special Detailstrgf"-->
            </div>
            <div
              class="four wide field ui toggle checkbox"
              style="padding-top: 18px"
            >
              <input
                #isBundle
                type="checkbox"
                name="isBundle"
                [checked]="modelNew.bundle"
                (change)="checkboxEvent('isBundle', isBundle.checked)"
              />
              <label
                class="ui tiny label"
                style="
                  font-size: 10px;
                  background-color: transparent;
                  padding-top: 5px;
                "
                >bundle</label
              >
            </div>
          </div>
        </div>
        <div
          id="secondFormContent2"
          class="ui bottom attached tab segment"
          data-tab="sa"
        >
          <div class="ui equal width padded grid">
            <div class="twelve wide field">
              <fieldset
                width="100%"
                style="
                  border: 1px solid lightgray;
                  padding-bottom: 10px;
                  padding-right: 0px;
                "
              >
                <legend
                  style="font-size: 10px; font-weight: bold; color: dodgerblue"
                >
                  Reefer Information
                </legend>
                <div class="ui equal width padded grid">
                  <div
                    class="six wide field ui toggle checkbox"
                    style="padding-left: 0px; margin-top: 0px"
                  >
                    <input
                      #isReefer
                      type="checkbox"
                      name="isReefer"
                      [checked]="modelNew.reefer"
                      (change)="checkboxEvent('isReefer', isReefer.checked)"
                      [disabled]="modelNew.reeferAsDry || this.lockBoth? true : false"
                    />
                    <label
                      class="ui tiny label"
                      style="
                        font-size: 10px;
                        background-color: transparent;
                        padding-top: 5px;
                      "
                      >RF</label
                    >
                  </div>
                  <div class="ten wide field" style="margin-top: -5px">
                    <div class="inline field">
                      <label class="cs-lbl" style="padding-right: 56px"
                        >Temp</label
                      >
                      <select
                        #tmpSign
                        id="temp-sign"
                        style="font-size: 10px"
                        (change)="selectChange(tmpSign, 'sign')"
                        [disabled]="!modelNew.reefer"
                      >
                        <option style="font-size: 10px" value="+">+</option>
                        <option style="font-size: 10px" value="-">-</option>
                      </select>
                      <!-- input #tempNum type="text" name="tempDegree" [(ngModel)]="modelNew['reeferTemp']" style="width: 88px;height: 27px;margin-left:2px;margin-right:2px;" [disabled]="!modelNew.reefer" (change)="noNegativeWithoutDecimal($event);" (blur)="testFormated(tempNum,3,2,'reefer Temp');" -->
                      <input
                        #tempNum
                        type="text"
                        name="tempDegree"
                        (keypress)="TempOnly($event)"
                        maxlength="6"
                        [(ngModel)]="modelNew['reeferTemp']"
                        style="
                          width: 88px;
                          height: 27px;
                          margin-left: 2px;
                          margin-right: 2px;
                        "
                        [disabled]="!modelNew.reefer"
                        (change)="noNegativeWithoutDecimal($event)"
                      />
                      <select
                        #tmpDegree
                        id="temp-Degreee"
                        style="font-size: 10px"
                        (change)="selectChange(tmpDegree, 'degree')"
                        [disabled]="!modelNew.reefer"
                      >
                        <option style="font-size: 10px" value="C">C</option>
                        <option style="font-size: 10px" value="F">F</option>
                      </select>
                    </div>
                  </div>
                  <div
                    class="six wide field ui toggle checkbox"
                    style="padding-left: 0px; margin-top: 0px"
                  >
                    <input
                      #isReeferAsDry
                      type="checkbox"
                      name="isReeferAsDry"
                      [checked]="modelNew.reeferAsDry"
                      (change)="
                        checkboxEvent('isReeferAsDry', isReeferAsDry.checked)
                      "
                      [disabled]="modelNew.reefer || this.lockBoth? true : false"
                    />
                    <label
                      class="ui tiny label"
                      style="
                        font-size: 10px;
                        background-color: transparent;
                        padding-top: 5px;
                      "
                      >RF as DRY</label
                    >
                  </div>
                  <div class="ten wide field" style="margin-top: -5px">
                    <div class="inline field" style="margin-bottom: -10px">
                      <label class="cs-lbl" style="padding-right: 20px"
                        >Com. Desc</label
                      >
                      <textarea maxlength="70"
                        #refCmdDesc
                        (change)="
                          textAreaChange(refCmdDesc, 'reeferCommodityDesc')
                        "
                        style="font-size: 10px; width: 216px; resize: none"
                        rows="2"
                        [disabled]="!modelNew.reefer && !modelNew.reeferAsDry"
                      ></textarea>
                    </div>
                  </div>
                  <div
                    class="six wide field"
                  ></div>
                  <div class="ten wide field" style="margin-left: 216px;margin-top: -5px;">
                    <div class="inline field">
                      <label class="cs-lbl" style="padding-right: 10px"
                        >Nature</label
                      >

                      <div class="one wide fields" style="width: 100%">
                        <div
                          class="ui fluid selection dropdown"
                          [class.disabled]="!modelNew.reefer"
                          style="width: 80%;font-size: 10px"

                        ><!--[class.disabled]="lockNature"-->
                          <div
                            class="text"
                            [innerHTML]="
                                modelNew['cllNatureTemp'] === 'F'
                                ? 'FROZEN'
                                : modelNew['cllNatureTemp'] === 'C'
                                ? 'CHILLED'
                                : 'N/A'
                            "
                          ></div>
                          <i class="dropdown icon"></i>
                          <div class="menu">
                            <div
                              class="item"
                              [class.active]="modelNew['cllNatureTemp'] == 'F'"
                              [class.selected]="
                              modelNew['cllNatureTemp'] == 'F'
                              "
                              (click)="onSelectNatureTemp('F')"
                              style="font-size: 10px"
                            >
                              FROZEN
                            </div>
                            <div
                              class="item"
                              [class.active]="modelNew['cllNatureTemp'] == 'C'"
                              [class.selected]="
                              modelNew['cllNatureTemp'] == 'C'
                              "
                              (click)="onSelectNatureTemp('C')"
                              style="font-size: 10px"
                            >
                              CHILLED
                            </div>
                            <div
                              class="item"
                              [class.active]="modelNew['cllNatureTemp'] == 'N/A'"
                              [class.selected]="
                              modelNew['cllNatureTemp'] == 'N/A'
                              "
                              (click)="onSelectNatureTemp('N')"
                              style="font-size: 10px"
                            >
                              N/A
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="ten wide field" style="float: left; margin-left: 216px">
                    <div class="fields">
                      <div class="six wide field">
                        <div class="inline field" style="width: 100%; margin-bottom: 3px">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="modelNew['cllSetOption'] === 'N'"
                              (change)="changeEventSetOption($event)"
                              value="N"
                              name="cllSetOption"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                              [disabled] ="lockReeferDry == true"

                            />
                            <!-- [disabled]="modelNew['cllSetOption'] !== 'N'"-->
                            <label class="cs-lbl" style="font-size: 8px;padding-right: 10px">N/A</label>
                          </div>
                        </div>
                        <div class="field" style="width: 100%; margin-bottom: 3px">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="modelNew['cllSetOption'] == 'C'"
                              (change)="changeEventSetOption($event)"
                              value="C"
                              name="cllSetOption"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                              [disabled] ="lockReefer == true"

                            />
                            <!--[disabled]="modelNew['cllSetOption'] === 'N'"-->
                            <label class="cs-lbl">Pre-cooling</label>
                          </div>
                        </div>
                        <div class="field" style="width: 100%">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="modelNew['cllSetOption'] == 'S'"
                              (change)="changeEventSetOption($event)"
                              value="S"
                              name="cllSetOption"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                              [disabled] ="lockReefer == true"

                            />
                            <!-- [disabled]="modelNew['cllSetOption'] === 'N'"-->
                            <label class="cs-lbl">Pre-set</label>
                          </div>
                        </div>
                      </div>
                      <div class="ten wide field">
                        <div class="field">
                          <label>Degree</label>
                        </div>
                        <div class="field">
                          <div class="fields">
                            <div class="eight wide field">
                              <input
                                type="number"
                                [(ngModel)]="cllDegreeSO"
                                name="degreeSO"
                                (change)="changeEventDegreeSO($event)"
                                maxlength="4"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                style=" margin-right: 1px; margin-left: 7px"
                                [disabled]="!modelNew.reefer"
                                (keypress)="formValidation.NumberOnly($event)"
                              />
                            </div>


                            <div class="eight wide field">
                              <div
                                class="ui fluid selection dropdown"
                                [class.disabled]="!modelNew.reefer"
                                style="width: 60px"
                              >
                                <!-- [class.disabled]="lockReeferTemp"-->
                                <div class="text"  style="font-size: 10px" [innerHTML]="cllDegreeTempSO"></div>
                                <i class="dropdown icon"></i>
                                <div class="menu">
                                  <div
                                    class="item"
                                    style="font-size: 10px"
                                    [class.active]="cllDegreeTempSO == 'C'"
                                    [class.selected]="cllDegreeTempSO == 'C'"
                                    (click)="onSelectDegreeTempSO('C')"
                                  >
                                    C
                                  </div>
                                  <div
                                    class="item"
                                    style="font-size: 10px"
                                    [class.active]="cllDegreeTempSO == 'F'"
                                    [class.selected]="cllDegreeTempSO == 'F'"
                                    (click)="onSelectDegreeTempSO('F')"
                                  >
                                    F
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>



                      </div>
                    </div>



                  </div>



                <div class="ten wide field" style="float: left; margin-left: 216px;">
                  <div class="fields">
                    <div class="six wide field">
                      <div class="inline field" style="width: 100%">
                        <label class="cs-lbl">Ventilation</label>
                        <div class="field" style="width: 100%">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="modelNew['cllVent'] == 'N'"
                              (change)="changeEventVent($event)"
                              value="N"
                              name="vent"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                              [disabled] ="lockReeferDry == true"

                            />
                            <!-- [disabled]="modelNew['cllVent'] !== 'N'"-->
                            <label class="cs-lbl">N/A</label>
                          </div>
                        </div>
                        <div class="field" style="width: 100%">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="modelNew['cllVent']== 'C'"
                              (change)="changeEventVent($event)"
                              value="C"
                              name="vent"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                              [disabled] ="lockReefer == true"

                            />
                            <!-- [disabled]="modelNew['cllVent'] === 'N'"-->
                            <label class="cs-lbl">Close</label>
                          </div>
                        </div>
                        <div class="field" style="width: 100%">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="modelNew['cllVent'] == 'O'"
                              (change)="changeEventVent($event)"
                              value="O"
                              name="vent"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                              [disabled] ="lockReefer == true"

                            />
                            <!-- [disabled]="modelNew['cllVent'] === 'N'"-->
                            <label class="cs-lbl"> Open</label>
                          </div>
                        </div>

                      </div>
                    </div>

                    <!-- right side vent -->
                    <div class="ten wide field">
                      <div class="fields" style="margin-top: 10px;">
                        <div class="eight wide field">
                          <div
                            class="field"
                            [class.error]="modelNew['error-cllVentNumber'] != null && modelNew['error-cllVentNumber'] != ''" >
                            <input
                              type="number"
                              [(ngModel)]="modelNew['cllVentNumber']"
                              (ngModelChange)="modelNew['error-cllVentNumber'] = ''"
                              name="ventNumber"
                              placeholder=""
                              [disabled]="lockVentNumber == true "
                              maxlength="3"
                              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                              (keypress)="formValidation.NumberOnly($event)"
                            />
                            <!--  || lockReeferTemp === true
                              "-->
                          </div>
                        </div>
                        <div class="eight wide field">
                          <div
                            class="ui fluid selection dropdown"
                            [class.error]="modelNew['error-cllVentOption'] != null && modelNew['error-cllVentOption'] != ''"
                            [class.disabled]="lockVentNumber == true "
                            style="height: 18px;font-size: 10px;"

                          >
                            <!--[class.disabled]="lockVentNumber"-->
                            <div
                              class="text"
                              [innerHTML]="modelNew['cllVentOption'] == 'O'? '%': (modelNew['cllVentOption'] == 'C') ? 'CMH' : modelNew['cllVentOption']
                    "
                            ></div>
                            <i class="dropdown icon"></i>
                            <div class="menu">
                              <div
                                class="item"
                                [class.active]="modelNew['cllVentOption'] == 'O'"
                                [class.selected]="
                      modelNew['cllVentOption'] == 'O'
                      "
                                (click)="onSelectVentOption('O')"
                                style="
                        padding: 0.785714rem 0.4rem !important;
                        font-size: 10px;
                      "
                              >
                                % OPEN
                              </div>
                              <div
                                class="item"
                                [class.active]="modelNew['cllVentOption'] == 'C'"
                                [class.selected]="modelNew['cllVentOption'] == 'C'"
                                (click)="onSelectVentOption('C')"
                                style="padding: 0.785714rem 0.4rem !important;font-size: 10px;"
                              >
                                CMH
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>




                <!--<div class="one wide fields" style="width: 100%">-->
                 <!----<div
                    class="one field"
                    style="width: 50%; margin-left: 216px;"
                  >
                    <label>Reefer Collection</label>
                    <input
                      id="rfdTime"
                      type="text"
                      name="rfdatetime"
                      (keypress)="timeOnly($event)"
                      maxlength="20"
                    />
                  </div>-->

                  <div class="one field" style="width: 100%;margin-left: 216px;">
                    <label>Humidity</label>

                    <div class="two wide fields" style="width: 100%">
                      <input
                        type="text"
                        name="humidity"
                        [(ngModel)]="modelNew['cllHumidity']"
                        placeholder=""
                        style="width: 97px; margin-right: 1px; margin-left: 7px"
                        maxlength="3"
                        (keypress)="formValidation.NumberOnly($event)"
                        [disabled]="!modelNew.reefer"
                      />
                      <!--[disabled]="lockHumidity"-->

                      <div
                        class="ui fluid selection dropdown"
                        [class.disabled]="!modelNew.reefer"
                        style="width: 40px"
                      >
                        <div class="text"  style="font-size: 10px" >%</div>
                      </div>
                    </div>
                  </div>
                <!--</div>-->

                  <!-- div class="sixteen wide field ui toggle checkbox" style="padding-left: 0px;margin-top: -9px;margin-bottom: -8px">
                  <input #isReeferAsDry type="checkbox" name="isReeferAsDry" [checked]="modelNew.reeferAsDry" (change)="checkboxEvent('isReeferAsDry', isReeferAsDry.checked);" [disabled]="modelNew.reefer?true:false">
                  <label class="ui tiny label" style="font-size: 10px;Background-color:transparent;padding-top:5px" >RF as DRY</label>
                </div -->
                </div>
              </fieldset>
            </div>
            <div class="four wide field">&nbsp;</div>
            <div class="twelve wide field">
              <fieldset
                width="100%"
                style="
                  border: 1px solid lightgray;
                  padding-bottom: 0px;
                  padding-right: 0px;
                "
              >
                <legend
                  style="font-size: 10px; font-weight: bold; color: dodgerblue"
                >
                  Dangerous Goods Information
                </legend>
                <div class="ui equal width padded grid">
                  <div
                    class="six wide field ui toggle checkbox"
                    style="padding-left: 0px"
                  >
                    <input
                      #isDG
                      type="checkbox"
                      name="isDG"
                      [checked]="modelNew.dg"
                      (change)="checkboxEvent('isDG', isDG.checked)"
                    />
                    <label
                      class="ui tiny label"
                      style="
                        font-size: 10px;
                        background-color: transparent;
                        padding-top: 5px;
                      "
                      >DG</label
                    >
                  </div>
                  <div class="ten wide field">
                    <div class="inline field" style="margin-bottom: -10px">
                      <label class="cs-lbl" style="padding-right: 61px"
                        >IMO</label
                      >
                      <input
                        type="text"
                        name="imo"
                        (keypress)="NumDotOnly($event)"
                        maxlength="4"
                        [(ngModel)]="modelNew['imo']"
                        [disabled]="!modelNew.dg"
                        style="width: 52px; margin-right: 5px"
                      />
                      <label
                        class="cs-lbl"
                        style="padding-right: 0px; margin-right: 5px"
                        >UN No</label
                      >
                      <input
                        type="text"
                        name="unno"
                        (keypress)="NumOnly($event)"
                        maxlength="5"
                        [(ngModel)]="modelNew['unno']"
                        [disabled]="!modelNew.dg"
                        style="width: 119px"
                      />
                    </div>
                  </div>
                  <!--div class="six wide field ui toggle checkbox" style="padding-left: 0px">&nbsp;</div>
                <div class="ten wide field">
                  <div class="inline field" style="margin-bottom: -10px">
                    <label class="cs-lbl" style="padding-right: 48px">UN No</label>
                    <input type="text" name="unno" [(ngModel)]="modelNew['unno']" [disabled]="!modelNew.dg">
                  </div>
                </div -->
                  <div
                    class="six wide field ui toggle checkbox"
                    style="padding-left: 0px"
                  >
                    &nbsp;
                  </div>
                  <div class="ten wide field">
                    <div class="inline field" style="margin-bottom: -10px">
                      <label class="cs-lbl" style="padding-right: 24px"
                        >DG Class</label
                      >
                      <input
                        id="dgClass"
                        type="text"
                        name="dgClassText"
                        (keypress)="CharNumOnly($event)"
                        maxlength="4"
                        [(ngModel)]="modelNew['dgClass']"
                        [disabled]="!modelNew.dg"
                        style="margin-right: 5px"
                        autocomplete="off"
                      />
                      <!-- select #dgClass id="dgClass" style="font-size: 10px;width:218px;" (change)="selectChange(dgClass, 'dgClass')" [disabled]="!modelNew.dg">
                          <option style="font-size: 10px" value="N">N/A</option>
                        <option style="font-size: 10px" value="C1">C1</option>
                        <option style="font-size: 10px" value="C2F">C2F</option>
                        <option style="font-size: 10px" value="C2">C2</option>
                        <option style="font-size: 10px" value="C3">C3</option>
                      </select -->
                    </div>
                  </div>

                  <div class="six wide field" style="padding-left: 0px">
                    &nbsp;
                    <!-- div class="inline field" style="margin-bottom: -10px">
                      <label class="cs-lbl" style="padding-right: 20px">DG Class</label>
                      <select #dgClass id="dgClass" style="font-size: 10px;width:110px;" (change)="selectChange(dgClass, 'dgClass')" [disabled]="!modelNew.dg">
                        <option style="font-size: 10px" value="C1">C1</option>
                        <option style="font-size: 10px" value="C2F">C2F</option>
                        <option style="font-size: 10px" value="C2">C2</option>
                        <option style="font-size: 10px" value="C3">C3</option>
                      </select>
                    </div -->
                  </div>
                  <div class="ten wide field">
                    <div class="inline field" style="margin-bottom: -10px">
                      <label class="cs-lbl" style="padding-right: 4px"
                        >Flash Point</label
                      >
                      <!-- input type="text" name="unno" [(ngModel)]="modelNew['flashPoint']" -->
                      <select
                        #flashSign
                        id="flash-sign"
                        style="font-size: 10px"
                        (change)="selectChange(flashSign, 'flashsign')"
                        [disabled]="!modelNew.dg"
                      >
                        <option style="font-size: 10px" value="+">+</option>
                        <option style="font-size: 10px" value="-">-</option>
                      </select>
                      <input
                        type="text"
                        name="flashDegree"
                        (keypress)="TempOnly($event)"
                        maxlength="6"
                        [(ngModel)]="modelNew['flashPoint']"
                        style="
                          width: 87px;
                          height: 27px;
                          margin-left: 2px;
                          margin-right: 2px;
                        "
                        [disabled]="!modelNew.dg"
                        (change)="noNegativeWithoutDecimal($event)"
                      />
                      <select
                        #flashDegree
                        id="flash-Degreee"
                        style="font-size: 10px"
                        (change)="selectChange(flashDegree, 'flashdegree')"
                        [disabled]="!modelNew.dg"
                      >
                        <option style="font-size: 10px" value=""></option>
                        <option style="font-size: 10px" value="C">C</option>
                        <option style="font-size: 10px" value="F">F</option>
                      </select>
                    </div>
                  </div>
                  <div
                    class="six wide field ui toggle checkbox"
                    style="padding-left: 0px"
                  >
                    &nbsp;
                  </div>
                  <div class="ten wide field">
                    <table border="0">
                      <tbody>
                        <tr>
                          <td
                            style="
                              font-family: 'Lucida Console', Monaco, monospace;
                              font-size: 12px !important;
                              margin-bottom: 10px !important;
                              font-weight: bold;
                              padding-right: 7px;
                            "
                          >
                            Package Type
                          </td>
                          <td style="width: 69%">
                            <combo-plugin
                              #newPackageType
                              [settings]="settingPackageType"
                              (keypress)="CharNumOnly($event)"
                              (change)="getDataCombo($event, 'PackageType')"
                            ></combo-plugin>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="six wide field"
                    style="padding-left: 0px; margin-bottom: 5px"
                  >
                    &nbsp;
                  </div>
                  <div
                    class="ten wide field"
                    style="margin-top: -5px; margin-bottom: 20px"
                  >
                    <div class="inline field" style="margin-bottom: -10px">
                      <label class="cs-lbl" style="padding-right: 20px"
                        >Com. Desc</label
                      >
                      <textarea maxlength="70"
                        #dgCmdDesc
                        (change)="textAreaChange(dgCmdDesc, 'dgCommodityDesc')"
                        style="font-size: 10px; width: 216px; resize: none"
                        rows="2"
                        [disabled]="!modelNew.dg"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="four wide field">&nbsp;</div>
            <div class="twelve wide field">
              <fieldset
                width="100%"
                style="
                  border: 1px solid lightgray;
                  padding-bottom: 0px;
                  padding-right: 0px;
                "
              >
                <legend
                  style="font-size: 10px; font-weight: bold; color: dodgerblue"
                >
                  Out of Gauge Information
                </legend>
                <div class="ui equal width padded grid">
                  <div
                    class="six wide field ui toggle checkbox"
                    style="padding-left: 0px"
                  >
                    <input
                      #isOOG
                      type="checkbox"
                      name="isOOg"
                      [checked]="modelNew.oog"
                      [(ngModel)]="modelNew['oog']"
                      (change)="checkboxEvent('isOOG', isOOG.checked)"
                    />
                    <label
                      class="ui tiny label"
                      style="
                        font-size: 10px;
                        background-color: transparent;
                        padding-top: 5px;
                      "
                      >OOG</label
                    >
                  </div>
                  <div class="ten wide field">
                    <div class="inline field" style="margin-bottom: -10px">
                      <label class="cs-lbl" style="padding-right: 61px"
                        >O/H</label
                      >
                      <input
                        #ohNum
                        type="text"
                        name="oh"
                        (keypress)="NumDotOnly($event)"
                        maxlength="6"
                        [(ngModel)]="modelNew['oh']"
                        style="width: 134px; height: 27px; margin-right: 2px"
                        [disabled]="!modelNew.oog"
                        (change)="noNegativeWithoutDecimal($event)"
                      />
                      <select
                        #ohDegree
                        id="oh-Degreee"
                        style="font-size: 10px"
                        (change)="selectChange(ohDegree, 'ohdegree')"
                        [disabled]="!modelNew.oog"
                      >
                        <option style="font-size: 10px" value="CM">CM</option>
                        <option style="font-size: 10px" value="M">M</option>
                        <option style="font-size: 10px" value="I">I</option>
                        <option style="font-size: 10px" value="F">F</option>
                      </select>
                    </div>
                  </div>
                  <div
                    class="six wide field ui toggle checkbox"
                    style="padding-left: 0px"
                  >
                    &nbsp;
                  </div>
                  <div class="ten wide field">
                    <div
                      class="inline field"
                      style="margin-bottom: -10px; font-size: 10px"
                    >
                      <label class="cs-lbl" style="padding-right: 62px"
                        >O/W</label
                      >
                      <input
                        #owlNum
                        type="text"
                        name="owl"
                        (keypress)="NumDotOnly($event)"
                        maxlength="6"
                        [(ngModel)]="modelNew['owl']"
                        style="width: 80px"
                        [disabled]="!modelNew.oog"
                        (change)="noNegativeWithoutDecimal($event)"
                      />&nbsp;L&nbsp;
                      <input
                        #owrNum
                        type="text"
                        name="owr"
                        (keypress)="NumDotOnly($event)"
                        maxlength="6"
                        [(ngModel)]="modelNew['owr']"
                        style="width: 80px"
                        [disabled]="!modelNew.oog"
                        (change)="noNegativeWithoutDecimal($event)"
                      />&nbsp;R&nbsp;
                    </div>
                  </div>
                  <div
                    class="six wide field ui toggle checkbox"
                    style="padding-left: 0px"
                  >
                    &nbsp;
                  </div>
                  <div class="ten wide field">
                    <div
                      class="inline field"
                      style="margin-bottom: -10px; font-size: 10px"
                    >
                      <label class="cs-lbl" style="padding-right: 62px"
                        >O/L</label
                      >
                      <input
                        #olfNum
                        type="text"
                        name="olf"
                        (keypress)="NumDotOnly($event)"
                        maxlength="6"
                        [(ngModel)]="modelNew['olf']"
                        style="width: 80px"
                        [disabled]="!modelNew.oog"
                        (change)="noNegativeWithoutDecimal($event)"
                      />&nbsp;F&nbsp;
                      <input
                        #olbNum
                        type="text"
                        name="olb"
                        (keypress)="NumDotOnly($event)"
                        maxlength="6"
                        [(ngModel)]="modelNew['olb']"
                        style="width: 80px"
                        [disabled]="!modelNew.oog"
                        (change)="noNegativeWithoutDecimal($event)"
                      />&nbsp;B&nbsp;
                    </div>
                  </div>
                  <div
                    class="six wide field ui toggle checkbox"
                    style="padding-left: 0px"
                  >
                    <input
                      #isUC
                      type="checkbox"
                      name="isUC"
                      [checked]="modelNew.uc"
                      [(ngModel)]="modelNew['uc']"
                      (change)="checkboxEvent('isUC', isUC.checked)"
                    />
                    <label
                      class="ui tiny label"
                      style="
                        font-size: 10px;
                        background-color: transparent;
                        padding-top: 5px;
                      "
                      >UC</label
                    >
                  </div>
                  <div class="ten wide field">
                    <div class="inline field" style="margin-bottom: -10px">
                      <label class="cs-lbl" style="padding-right: 53px"
                        >UC/H</label
                      >
                      <input
                        type="number"
                        name="uch"
                        max="999999"
                        step="0.01"
                        (keypress)="NumDotOnly($event)"
                        [(ngModel)]="modelNew['uch']"
                        style="width: 134px; height: 27px; margin-right: 2px"
                        [disabled]="!modelNew.uc"
                        (change)="noNegativeWithoutDecimal($event)"
                      />
                      <select
                        #uchDegree
                        id="uch-Degreee"
                        style="font-size: 10px"
                        (change)="selectChange(uchDegree, 'uchdegree')"
                        [disabled]="!modelNew.uc"
                      >
                        <option style="font-size: 10px" value="CM">CM</option>
                        <option style="font-size: 10px" value="M">M</option>
                        <option style="font-size: 10px" value="I">I</option>
                        <option style="font-size: 10px" value="F">F</option>
                      </select>
                    </div>
                  </div>
                  <div
                    class="six wide field ui toggle checkbox"
                    style="padding-left: 0px"
                  >
                    &nbsp;
                  </div>
                  <div class="ten wide field">
                    <div class="inline field" style="margin-bottom: -10px">
                      <label class="cs-lbl" style="padding-right: 53px"
                        >UC/L</label
                      >
                      <input
                        type="number"
                        name="ucl"
                        max="999999"
                        step="0.01"
                        (keypress)="NumDotOnly($event)"
                        [(ngModel)]="modelNew['ucl']"
                        style="width: 134px; height: 27px; margin-right: 2px"
                        [disabled]="!modelNew.uc"
                        (change)="noNegativeWithoutDecimal($event)"
                      />
                      <select
                        #uclDegree
                        id="ucl-Degreee"
                        style="font-size: 10px"
                        (change)="selectChange(uclDegree, 'ucldegree')"
                        [disabled]="!modelNew.uc"
                      >
                        <option style="font-size: 10px" value="CM">CM</option>
                        <option style="font-size: 10px" value="M">M</option>
                        <option style="font-size: 10px" value="I">I</option>
                        <option style="font-size: 10px" value="F">F</option>
                      </select>
                    </div>
                  </div>
                  <div
                    class="six wide field ui toggle checkbox"
                    style="padding-left: 0px"
                  >
                    &nbsp;
                  </div>
                  <div class="ten wide field">
                    <div class="inline field" style="margin-bottom: -10px">
                      <label class="cs-lbl" style="padding-right: 53px"
                        >UC/W</label
                      >
                      <input
                        type="number"
                        name="ucw"
                        max="999999"
                        step="0.01"
                        (keypress)="NumDotOnly($event)"
                        [(ngModel)]="modelNew['ucw']"
                        style="width: 134px; height: 27px; margin-right: 2px"
                        [disabled]="!modelNew.uc"
                        (change)="noNegativeWithoutDecimal($event)"
                      />
                      <select
                        #ucwDegree
                        id="ucw-Degreee"
                        style="font-size: 10px"
                        (change)="selectChange(ucwDegree, 'ucwdegree')"
                        [disabled]="!modelNew.uc"
                      >
                        <option style="font-size: 10px" value="CM">CM</option>
                        <option style="font-size: 10px" value="M">M</option>
                        <option style="font-size: 10px" value="I">I</option>
                        <option style="font-size: 10px" value="F">F</option>
                      </select>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="four wide field">&nbsp;</div>

            <div class="twelve wide field">
              <label class="cs-lbl">Remarks</label>
              <textarea
                #remarks
                name="remarks"
                (change)="textAreaChange(remarks, 'remarks')"
                style="font-size: 10px"
              ></textarea>
            </div>
            <!-- div class="four wide field">&nbsp;</div -->
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- end of dialog New Booking -->

<!-- dialog New Container -->
<div id="newAddContainer" style="display: none">
  <div class="ui buttons">
    <button
      class="ui tiny icon button"
      style="font-size: 10px; margin-right: 5px"
      (click)="addNew()"
    >
      <i class="add icon"></i>
    </button>
    <button
      class="ui tiny icon button"
      style="font-size: 10px; margin-right: 5px"
      (click)="cancelNew()"
    >
      <i class="remove icon"></i>
    </button>
  </div>
  <div
    class="ui inverted dimmer"
    [class.active]="addContainerLoading == true"
    style="z-index: 1"
  >
    <div class="ui medium text loader">Loading</div>
  </div>
  <div
    class="ui raised segment"
    style="
      height: auto;
      margin-bottom: 10px;
      margin-right: 0px;
      margin-top: 6px;
    "
  >
    <form class="ui form">
      <input
        type="hidden"
        name="seq"
        [(ngModel)]="modelContainer['containerSeq']"
      />
      <div class="field">
        <!--    New Container 1st row     -->
        <div class="fields" style="margin-bottom: -18px">
          <div class="six wide field" style="margin-top: 8px">
            <label class="cs-lblNew">Container No.</label>
          </div>
          <div class="ten wide fields">
            <input
              type="text"
              name="containerNo"
              [(ngModel)]="modelContainer['containerNo']"
              placeholder="container no."
              maxlength="14"

              (keypress)="keypressEventContainerNo($event)"
              (focusout)="focusOuteventContainerNo($event, 'containerNo')"
              (paste)="pasteEventContainerNo($event, 'containerNo')" 
            />
          </div>
        </div>
      </div>
      <!-- End of New Container 1st row -->

      <div class="field">
        <!--     New Container 2nd row     -->
        <div class="fields" style="margin-bottom: -18px">
          <div class="six wide field">
            <label class="cs-lblNew">Type.</label>
          </div>
          <div class="ten wide fields">
            <div
              id="typeCombo"
              class="ui selection search dropdown"
              style="font-size: 11px; margin-top: -5px"
            >
              <input
                type="hidden"
                name="dtType"
                [(ngModel)]="modelContainer['containerType']"
              />
              <i class="dropdown icon"></i>
              <div class="default text" style="font-size: 11px">Select</div>
              <div class="menu"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- End of New Container 2nd row -->

      <div class="field">
        <!--     New Container 3rd row     -->
        <div class="fields" style="margin-bottom: -18px">
          <div class="six wide field">
            <label class="cs-lblNew">Size</label>
          </div>
          <div class="ten wide fields" style="margin-bottom: 9px">
            <div
              id="sizeCombo"
              class="ui selection search dropdown"
              style="font-size: 11px; margin-top: -5px"
              [class.loading]="sizeComboLoading"
              [class.disabled]="sizeComboDisabled"
            >
              <input
                type="hidden"
                name="dtSize"
                [(ngModel)]="modelContainer['containerSize']"
              />
              <i class="dropdown icon"></i>
              <div class="default text" style="font-size: 11px">Select</div>
              <div class="menu"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- End of New Container 3rd row -->

      <div class="field">
        <!--     New Container 4th row     -->
        <div class="fields" style="margin-bottom: -21px">
          <div class="six wide field required" style="margin-top: 6px">
            <label class="cs-lblNew" style="color: red">VGM WT. (KGS)</label>
          </div>
          <div class="ten wide fields">
            <input
              type="number"
              name="containerWidth"
              max="99999"
              maxlength="5"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              min="0"
              (keypress)="NumDotOnly($event)"
              [(ngModel)]="modelContainer['containerWeight']"
              
            />
          </div>
        </div>
      </div>
      <!-- End of New Container 4th row -->

      <div class="field">
        <!--     New Container 5th row     -->
        <div class="fields" style="margin-bottom: 0px">
          <div class="six wide field required" style="margin-top: 2px">
            <label class="cs-lblNew" style="color: red">Indicator</label>
          </div>
          <div
            class="ten wide field ui toggle checkbox"
            style="margin-left: -5px; padding-left: 0px"
          >
            <input
              #vmInd
              type="checkbox"
              name="indicator"
              [checked]="modelContainer.containerVgmIndicator"
              [(ngModel)]="modelContainer['containerVgmIndicator']"
              (change)="indicatorChange(vmInd.checked)"
            />
            <label
              class="ui tiny label"
              style="
                font-size: 10px;
                background-color: transparent;
                padding-top: 5px;
              "
              >&nbsp;</label
            >
          </div>
          <!-- div class="ten wide fields" asd>
            <input type="text" name="sealNo1" [(ngModel)] =  "modelContainer['containerVgmIndicator']" placeholder="Indicator">
          </div -->
        </div>
      </div>
      <!-- End of New Container 5th row -->

      <div class="field">
        <!--     New Container 6th row     -->
        <div class="fields" style="margin-top: -5px; margin-bottom: -21px">
          <div class="six wide field">
            <label class="cs-lblNew">Method</label>
          </div>
          <div class="ten wide fields">
            <div
              id="methodCombo"
              class="ui selection dropdown"
              style="font-size: 11px; margin-top: -5px"
            >
              <input type="hidden" name="dtMethod" />
              <i class="dropdown icon"></i>
              <div class="default text" style="font-size: 11px">Select</div>
              <div class="menu">
                <div class="item" data-value="" style="font-size: 11px"></div>
                <div class="item" data-value="SM1" style="font-size: 11px">
                  SM1
                </div>
                <div class="item" data-value="SM2" style="font-size: 11px">
                  SM2
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End of New Container 6th row -->

      <div class="field">
        <!--     New Container 7th row     -->
        <div class="fields" style="margin-bottom: -21px">
          <div class="six wide field">
            <label class="cs-lblNew">Seal No 1</label>
          </div>
          <div class="ten wide fields">
            <input
              type="text"
              name="sealNo1"
              maxlength="20"
              [(ngModel)]="modelContainer['containerSealNo1']"
              placeholder="seal no 1"

              (keypress)="keypressEventSealNo($event)"
              (focusout)="focusOuteventContainerNo($event, 'containerSealNo1')"
              (paste)="pasteEventContainerNo($event, 'containerSealNo1')"
            />
          </div>
        </div>
      </div>
      <!-- End of New Container 7th row -->

      <div class="field">
        <!--     New Container 8th row     -->
        <div class="fields" style="margin-bottom: -21px">
          <div class="six wide field">
            <label class="cs-lblNew">Seal No 2</label>
          </div>
          <div class="ten wide fields">
            <input
              type="text"
              name="sealNo2"
              maxlength="20"
              [(ngModel)]="modelContainer['containerSealNo2']"
              placeholder="seal no 2"

              (keypress)="keypressEventSealNo($event)"
              (focusout)="focusOuteventContainerNo($event, 'containerSealNo2')"
              (paste)="pasteEventContainerNo($event, 'containerSealNo2')"
            />
          </div>
        </div>
      </div>
      <!-- End of New Container 8th row -->

      <div class="field">
        <!--     New Container 9th row     -->
        <div class="fields" style="margin-bottom: -21px">
          <div class="six wide field">
            <label class="cs-lblNew">Seal No 3</label>
          </div>
          <div class="ten wide fields">
            <input
              type="text"
              name="sealNo3"
              maxlength="20"
              [(ngModel)]="modelContainer['containerSealNo3']"
              placeholder="seal no 3"

              (keypress)="keypressEventSealNo($event)"
              (focusout)="focusOuteventContainerNo($event, 'containerSealNo3')"
              (paste)="pasteEventContainerNo($event, 'containerSealNo3')"
            />
          </div>
        </div>
      </div>
      <!-- End of New Container 9th row -->

      <div class="field">
        <!--     New Container 10th row     -->
        <div class="fields" style="margin-bottom: -21px">
          <div class="six wide field">
            <label class="cs-lblNew">Pax</label>
          </div>
          <div class="ten wide fields">
            <input
              type="text"
              name="pax"
              maxlength="200"
              [(ngModel)]="modelContainer['pax']"
              placeholder="Pax"

              (keypress)="keypressEventContainerPax($event)"
              (focusout)="focusOuteventContainerPax($event)"
              (paste)="pasteEventContainerPax($event)"
            />
          </div>
        </div>
      </div>
      <!-- End of New Container 10th row -->
    </form>
  </div>
</div>
<!-- end of dialog New Container -->

<!-- Dialog Receive CLL -->
<div id="receiveCLLDialogId" style="display: none">
  <div class="ui buttons">
    <!-- button class="ui tiny icon button" style="font-size:10px" (click)="uploadCLL();">SAVE</button -->
    <button
      class="ui tiny icon button"
      style="font-size: 10px; margin-right: 5px; margin-bottom: 5px"
      (click)="closeUploadCLL()"
    >
      CLOSE
    </button>
    <button
      class="ui tiny icon button"
      style="font-size: 10px; margin-right: 5px; margin-bottom: 5px"
      (click)="errorReportCLL()"
      [disabled]="disableErrorButton"
    >
      Discrepancy Report
    </button>
  </div>
  <div
    class="ui raised segment"
    style="
      height: auto;
      margin-bottom: 0px;
      margin-right: 0px;
      margin-top: 0px;
      padding-right: 160px;
    "
  >
    <div
      class="ui equal width padded grid"
      style="margin-left: -14px !important; margin-bottom: 15px !important"
    >
      <div
        class="ui inverted dimmer"
        [class.active]="loadingUpload == true"
        style="z-index: 1"
      >
        <div class="ui medium text loader">Loading</div>
      </div>
      <div class="sixteen wide field" style="width: 100%">
        <label style="font-size: 12px">FILE EDI</label>
        <upload-plugin
          #uploadPlg
          [settings]="settingUpload"
          (eventUpload)="uploadEvent($event)"
        ></upload-plugin>
      </div>
    </div>
    <!-- grid-plugin id="cllGridUpload" #cllGridUpload [settings]="settingGridUpload" (gridEvent)="gridEventUpload($event)" (infoGrid)="infoGrid($event)"></grid-plugin -->
  </div>
</div>
<!-- End of Receive CLL dialog -->

<!-- Dialog Batch Cll -->
<div id="batchCllId" style="display: none">
  <div
    class="ui inverted dimmer"
    [class.active]="loadingBatch == true"
    style="z-index: 1"
  >
    <div class="ui medium text loader">Loading</div>
  </div>
  <div class="ui buttons">
    <button
      class="ui tiny icon button"
      style="font-size: 10px; margin-right: 5px"
      (click)="batchSave()"
      [disabled]="batchSaveButtonDisabled"
    >
      Batch Save
    </button>
    <button
      class="ui tiny icon button"
      style="font-size: 10px"
      (click)="cancelBatch()"
    >
      Cancel
    </button>
  </div>
  <div
    class="ui raised segment"
    style="
      height: auto;
      margin-bottom: 0px;
      margin-right: 0px;
      margin-top: 0px;
      padding: 5px;
    "
  >
    <div
      class="ui basic red prompt label transition error-message-hidden"
      [class.error-message-visible]="errorBatchMessage != ''"
      style="margin-top: 10px"
    >
      <span [innerHTML]="errorBatchMessage"></span>
    </div>
    <div class="ui equal width padded grid">
      <div class="four wide field" style="padding: 0px">
        <grid-plugin
          id="cllGridBatch"
          #cllGridBatch
          [settings]="settingGridBatch"
          (gridEvent)="gridEventBatch($event)"
          (infoGrid)="infoGrid($event)"
        ></grid-plugin>
      </div>
      <div
        class="twelve wide fields"
        style="
          width: 78%;
          padding-right: 0px !important;
          margin-right: 0px !important;
        "
      >
        <div class="sixteen wide field">
          <!-- grid-plugin id="cllGridBatchHeader" #cllGridBatchHeader [settings]="settingGridBatchHeader" (gridEvent)="gridEventBatchHeader($event)" (infoGrid)="infoGrid($event)"></grid-plugin -->
          <div class="sixteen wide field">
            <grid-plugin
              id="cllGridBatchHeader"
              #cllGridBatchHeader
              [settings]="settingGridBatchHeader"
              (gridEvent)="gridEventBatchHeader($event)"
              (infoGrid)="infoGrid($event)"
            ></grid-plugin>
          </div>
          <div class="sixteen wide field" style="width: 100%; margin-top: 20px">
            <table id="checkboxGroup" border="0" width="100%" cellspacing="2">
              <tbody>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      name="1"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'operatorCode', 'OP')
                      "
                    />OP Code
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="2"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'loadPortName',
                          'POL'
                        )
                      "
                    />POL
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="10"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'thruSpt',
                          'Thru Shpt (Y/N)'
                        )
                      "
                    />Thru Shpt (Y/N)
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="4"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'flashScale', 'C/F')
                      "
                    />C/F
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="12"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'owl', 'OWL')
                      "
                    />OWL
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="20"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'sealNo3',
                          'Seal No. 3'
                        )
                      "
                    />Seal No. 3
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="28"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'stackDateTime',
                          'Stack Date/Time'
                        )
                      "
                    />Stack Date/Time
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      name="80"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'inSlot', 'SO')
                      "
                    />SO Code
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="9"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'transhipPortName',
                          'POT'
                        )
                      "
                    />POT
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="17"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'shipperName',
                          'Shipper'
                        )
                      "
                    />Shipper
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="11"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'pkgTypeCode',
                          'PKG Type'
                        )
                      "
                    />Pkg Type
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="19"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'owr', 'OWR')
                      "
                    />OWR
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="27"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'uc_display',
                          'UC (Y/N)'
                        )
                      "
                    />UC (Y/N)
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="35"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'indicator',
                          'Indicator'
                        )
                      "
                    />Indicator
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      name="8"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'typeSize',
                          'Cntr Size/Type'
                        )
                      "
                    />Cntr Size/Type
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="16"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'discPort1Name',
                          'POD 1'
                        )
                      "
                    />POD 1
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="24"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'commodityName',
                          'Commodity'
                        )
                      "
                    />Commodity
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="18"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'reefer_display',
                          'RF (Y/N)'
                        )
                      "
                    />RF (Y/N)
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="26"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'olf', 'OLF')
                      "
                    />OLF
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="34"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'uch', 'UC/H')
                      "
                    />UC/H
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="42"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'method', 'Method')
                      "
                    />Method
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      name="15"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'sealNo1',
                          'Seal No. 1'
                        )
                      "
                    />Seal No. 1
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="23"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'discPort2Name',
                          'POD 2'
                        )
                      "
                    />POD 2
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="xx"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'dg_display',
                          'DG (Y/N)'
                        )
                      "
                    />DG (Y/N)
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="25"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'reeferTemp',
                          'RF Temp.'
                        )
                      "
                    />RF Temp.
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="33"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'olb', 'OLB')
                      "
                    />OLB
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="41"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'uchScale', 'S.')
                      "
                    />UC/H Scale.
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="49"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'pax', 'Pax')
                      "
                    />Pax
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      name="22"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'cellLocation',
                          'Cell Loc.'
                        )
                      "
                    />Cell Loc.
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="30"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'discPort3Name',
                          'POD 3'
                        )
                      "
                    />POD 3
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="31D5"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'dgClass',
                          'DG Class'
                        )
                      "
                    />DG Class
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="32"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'tempScale', 'C/F')
                      "
                    />C/F
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="40"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'oogScale',
                          'OOG Scale'
                        )
                      "
                    />OOG Scale
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="48"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'ucl', 'UC/L')
                      "
                    />UC/L
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="77"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'remarks', 'Remarks')
                      "
                    />Remarks
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      name="29"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'weight',
                          'VGM Wt. (KGS)'
                        )
                      "
                    />VGM Wt. (KGS)
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="37"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'finalDestName',
                          'Final Dest.'
                        )
                      "
                    />Final Dest.
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="31"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'imo', 'IMO')
                      "
                    />IMO
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="39"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'reeferAsDry_display',
                          'RF as DRY'
                        )
                      "
                    />RF as Dry
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="47"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'bundle_display',
                          'Bundle (Y/N)'
                        )
                      "
                    />Bundle (Y/N)
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="7"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'uclScale', 'S.')
                      "
                    />UC/L Scale.
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      name="36"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'status',
                          'Status (F/E)'
                        )
                      "
                    />Status (F/E)
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="44"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'nextVesselName',
                          'Connecting Vsl'
                        )
                      "
                    />Connecting Vsl
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="38"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'unno', 'UN No.')
                      "
                    />UN No.
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="46"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'oog_display',
                          'OOG (Y/N)'
                        )
                      "
                    />OOG (Y/N)
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="6"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'spDetails',
                          'Special Details'
                        )
                      "
                    />Special Details
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="14"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'ucw', 'UC/W')
                      "
                    />UC/W
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      name="43"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'tranship',
                          'T/S (Y/N)'
                        )
                      "
                    />T/S (Y/N)
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="3"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'nextVoyage',
                          'Connecting Voy'
                        )
                      "
                    />Connecting Voy
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="45"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'flashPoint',
                          'Flash Pt.'
                        )
                      "
                    />Flash Pt.
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="5"
                      (change)="eventCheckBoxNew1($event, 'batch', 'oh', 'O/H')"
                    />O/H
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="13"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'batch',
                          'sealNo2',
                          'Seal No. 2'
                        )
                      "
                    />Seal No. 2
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="21"
                      (change)="
                        eventCheckBoxNew1($event, 'batch', 'ucwScale', 'S.')
                      "
                    />UC/W Scale.
                  </td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end of dialog Batch CLL -->

  <!-- Dialog Copy Cll -->
  <div id="copyCllId" style="display: none">
    <div
      class="ui inverted dimmer"
      [class.active]="loadingCopy == true"
      style="z-index: 1"
    >
      <div class="ui medium text loader">Loading</div>
    </div>
    <div class="ui buttons">
      <button
        class="ui tiny icon button"
        style="font-size: 10px; margin-right: 5px"
        (click)="copyProcess()"
        [disabled]="copyButtonDisable"
      >
        Copy
      </button>
      <button
        class="ui tiny icon button"
        style="font-size: 10px"
        (click)="cancelCopy()"
      >
        Cancel
      </button>
    </div>
    <div
      class="ui raised segment"
      style="
        height: auto;
        margin-bottom: 0px;
        margin-right: 0px;
        margin-top: 0px;
        padding: 5px;
      "
    >
      <div
        class="ui basic red prompt label transition error-message-hidden"
        [class.error-message-visible]="errorCopyMessage != ''"
        style="margin-top: 10px"
      >
        <span [innerHTML]="errorCopyMessage"></span>
      </div>
      <div class="ui equal width padded grid">
        <div class="four wide field" style="padding: 0px">
          <grid-plugin
            id="cllGridCopy"
            #cllGridCopy
            [settings]="settingGridCopy"
            (gridEvent)="gridEventCopy($event)"
            (infoGrid)="infoGrid($event)"
            (keypress)="keypressEventContainerNo($event)"
            (paste)="pasteEventContainerNoCopy($event)"
            
          ></grid-plugin>
        </div>
        <div
          class="twelve wide fields"
          style="
            width: 78%;
            padding-right: 0px !important;
            margin-right: 0px !important;
          "
        >
          <div class="sixteen wide field">
            <grid-plugin
              id="cllGridCopyHeader"
              #cllGridCopyHeader
              [settings]="settingGridCopyHeader"
              (gridEvent)="gridEventCopyHeader($event)"
              (infoGrid)="infoGrid($event)"
            ></grid-plugin>
          </div>
          <div class="sixteen wide field" style="width: 100%; margin-top: 20px">
            <table
              id="checkboxGroupCopy"
              border="0"
              width="100%"
              cellspacing="2"
            >
              <tbody>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      name="1"
                      (change)="
                        eventCheckBoxNew1($event, 'copy', 'operatorCode', 'OP')
                      "
                    />OP Code
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="9"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'transhipPortName',
                          'POT'
                        )
                      "
                    />POT
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="17"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'shipperName',
                          'Shipper'
                        )
                      "
                    />Shipper
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="11"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'pkgTypeCode',
                          'PKG Type'
                        )
                      "
                    />Pkg Type
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="19"
                      (change)="eventCheckBoxNew1($event, 'copy', 'owr', 'OWR')"
                    />OWR
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="27"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'uc_display',
                          'UC (Y/N)'
                        )
                      "
                    />UC (Y/N)
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="35"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'indicator',
                          'Indicator'
                        )
                      "
                    />Indicator
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      name="8"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'typeSize',
                          'Cntr Size/Type'
                        )
                      "
                    />Cntr Size/Type
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="16"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'discPort1Name',
                          'POD 1'
                        )
                      "
                    />POD 1
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="24"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'commodityName',
                          'Commodity'
                        )
                      "
                    />Commodity
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="18"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'reefer_display',
                          'RF (Y/N)'
                        )
                      "
                    />RF (Y/N)
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="26"
                      (change)="eventCheckBoxNew1($event, 'copy', 'olf', 'OLF')"
                    />OLF
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="34"
                      (change)="
                        eventCheckBoxNew1($event, 'copy', 'uch', 'UC/H')
                      "
                    />UC/H
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="42"
                      (change)="
                        eventCheckBoxNew1($event, 'copy', 'method', 'Method')
                      "
                    />Method
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      name="15"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'sealNo1',
                          'Seal No. 1'
                        )
                      "
                    />Seal No. 1
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="23"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'discPort2Name',
                          'POD 2'
                        )
                      "
                    />POD 2
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="xx"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'dg_display',
                          'DG (Y/N)'
                        )
                      "
                    />DG (Y/N)
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="25"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'reeferTemp',
                          'RF Temp.'
                        )
                      "
                    />RF Temp.
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="33"
                      (change)="eventCheckBoxNew1($event, 'copy', 'olb', 'OLB')"
                    />OLB
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="41"
                      (change)="
                        eventCheckBoxNew1($event, 'copy', 'uchScale', 'S.')
                      "
                    />S.
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="49"
                      (change)="eventCheckBoxNew1($event, 'copy', 'pax', 'Pax')"
                    />Pax
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      name="22"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'cellLocation',
                          'Cell Loc.'
                        )
                      "
                    />Cell Loc.
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="30"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'discPort3Name',
                          'POD 3'
                        )
                      "
                    />POD 3
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="31D5"
                      (change)="
                        eventCheckBoxNew1($event, 'copy', 'dgClass', 'DG Class')
                      "
                    />DG Class
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="32"
                      (change)="
                        eventCheckBoxNew1($event, 'copy', 'tempScale', 'C/F')
                      "
                    />C/F
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="40"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'oogScale',
                          'OOG Scale'
                        )
                      "
                    />OOG Scale
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="48"
                      (change)="
                        eventCheckBoxNew1($event, 'copy', 'ucl', 'UC/L')
                      "
                    />UC/L
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      name="29"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'weight',
                          'VGM Wt. (KGS)'
                        )
                      "
                    />VGM Wt. (KGS)
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="37"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'finalDestName',
                          'Final Dest.'
                        )
                      "
                    />Final Dest.
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="31"
                      (change)="eventCheckBoxNew1($event, 'copy', 'imo', 'IMO')"
                    />IMO
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="39"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'reeferAsDry_display',
                          'RF as DRY'
                        )
                      "
                    />RF as Dry
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="47"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'bundle_display',
                          'Bundle (Y/N)'
                        )
                      "
                    />Bundle (Y/N)
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="7"
                      (change)="
                        eventCheckBoxNew1($event, 'copy', 'uclScale', 'S.')
                      "
                    />S.
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      name="36"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'status',
                          'Status (F/E)'
                        )
                      "
                    />Status (F/E)
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="44"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'nextVesselName',
                          'Connecting Vsl'
                        )
                      "
                    />Connecting Vsl
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="38"
                      (change)="
                        eventCheckBoxNew1($event, 'copy', 'unno', 'UN No.')
                      "
                    />UN No.
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="46"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'oog_display',
                          'OOG (Y/N)'
                        )
                      "
                    />OOG (Y/N)
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="6"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'spDetails',
                          'Special Details'
                        )
                      "
                    />Special Details
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="14"
                      (change)="
                        eventCheckBoxNew1($event, 'copy', 'ucw', 'UC/W')
                      "
                    />UC/W
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      name="43"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'tranship',
                          'T/S (Y/N)'
                        )
                      "
                    />T/S (Y/N)
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="3"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'nextVoyage',
                          'Connecting Voy'
                        )
                      "
                    />Connecting Voy
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="45"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'flashPoint',
                          'Flash Pt.'
                        )
                      "
                    />Flash Pt.
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="5"
                      (change)="eventCheckBoxNew1($event, 'copy', 'oh', 'O/H')"
                    />O/H
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="13"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'sealNo2',
                          'Seal No. 2'
                        )
                      "
                    />Seal No. 2
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="21"
                      (change)="
                        eventCheckBoxNew1($event, 'copy', 'ucwScale', 'S.')
                      "
                    />S.
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      name="2"
                      (change)="
                        eventCheckBoxNew1($event, 'copy', 'loadPortName', 'POL')
                      "
                    />POL
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="10"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'thruSpt',
                          'Thru Shpt (Y/N)'
                        )
                      "
                    />Thru Shpt (Y/N)
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="4"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'flashPointScale',
                          'C/F'
                        )
                      "
                    />C/F
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="12"
                      (change)="eventCheckBoxNew1($event, 'copy', 'owl', 'OWL')"
                    />OWL
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="20"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'sealNo3',
                          'Seal No. 3'
                        )
                      "
                    />Seal No. 3
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="28"
                      (change)="
                        eventCheckBoxNew1(
                          $event,
                          'copy',
                          'stackDateTime',
                          'Stack Date/Time'
                        )
                      "
                    />Stack Date/Time
                  </td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end of dialog Copy CLL -->

  <!-- Dialog Transfer Cll -->
  <div id="transferCllId" style="display: none">
    <div class="ui buttons">
      <button
        class="ui tiny icon button"
        style="font-size: 10px"
        (click)="transferProcess()"
        [disabled]="trfButtonDisabled"
      >
        Ok
      </button>
      <button
        class="ui tiny icon button"
        style="font-size: 10px"
        (click)="cancelTransfer()"
        [disabled]="trfButtonDisabled"
      >
        Cancel
      </button>
      <button
        class="ui tiny icon button"
        style="font-size: 10px"
        (click)="closeTransfer()"
      >
        Close
      </button>
    </div>
    <div
      class="ui raised segment"
      style="
        height: auto;
        margin-bottom: 0px;
        margin-right: 0px;
        margin-top: 0px;
        padding: 5px;
      "
    >
      <div class="ui equal width padded grid">
        <div class="twelve wide field" style="padding: 0px">
          <grid-plugin
            id="cllGridTransfer"
            #cllGridTransfer
            [settings]="settingGridTransfer"
            (gridEvent)="gridEventTransfer($event)"
            (infoGrid)="infoGrid($event)"
          ></grid-plugin>
        </div>
        <div class="four wide fields">
          <label class="ui red ribbon small label">Transfer To:</label>
          <div class="field">
            <label>Vessel Name</label>
            <combo-plugin
              #vslTo
              [settings]="settingVesselTo"
              (keypress)="exceptApos($event)"
              (change)="getDataCombo($event, 'transfer-vessel')"
            ></combo-plugin>
          </div>
          <div class="field">
            <label>Voyage</label>
            <combo-plugin
              #voyTo
              [settings]="settingVoyageTo"
              (keypress)="exceptApos($event)"
              (change)="getDataCombo($event, 'transfer-voyage')"
            ></combo-plugin>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end of dialog Transfer CLL -->

  <!-- Dialog Update cell location Cll -->
  <div id="updateCellLocCllId" style="display: none">
    <div
      class="ui inverted dimmer"
      [class.active]="loadingUpdateCellLocc == true"
      style="z-index: 1"
    >
      <div class="ui medium text loader">Loading</div>
    </div>
    <div class="ui buttons">
      <button
        class="ui tiny icon button"
        style="font-size: 10px"
        (click)="updateCellLocProcess()"
        [disabled]="transferButtonDisable"
      >
        Save
      </button>
      <button
        class="ui tiny icon button"
        style="font-size: 10px"
        (click)="cancelCellLoc()"
      >
        Cancel
      </button>
    </div>
    <div
      class="ui raised segment"
      style="
        height: auto;
        margin-bottom: 0px;
        margin-right: 0px;
        margin-top: 0px;
        padding: 5px;
      "
    >
      <div class="ui equal width padded grid">
        <div class="field" style="padding: 0px; width: 100%">
          <grid-plugin
            id="cllGridUpdateCelLoc"
            #cllGridUpdateCelLoc
            [settings]="settingGridUpdateCelLoc"
            (gridEvent)="gridEventUpdateCelLoc($event)"
            (infoGrid)="infoGrid($event)"
          ></grid-plugin>
        </div>
      </div>
    </div>
  </div>
  <!-- end of dialog update cell location CLL -->

  <!-- Dialog Receive Cell Locations -->
  <div id="receiveCellLocationDialogId" style="display: none">
    <div class="ui buttons">
      <!-- button class="ui tiny icon button" style="font-size:10px" (click)="uploadReceiveCellLocation();" disabled>SAVE</button -->
      <button
        class="ui tiny icon button"
        style="font-size: 10px; margin-right: 5px; margin-bottom: 5px"
        (click)="closeReceiveCellLocation()"
      >
        CLOSE
      </button>
      <button
        class="ui tiny icon button"
        style="font-size: 10px; margin-right: 5px; margin-bottom: 5px"
        (click)="errorReportReceiveCellLocation()"
        [disabled]="disableErrorButton1"
      >
        Error Report
      </button>
    </div>
    <div
      class="ui raised segment"
      style="
        height: auto;
        margin-bottom: 0px;
        margin-right: 0px;
        margin-top: 0px;
      "
    >
      <div
        class="ui equal width padded grid"
        style="margin-left: -14px !important; margin-bottom: 15px !important"
      >
        <div
          class="ui inverted dimmer"
          [class.active]="loadingUpload == true"
          style="z-index: 1"
        >
          <div class="ui medium text loader">Loading</div>
        </div>
        <div class="sixteen wide field" style="width: 100%">
          <label style="font-size: 12px">FILE EDI</label>
          <upload-plugin
            #uploadPlgCellLocation
            [settings]="settingUploadCellLocation"
            (eventUpload)="uploadEventCellLocations($event)"
          ></upload-plugin>
        </div>
      </div>
      <!-- grid-plugin id="cllGridUpload" #cllGridUpload [settings]="settingGridUpload" (gridEvent)="gridEventUpload($event)" (infoGrid)="infoGrid($event)"></grid-plugin -->
    </div>
  </div>
  <!-- End of Receive CLL Cell Locations -->

  <!-- Dialog Multiple DG -->
  <div id="multipleDGId" style="display: none">
    <div
      class="ui inverted dimmer"
      [class.active]="loadingUpdateCellLocc == true"
      style="z-index: 1"
    >
      <div class="ui medium text loader">Loading</div>
    </div>

    <div class="ui buttons">
      <button
        class="ui tiny icon button"
        style="font-size: 10px"
        (click)="cancelMultipleDG()"
      >
        Close
      </button>
    </div>

    <div
      class="ui raised segment"
      style="
        height: auto;
        margin-bottom: 0px;
        margin-right: 0px;
        margin-top: 0px;
        padding: 5px;
      "
    >
      <div class="ui equal width padded grid">
        <div class="field" style="padding: 0px; width: 100%">
          <grid-plugin
            id="cllGridAdditionalDGId"
            #cllGridAdditionalDG
            [settings]="settingGridAdditionalDG"
            (gridEvent)="gridEventAdditionalDG($event)"
            (infoGrid)="infoGrid($event)"
          ></grid-plugin>
        </div>
      </div>
    </div>
  </div>
  <!-- end of dialog multiple DG -->

  <!-- Dialog Receive CLL Baplie -->
  <div id="receiveBaplieDialogId" style="display: none">
    <div class="ui buttons">
      <!-- button class="ui tiny icon button" style="font-size:10px" (click)="uploadReceiveCellLocation();" disabled>SAVE</button -->
      <button
        class="ui tiny icon button"
        style="font-size: 10px; margin-right: 5px; margin-bottom: 5px"
        (click)="closeReceiveBabplie()"
      >
        CLOSE
      </button>
      <button
        class="ui tiny icon button"
        style="font-size: 10px; margin-right: 5px; margin-bottom: 5px"
        (click)="errorReportReceiveBaplie()"
        [disabled]="disableErrorButton2"
      >
        Error Report
      </button>
    </div>
    <div
      class="ui raised segment"
      style="
        height: auto;
        margin-bottom: 0px;
        margin-right: 0px;
        margin-top: 0px;
      "
    >
      <div
        class="ui equal width padded grid"
        style="margin-left: -14px !important; margin-bottom: 15px !important"
      >
        <div
          class="ui inverted dimmer"
          [class.active]="loadingUploadBaplie == true"
          style="z-index: 1"
        >
          <div class="ui medium text loader">Loading</div>
        </div>
        <div class="sixteen wide field" style="width: 100%">
          <label style="font-size: 12px">FILE EDI (Baplie)</label>
          <upload-plugin
            #uploadPlgBaplie
            [settings]="settingUploadBaplie"
            (eventUpload)="uploadEventBaplie($event)"
          ></upload-plugin>
        </div>
      </div>
      <!-- grid-plugin id="cllGridUpload" #cllGridUpload [settings]="settingGridUpload" (gridEvent)="gridEventUpload($event)" (infoGrid)="infoGrid($event)"></grid-plugin -->
    </div>
  </div>
  <!-- End of Receive CLL Cell Locations -->

  <footer-plugin [info]="info"></footer-plugin>

  <pdf-plugin
    #pdfContainerCLL
    style="margin-left: 3px"
    [settings]="settingPDF"
    (change)="eventPDFPlugin($event)"
  ></pdf-plugin>

  <div
    id="cllDialogUploadConfirm"
    style="display: none; font-family: Lucida Console, monospace"
  >
    <form class="ui form">
      <!-- form start -->
      <div class="field" style="margin-bottom: 5px">
        <table border="0">
          <tr>
            <td width="20%" style="text-align: center">
              <i class="info circle huge icon"></i>
            </td>
            <td>
              <div [innerHTML]="frmDialogMessage"></div>
            </td>
          </tr>
        </table>
      </div>
      <div class="field" style="text-align: right">
        <button
          class="positive ui button"
          (click)="onCllDialogUploadConfirmYes()"
        >
          Yes
        </button>
        <button
          class="negative ui button"
          (click)="onCllDialogUploadConfirmNo()"
        >
          No
        </button>
      </div>
    </form>
  </div>

  <div id="idPrompter" style="display: none">
    <!-- div class="ui raised segment" style="height:auto;margin-bottom:0px;margin-right:0px;margin-top:0px;" -->
    <table border="0" style="width: 100%">
      <tbody>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td style="vertical-align: top">
            <div [ngSwitch]="prompterType">
              <div *ngSwitchCase="'information'">
                <i class="info circle big icon"></i>&nbsp;&nbsp;
              </div>
              <div *ngSwitchCase="'warning'">
                <i class="warning sign big icon"></i>&nbsp;&nbsp;
              </div>
              <div *ngSwitchCase="'announcement'">
                <i class="announcement big icon"></i>&nbsp;&nbsp;
              </div>
              <div *ngSwitchCase="'error'">
                <i class="minus circle big icon"></i>&nbsp;&nbsp;
              </div>
              <div *ngSwitchCase="'delete'">
                <i class="remove circle big icon"></i>&nbsp;&nbsp;
              </div>
              <div *ngSwitchCase="'save'">
                <i class="database big icon"></i>&nbsp;&nbsp;
              </div>
              <div *ngSwitchDefault>{{ prompterHeader }}&nbsp;&nbsp;</div>
            </div>
          </td>
          <td>
            <span [innerHTML]="prompterContent"></span>
          </td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td colspan="2" style="text-align: center">
            <div
              *ngFor="let btn of prompterButtons"
              class="ui mini buttons"
              style="position: inherit; padding-left: 0; padding-right: 0"
            >
              <div
                class="ui mini button"
                style="margin-left: 0px; margin-right: 5px"
                (click)="clickEvent(btn.event)"
              >
                <span [innerHTML]="btn.name"></span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- /div -->
  </div>
</div>
