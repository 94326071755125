<div class="ui segment">
  
  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <br />
      <div class="field">
        <div class="ui top attached tabular menu" style="background-color: beige">
          <a
            class="item"
            [class.active]="tabStatus == 'tab1'"
            data-tab="ediListing"
            (click)="initTab1()"
            >BL EDI-Listing &nbsp;
            <span
              [style.display]="tabStatus == 'tab1' ? 'block' : 'none'"
              style="color: red; text-transform: uppercase"
              >{{ appStatusLabel }}</span
            ></a
          >
          <a
            class="item"
            [class.active]="tabStatus == 'tab2'"
            data-tab="overwriteBl"
            (click)="initTab2()"
            >Create / Overwrite BL(s)&nbsp;
            <span
              [style.display]="tabStatus == 'tab2' ? 'block' : 'none'"
              style="color: red; text-transform: uppercase"
              >{{ appStatusLabel }}</span
            ></a>
        </div>
        <div
        class="ui bottom attached tab segment"
        [class.active]="tabStatus1 == 'active' && tabStatus == 'tab1'"
        id="ediList"
        data-tab="ediListing" >  <!-- BL EDI List -->
            <div class="twelve wide fields">
              <div
                class="one field"
                style="
                width: 120%;
                border: 1px solid gray;
                border-radius: 0.28571429rem;
                padding: 9px;
                padding-bottom: 0px;
                "
              >

              <toolbar-plugin  [modeToolbar]="modeToolbar" [settings]="settingToolbar1" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" >   </toolbar-plugin>
            
            <grid-plugin
            #grid
            [settings]="settingGrid"
            (gridEvent)="gridEvent($event)"
            (infoGrid)="infoGrid($event)"
            ></grid-plugin>  
            <div class="container">
                <button  class="ui button"  [disabled]="lock == true" style="color: red;" (click) = "discard($event);">Discard</button>
            </div>
            </div>
          </div> <!-- End of BL EDI listing -->
      </div>
        
     <div
      class="ui bottom attached tab segment"
      [class.active]="tabStatus2 == 'active' || tabStatus == 'tab2'"
      id="overwriteBls"
      data-tab="overwriteBl" >  <!-- Create/overwrite -->
        
          <div class="twelve wide fields">

            <div class="one field"
              style="
              width: 100%;
              border: 1px solid gray;
              border-radius: 0.28571429rem;
              padding: 9px;
              padding-bottom: 0px; " >
             
              <a class="ui red ribbon label">Stage 2 : Create / Overwrite BL(s)</a>
         
              <toolbar-plugin #toolbarSetting [modeToolbar]="modeToolbar" [settings]="settingToolbar2" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent2($event)" >   </toolbar-plugin>
             
              <div  class= "ui raised segment" style="width: 650pt">
                <div style="width: 100%;" class="required field" [class.error]="
                  model['error-vesselCode'] != null &&
                  model['error-vesselCode'] != '' &&
                  model['error-vesselCode'] != undefined">

                  <label style="font-weight: bold;">File name </label>
                  <combo-plugin 
                    #cbFileName
                    [settings]="settingFileName" 
                    (change)="changeEventFileName($event)"
                    style="width:100%;"
                    >
                  </combo-plugin>
                </div>
              </div>
              
              <grid-plugin
              #grid2
              [settings]="settingGrid2"
              (gridEvent)="gridEvent2($event)"
              (infoGrid)="infoGrid2($event)">
            </grid-plugin>  
          
          </div>
        </div> 
       </div><!-- end Create/Overview Bl -->
     </div>
      
    </form>
  </div>  <!-- end div container -->
</div>

<!-- Confirmation popup -->
<div id="UnconfirmedList" class="dialogUnconfirmedList">
  <div class="btn-UnconfirmedList">
    <button class="ui button btn-yes" (click)="proceed()">Proceed</button>
    <button class="ui button btn-no" (click)="closeUnconfirmedList();">Cancel</button>
  </div>
  <div class="wrapper">
  <table class="table">
    <tr>
      <th class="sticky-col first-col">Status</th>
      <th class="sticky-col second-col">Overwrite BL?</th>
      <th>Vessel Name</th>
      <th>Voyage No.</th>
      <th>BL No.</th>
      <th>Shipper</th>
      <th>Consignee</th>
      <th>Notify Party</th>
      <th>POR</th>
      <th>POL</th>
      <th>POD</th>
      <th>PODL</th>
      <th>FDEST</th>
    </tr>
    <tr *ngFor="let item of unconfirmedList">
      <td class="sticky-col first-col"> {{item.Status}}</td>
      <td class="sticky-col second-col">
        <div class="d-flex-checkbox" >
          <input type="radio" id="yes{{item.blNo}}" name="yes{{item.blNo}}" 
            [(ngModel)]="item.isConfirm" value="Y" [disabled]="item.Status === 'No BKG found'">
          <label for="yes{{item.blNo}}" class="mr-20px">Yes</label>

          <input type="radio" id="no{{item.blNo}}" name="no{{item.blNo}}"
            [(ngModel)]="item.isConfirm" value="N" [disabled]="item.Status === 'No BKG found'">
            
          <label for="no{{item.blNo}}">No</label>
        </div>
      </td>
      <td>{{item.vesselName}}</td>
      <td>{{item.voyageNo}}</td>
      <td>{{item.blNo}}</td>
      <td>{{item.shipper}}</td>
      <td>{{item.consignee}}</td>
      <td>{{item.notifyParty}}</td>
      <td>{{item.por}}</td>
      <td>{{item.pol}}</td>
      <td>{{item.pod}}</td>
      <td>{{item.podl}}</td>
      <td>{{item.fdest}}</td>
      
    </tr>
  </table>
  </div>

</div>


<footer-plugin [info]="info"></footer-plugin>


