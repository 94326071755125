import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { LocationGroup, LocationGroupMember } from "../shared/index";
import FormValidation from "../../utils/formValidation";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  ToolbarPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
declare var $: any;

@Component({
  selector: "app-master-location-group-page",
  templateUrl: "./master-location-group-page.component.html",
  styleUrls: ["./master-location-group-page.component.css"],
})
export class MasterLocationGroupPageComponent
  extends Validator
  implements OnInit, AfterViewInit {
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("gridDetails") gridDetails: GridPluginComponent;
  @ViewChild("cbDischargePort") cbDischargePort: ComboPluginComponent;
  @ViewChild("cbAssignedPort") cbAssignedPort: ComboPluginComponent;
  @ViewChild("cbDischargePortHistroy")
  cbDischargePortHistroy: ComboPluginComponent;
  @ViewChild("gridHistory") gridHistory: GridPluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for dialog */
  dialog: any;

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;
  modeToolbar = false;
  form = "";
  oldValid = true;
  oldCode = "";

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridDetails;
  settingCountry;
  settingDischargePort;
  settingAssignedPort;
  settingDischargePortHistory;
  settingGridHistory;
  dischargePortHistory;
  officeCode: string = "";
  updateMode: string = "";

  /* Parameter model */
  model = new LocationGroup();
  modelDetail = new LocationGroupMember();

  formValidation = new FormValidation();

  loading = false;

  lock = false;
  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  getSelectedDetails: string = "";

  /* validatorRules */
  validatorRules = {};

  constructor(
    private cdr: ChangeDetectorRef,
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [],
      buttonsDetail: [],
      createDefaultFront: true,
      createDefaultDetail: true,
      searchBy: [
        { display: "User Deleted", value: "userDeleted" },
        { display: "Date Deleted", value: "dateDeleted" }
      ],
      urlhelp: "assets/pdf/panduan.pdf",
    };

    this.settingGrid = {
      id: "gridGeneral",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocationGroups/findGeneral",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 50 },
        {
          header: "Freight Group Name",
          field: "locationGroupName",
          width: 200,
        },
        { header: "Member Port", field: "memberPort", width: 200 },
        { header: "Remarks", field: "remarks", width: 250 },
        { header: "User Created", field: "userCreated", width: 110 },
        { header: "Date Created", field: "dateCreated", width: 110 },
        { header: "User Modified", field: "userModified", width: 110 },
        { header: "Date Modified", field: "dateModified", width: 110 },
        { header: "Valid", field: "isValid", width: 80 },
        { header: "User Deleted", field: "userDeleted", width: 110 },
        { header: "Date Deleted", field: "dateDeleted", width: 110 },
        { header: "Delete", field: "isDeleted", width: 80 }
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
    };

    this.settingGridHistory = {
      id: "gridHistory",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocationGroups/findGeneralHistoryById/" +
        this.cookieService.getDefaultLocationCode().toString().trim(),
      page: 10,
      columns: [
        { header: "No", field: "no", width: 50 },
        {
          header: "Freight Group Name",
          field: "locationGroupName",
          width: 200,
        },
        { header: "Member Port", field: "memberPort", width: 200 },
        { header: "Remarks", field: "remarks", width: 250 },
        { header: "User Created", field: "userCreated", width: 110 },
        { header: "Date Created", field: "dateCreated", width: 110 },
        { header: "User Modified", field: "userModified", width: 110 },
        { header: "Date Modified", field: "dateModified", width: 110 },
        { header: "Valid", field: "isValid", width: 80 },
        { header: "Action Log", field: "actionLog", width: 110 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
    
      enablePagination: true,
    };

    this.settingGridDetails = {
      url: "",
      page: 5,
      columns: [
        { header: "Seq", field: "seqNo", width: 50 },
        { header: "Member Port", field: "assignedPortName", width: 150 },
        { header: "Freight Group", field: "dischargePortName", width: 200 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: true,
      enablePagination: false,
      enableSelectedRows: false,
    };

    this.settingDischargePortHistory = {
      id: "cbLocationGroupsHistory",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocationGroups/findGeneralHistory/'+this.cookieService.getDefaultLocationCode().toString().trim()+'/{query}',
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/*/locationName/{query}/1/20/*/*',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 200 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingDischargePort = {
      id: "cbDischargePort",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/*/locationName/{query}/1/20/*/*',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Discharge Port",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 200 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingAssignedPort = {
      id: "cbAssignedPort",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/*/locationName/{query}/1/10/*/*',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Assigned Port",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 200 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
  }

  ngOnInit() {
    $(".ui.accordion").accordion();
  }

  ngAfterViewInit() {
    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    $(".test.checkbox").checkbox();
    $(".special.cards .image").dimmer({
      on: "hover",
    });

    this.setValidatorHeader();
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";
    this.officeCode = this.cookieService
      .getDefaultLocationCode()
      .toString()
      .trim();

    this.grid.search = this.officeCode + "/*/*/*";
    this.grid.onFirst();
    this.genericUtil.setBreadcrumb(
      "Master,Entity,Master-Customer:/main/master-customer"
    );
    this.cdr.detectChanges();

    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event: any) {
    if (event != "") {
      eval(event);
    }
  }

  infoGrid(event: any) {
    this.info = event;
  }

  infoGridDetails(event: any) {
    this.info = event;
  }

  gridEvent(event: any) {
    //// console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;

      case "click":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      case "afterLoad": 
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.grid.listStore.store.forEach((element) => {
            if(element["isDeleted"] == 'Y'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);
        break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              this.handleUpdate();
              break;
            case "dblClick":
              let dt = this.grid.getSelectedValues()[0];
              if (dt.isDeleted == "Y") {
                this.message(
                  "warning",
                  "Error Input",
                  "The record has been deleted",
                  "okonly",
                  { ok: "this.loading=false;" }
                );
                return false;
              } else {
                if (this.viewOnlyStatus) {
                  this.disableToolbarButtons = "create,update,delete";
                } else {
                  this.disableToolbarButtons = "";
                }
                this.invisibleToolbarButtons = "create,history,print";

                this.modeForm = true;
                this.modeToolbar = false;

                let dt = this.grid.getSelectedValues()[0];
                let locationGroupId: string = String(dt.locationGroupId).trim();
                this.onRetrieve(locationGroupId);

                this.lock = true;
                this.gridDetails.clearSelectedValues();
                this.gridDetails.disable();
              }
              break;

            default:
              break;
          }
        }

        break;
    }
  }

  gridEventDetails(event: any) {
    //this.gridDetails.onSelectAllByValue(false);
    //this.gridDetails.is_select_all = '';
    console.log("eventgrid" + event);
    switch (event.split(".")[0]) {
      case "checked":
        break;

      case "click":
        if (
          this.gridDetails.getSelectedValues().length ==
          this.gridDetails.listStore.store.length
        ) {
          console.log("harusnya ke cek semua");
          this.gridDetails.onSelectAllByValue(true);
          this.gridDetails.is_select_all = "checked";
        } else {
          //this.gridDetails.onSelectAllByValue(false);
          this.gridDetails.is_select_all = "";
        }
        break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");

          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              console.log("masukk dblclick");
              this.setValidatorDetails();
              if (this.viewOnlyStatus) {
                this.disableToolbarButtons = "create,update,delete";
              } else {
                this.disableToolbarButtons = "save,cancel";
              }

              if (this.model["error-locationGroupName"] != null) {
                this.model["error-locationGroupName"] = "";
              }
              //console.log(this.gridDetails.listStore.store);
              console.log(this.gridDetails.getSelectedValues());

              let dt = this.gridDetails.getSelectedValues()[0];
              this.gridDetails.listStore.store.forEach((element) => {
                if (dt.no == element["no"]) {
                  this.modelDetail = new LocationGroupMember();
                  this.modelDetail = element;
                  // console.log(this.modelDetail);
                }
              });
              //console.log(this.gridDetails.getSelectedValues());
              this.cbAssignedPort.setForceValue(
                this.gridDetails.getSelectedValues()[0]["assignedPortName"]
              );
              this.modelDetail.locationCode = this.gridDetails.getSelectedValues()[0][
                "assignedPortCode"
              ];
              this.modelDetail.locationId = this.gridDetails.getSelectedValues()[0][
                "assignedPortId"
              ];
              this.getSelectedDetails = this.gridDetails.getSelectedValues()[0][
                "assignedPortId"
              ];
              this.modelDetail.locationName = this.gridDetails.getSelectedValues()[0][
                "assignedPortName"
              ];
              this.modelDetail.locationGroupCode = this.model.dischargePortCode;
              this.modelDetail.locationGroup = this.model.dischargePortName;
              this.modelDetail.locationGroupId = this.model.locationGroupId;

              $("#display").transition();
              $("#displayGrid").transition("hide");
              
              break;
            default:
              break;
          }
        }

        break;
    }
  }

  toolbarEvent(event: any) {
    //// console.log('event : ' + event);
    switch (event) {
      case "create":
        this.handleCreate();
        break;
      case "update":
        this.handleUpdate();
        break;
      case "delete":
        this.handleDelete();
        break;
      case "print":
        this.handlePrint();
        break;
      case "save":
        if (this.model.locationGroupId != "") {
          this.model.userModified = this.cookieService.getName();
          this.model.dateModified = this.genericUtil.getPSIDate();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "history":
        this.handleHistory();
        break;
      default:
        let str: string = event;
        // console.log('str'+str)
        if (str.indexOf("search") > -1) {
          this.grid.search = this.officeCode + "/" + str.replace("search:", "");
          this.grid.clearSelectedValues();
          this.grid.onFirst();
          this.handleCancel();
        } else {
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate() {
    // handle create event

    this.modeForm = true;
    this.modeToolbar = true;

    this.invisibleToolbarButtons = "";

    this.model = new LocationGroup();
    this.modelDetail = new LocationGroupMember();

    this.model.isValid = true;

    this.gridDetails.enable();
    this.gridDetails.enableSelectAll = true;
    this.gridDetails.listStore.store = [];
    this.gridDetails.loadData();

    this.cbDischargePort.ngAfterViewInit();
    this.genericUtil.setBreadcrumb(
      "Master,Entity,Master-Location-Group:/main/master-location-group,Form"
    );
  }

  handleUpdate() {
    // handle update event
    let dt = this.grid.getSelectedValues()[0];
    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;
      this.updateMode = "";
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "create,update,delete";
      } else {
        this.disableToolbarButtons = "";
      }
      this.invisibleToolbarButtons = "create,print";

      let dt = this.grid.getSelectedValues()[0];
      let locationGroupId: string = String(dt.locationGroupId).trim();
      this.onRetrieve(locationGroupId);

      this.lock = false;
      this.updateMode = "on";
      this.gridDetails.enable();
    }
  }

  handleDelete() {
    // handle delete event

    // this.loading = true;
    // let deleteList = [];
    // this.grid.getSelectedValues().forEach((element) => {
    //   var deleteObj = {"locationGroupId":"","locationGroupCode":""};
    //   deleteObj.locationGroupId = element.locationGroupId;
    //   deleteObj.locationGroupCode = element.locationGroupCode;
    //   deleteList.push(deleteObj);
    // });
    // this.genericService
    //   .POST(
    //     this.configService.config.BASE_API.toString() +
    //       "/MasterBooking/checkingListLocationGroupUsed",
    //       deleteList
    //   )
    //   .subscribe(
    //     (resp) => {
    //       if (resp.status == 200) {
            
    //         let dataRes = resp.json();
    //         console.log(dataRes['totalUsed']);
    //         if (dataRes['totalUsed'] == 0) {
    //           this.loading = false;
    //           this.message(
    //             "delete",
    //             "Deletion Record",
    //             "Confirm to delete record(s)?",
    //             "yesno",
    //             { yes: "this.deleteEvent()", no: "this.loading = false;" }
    //           );
    //         } else {
    //           this.message(
    //             "information",
    //             "Information",
    //             "Record being utilized, cannot be deleted. ",
    //             "okonly",
    //             { ok: "this.loading=false;" }
    //           );
    //         }
            
    //       }
    //     },
    //     (error) => {
    //       this.loading = false;
    //       this.info = error.message;
    //     }
    //   );
    
    this.loading = true;
    this.message(
      "delete",
      "Deletion Record",
      "Confirm to delete record(s)?",
      "yesno",
      { yes: "this.deleteEvent()", no: "this.loading = false;" }
    );
  }

  handlePrint() {
    // handle print event

    var formatName = "report-Location-Group-Export-Reports.xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/39/Location-Group-Export-Reports/*";
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });

    // var splitUrl = this.configService.config.getPDFUrl.split(":");

    // window.open(
    //  // this.configService.config.BASE_API.toString().replace("9090", "10000") +
    //  this.configService.config.getPDFUrl.replace(splitUrl[2], "10000")+
    //     "/Mreport/runReport/39/Location-Group-Export-Reports/*"
    // );
  }

  handleSave() {
    // handle save event
    console.log("isi liststore");
    console.log(this.gridDetails.listStore.store.length);
    let isValid: boolean = this.onValidate(this.model);

    if (this.gridDetails.listStore.store.length < 1) {
      console.log("masuk gaasss");
      this.message(
        "information",
        "Information",
        "No member detected.",
        "okonly",
        { ok: "this.loading=false;" }
      );
    } else {
      if (!isValid) {
        this.message(
          "save",
          "Saving data",
          "Do you want to save the record? ",
          "yesno",
          { yes: "this.savePreEvent()", no: "this.loading = false;" }
        );
      } else {
        this.loading = false;
      }
    }
  }

  handleCancel() {
    // handle cancel event
    this.model = new LocationGroup();
    this.modelDetail = new LocationGroupMember();

    this.gridDetails.onClear();

    this.modeForm = false;
    this.modeToolbar = false;
    this.updateMode = "";

    this.form = "";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";
    this.lock = false;

    this.cbDischargePortHistroy.setValue("");
    this.cbDischargePortHistroy.close();
    this.cbDischargePort.setValue("");
    this.gridHistory.onClear();
    this.getSelectedDetails = "";
  }

  handleHistory() {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = "history";

    this.invisibleToolbarButtons = "save";

    this.gridHistory.onClear();
  }

  onRetrieve(locationGroupId: string) {
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterLocationGroups/findById/" +
          locationGroupId
      )
      .subscribe((resp) => {
        let data = resp.json();
        this.setData(data);
      });
  }

  setData(data: any) {
    this.model = new LocationGroup();

    this.gridDetails.listStore.store = [];
    this.gridDetails.clearSelectedValues();

    console.log("dataaaa");
    console.log(data);

    data.forEach((element) => {
      this.model.locationGroupId = element.locationGroupId;
      this.model.dischargePortId = element.dischargePortId;
      this.model.dischargePortCode = element.dischargePortCode;
      this.model.dischargePortName = element.dischargePortName;
      this.cbDischargePort.setValue(this.model.dischargePortName);
      this.model.countryCode = element.countryCode;
      this.model.countryName = element.countryName;
      this.model.remarks = element.remarks;

      this.oldCode = this.model.dischargePortId;

      if (element.isValid == "Y") {
        this.model.isValid = true;
        this.oldValid = true;
      } else if (element.isValid == "N") {
        this.model.isValid = false;
        this.oldValid = false;
      }
      this.model.userCreated = element.userCreated;
      this.model.dateCreated = element.dateCreated;
      this.model.userModified = element.userModified;
      this.model.dateModified = element.dateModified;
      this.model.userInvalid = element.userInvalid;
      this.model.dateInvalid = element.dateInvalid;

      let det = new LocationGroup();

      if (
        element.assignedPortId != "NULL" &&
        element.assignedPortId != "" &&
        element.assignedPortId != null
      ) {
        det.locationGroupId = element.locationGroupId;
        det.no = element.locationGroupSeq;
        det.userCreated = element.userCreated;
        det.dateCreated = element.dateCreated;
        det.userModified = element.userModified;
        det.dateModified = element.dateModified;
        det.userInvalid = element.userInvalid;
        det.dateInvalid = element.dateInvalid;
        det.assignedPortId = element.assignedPortId;
        det.assignedPortCode = element.assignedPortCode;
        det.assignedPortName = element.assignedPortName;
        det.dischargePortId = element.dischargePortId;
        det.dischargePortCode = element.dischargePortCode;
        det.dischargePortName = element.dischargePortName;
        this.gridDetails.listStore.addData(det);
      } else {
      }
    });

    this.gridDetails.loadData();
    this.updateSeqNo();
  }

  changeEventDischargePortHistory(event: any) {
    if (event["locationId"] != null) {
      this.dischargePortHistory = event["locationId"];
    } else {
      this.dischargePortHistory = "";
    }

    this.gridHistory.onHistory(this.dischargePortHistory);
  }

  changeEventLocationGroupsHistory(event: any) {}

  afterSave() {
    this.modeForm = false;
    this.modeToolbar = false;
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.form = "";
    this.lock = false;

    if (this.grid.getSelectedValues().length >= 1) {
      this.grid.onSortDir("dateModified", "DESC");
    } else {
      this.grid.onSortDir("dateCreated", "DESC");
    }

    this.grid.clearSelectedValues();
    this.grid.onFirst();
  }

  afterDelete() {
    this.modeForm = false;
    this.modeToolbar = false;
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.form = "";
    this.lock = false;

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }

  savePreEvent() {
    var valValid = "Y";

    if (this.model.isValid == false) {
      valValid = "N";
    }

    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterLocationGroups/getValidLocation/" +
          this.officeCode +
          "/" +
          this.model.dischargePortId.toUpperCase()
      )
      .subscribe((kesp) => {
        console.log("hohoh" + kesp.json()["content"][0].cnt);
        console.log(
          "old valid" + this.oldValid + " valid new" + this.model.isValid
        );
        console.log(
          "old vessel" +
            this.oldCode +
            " new vessel" +
            this.model.dischargePortId
        );
        console.log("val valid " + valValid);
        if (kesp.ok) {
          var lenResp = Object.keys(kesp.json()["content"]).length;

          if (
            parseInt(kesp.json()["content"][0].cnt) == 0 ||
            (parseInt(kesp.json()["content"][0].cnt) > 0 && valValid == "N") ||
            (parseInt(kesp.json()["content"][0].cnt) > 0 &&
              this.oldCode == this.model.dischargePortId &&
              this.oldValid == this.model.isValid &&
              this.updateMode == "on")
          ) {
            this.saveEvent();
          } else {
            this.message(
              "information",
              "Information",
              "Duplicate freight group detected.",
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        }
      });
  }

  saveEvent() {
    this.loading = true;
    let saveList = [];

    console.log("isi model");
    console.log(this.model);

    //if(this.gridDetails.listStore.store.length > 0){

    this.gridDetails.listStore.store.forEach((element) => {
      element["countryCode"] = String(this.model.countryCode)
        .toUpperCase()
        .trim();
      element["remarks"] = String(this.model.remarks).toUpperCase().trim();
      element["isValid"] = this.model.isValid;

      if (this.model.dateCreated == "") {
        element["dateCreated"] = this.genericUtil.getPSIDate();
        element["userCreated"] = this.cookieService.getName();
      } else {
        element["dateCreated"] = this.model.dateCreated;
        element["userCreated"] = this.model.userCreated;
      }

      if (this.model.locationGroupId != "") {
        if (this.oldValid && !this.model.isValid) {
          element["dateInvalid"] = this.genericUtil.getPSIDate();
          element["userInvalid"] = this.cookieService.getName();
        }

        element["userModified"] = this.model.userModified;
        element["dateModified"] = this.model.dateModified;
      } /*else{

            element['dateCreated'] = this.genericUtil.getPSIDate();
            element['userCreated'] = this.model.userCreated;
          }*/

      element["officeCode"] = this.officeCode;
      saveList.push(element);
    });
    // }
    /*else{
       var dateBikin = "";
       var userBikin = "";

       var dateEdit = "";
       var userEdit = "";

       var dateValid = "";
       var userValid = "";

       if(this.model.locationGroupId != ""){

        if(this.oldValid && !this.model.isValid){

          dateValid = this.genericUtil.getPSIDate();
          userValid = this.cookieService.getName();

        }

           userEdit = this.model.userModified;
            dateEdit = this.model.dateModified;

       }

    if(this.model.dateCreated == ""){
      dateBikin = this.genericUtil.getPSIDate();
      userBikin=  this.cookieService.getName();
    }else{
      dateBikin =  this.model.dateCreated;
      userBikin = this.model.userCreated;
    }

     saveList.push({


      countryCode: String(this.model.countryCode).toUpperCase().trim(),
      countryName: "",
      dischargePortId:String(this.model.dischargePortId).toUpperCase().trim(),
      dischargePortCode: String(this.model.dischargePortCode).toUpperCase().trim(),
      dischargePortName: String(this.model.dischargePortName).toUpperCase().trim(),
      isValid: this.model.isValid,
      dateCreated: dateBikin,
      userCreated: userBikin,
      dateModified: dateEdit,
      userModified: userEdit,
      dateInvalid: dateValid,
      userInvalid: userValid,
      locationGroupId:String(this.model.locationGroupId.toUpperCase().trim()),
      officeCode:this.officeCode,
      locationGroupSeq : 1,
      remarks : this.model.remarks



     })
   }*/

    console.log("savelist");
    console.log(saveList);

    //this.configService.config.BASE_API.toString() +

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterLocationGroups/save",
        saveList
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200 && resp.json()["message"] == "OK") {
            //clear all
            this.model = new LocationGroup();
            this.modelDetail = new LocationGroupMember();
            this.cbDischargePort.setValue("");

            this.gridDetails.clearSelectedValues();
            this.gridDetails.is_select_all = "";
            this.gridDetails.listStore.store = [];
            this.gridDetails.loadData();

            this.loading = false;
            this.info = "save successfully";
            this.updateMode = "";

            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.loading=false;this.afterSave();" }
            );
          } else {
            this.loading = false;
            this.message(
              "warning",
              "Warning",
              resp.json()["content"],
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  deleteEvent() {
    //// console.log('delete event');
    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      deleteList.push(element.locationGroupId);
    });

    var currentdate = new Date(); 
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-" 
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"  
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":" 
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"locationGroupId":[]}
    paramsDelete.locationGroupId = deleteList;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
        //   "/MasterLocationGroups/delete",
        // deleteList
        "/MasterLocationGroups/softDelete",
        paramsDelete
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            this.model = new LocationGroup();
            this.modelDetail = new LocationGroupMember();
            this.gridDetails.listStore.store = [];
            this.gridDetails.loadData();

            this.loading = false;

            this.message(
              "information",
              "Information",
              "Record deleted successfully. ",
              "okonly",
              { ok: "this.loading=false;this.afterDelete();" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  setValidatorHeader() {
    this.validatorRules = {
      dischargePortName: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Discharge Port Name.",
          },
        ],
      },
      remarks: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regAllChar,
            prompt: "Invalid remarks",
          },
        ],
      },
    };
  }

  setValidatorDetails() {
    this.validatorRules = {
      locationName: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Location Name.",
          },
        ],
      },
    };
  }

  addDetail() {
    this.setValidatorDetails();
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "save,cancel";
    }

    if (this.model["error-locationGroupName"] != null) {
      this.model["error-locationGroupName"] = "";
    }

    this.modelDetail = new LocationGroupMember();
    this.cbAssignedPort.setValue("");

    $("#display").transition();
    $("#displayGrid").transition("hide");
    this.getSelectedDetails = "";
  }

  removeDetail() {
    this.gridDetails.getSelectedValues().forEach((element) => {
      // console.log(element);
      this.gridDetails.listStore.removeData(element);
    });
    this.gridDetails.loadData();
    this.updateSeqNo();
    this.gridDetails.clearSelectedValues();
  }

  cancelDetail() {
    this.setValidatorHeader();
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "";
    }
    $("#display").transition();
    $("#displayGrid").transition();
    this.gridDetails.clearSelectedValues();
  }

  saveDetail() {
    var dblFlag = 0;
    var soso = [];

    this.gridDetails.listStore.store.forEach((key) => {
      console.log("port id" + key.assignedPortId);
      console.log("selected details" + this.getSelectedDetails);
      if (key.assignedPortId != this.getSelectedDetails) {
        if (key.assignedPortId == this.modelDetail.locationId) dblFlag = 1;
      }
    });

    //console.log('sososos');
    //console.log(this.modelDetail);

    if (dblFlag == 1) {
      this.message(
        "information",
        "Information",
        "Duplicate member port detected.",
        "okonly",
        { ok: "this.loading=false;" }
      );
    } else {
      console.log(this.gridDetails);
      if (!this.onValidate(this.modelDetail)) {
        // console.log(this.modelDetail.no);
        if (this.modelDetail.no == 0) {
          let data = new LocationGroup();
          data.assignedPortName = String(this.modelDetail.locationName)
            .toUpperCase()
            .trim();
          data.assignedPortCode = String(this.modelDetail.locationCode)
            .toUpperCase()
            .trim();
          data.assignedPortId = String(this.modelDetail.locationId)
            .toUpperCase()
            .trim();
          data.dischargePortName = String(this.modelDetail.locationGroup)
            .toUpperCase()
            .trim();
          data.dischargePortCode = String(this.modelDetail.locationGroupCode)
            .toUpperCase()
            .trim();
          data.dischargePortId = String(this.model.dischargePortId)
            .toUpperCase()
            .trim();
          data.locationGroupId = String(
            this.model.locationGroupId.toUpperCase().trim()
          );

          this.gridDetails.listStore.addData(data);
          this.gridDetails.loadData();
          this.gridDetails.clearSelectedValues();

          this.updateSeqNo();

          this.modelDetail = new LocationGroupMember();

          this.setValidatorHeader();
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
          $("#display").transition();
          $("#displayGrid").transition();
        } else {
          let data = new LocationGroup();
          data.no = this.modelDetail.no;
          data.assignedPortName = String(this.modelDetail.locationName)
            .toUpperCase()
            .trim();
          data.assignedPortCode = String(this.modelDetail.locationCode)
            .toUpperCase()
            .trim();
          data.assignedPortId = String(this.modelDetail.locationId)
            .toUpperCase()
            .trim();
          data.dischargePortName = String(this.modelDetail.locationGroup)
            .toUpperCase()
            .trim();
          data.dischargePortCode = String(this.modelDetail.locationGroupCode)
            .toUpperCase()
            .trim();
          data.dischargePortId = String(this.model.dischargePortId)
            .toUpperCase()
            .trim();
          data.locationGroupId = String(
            this.model.locationGroupId.toUpperCase().trim()
          );
          console.log("dataaa");
          console.log(data);

          this.gridDetails.listStore.updateData(this.modelDetail.no - 1, data);
          this.gridDetails.loadData();
          this.updateSeqNo();

          this.modelDetail = new LocationGroupMember();

          this.setValidatorHeader();
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
          this.gridDetails.clearSelectedValues();
          $("#display").transition();
          $("#displayGrid").transition();
        }
      }
    }
  }

  checkDuplicateInObject(propertyName, inputArray) {
    var seenDuplicate = false,
      testObject = {};

    inputArray.map(function (item) {
      var itemPropertyName = item[propertyName];
      if (itemPropertyName in testObject) {
        testObject[itemPropertyName].duplicate = true;
        item.duplicate = true;
        seenDuplicate = true;
      } else {
        testObject[itemPropertyName] = item;
        delete item.duplicate;
      }
    });

    return seenDuplicate;
  }

  updateSeqNo() {
    let index: number = 0;
    this.gridDetails.listStore.store.forEach((element) => {
      element["seqNo"] = element["no"];
      element["locationGroupSeq"] = element["no"];
      this.gridDetails.listStore.updateData(index, element);
      index++;
    });
  }

  generateLocationGroupsHistoryReport(event: any) {
    if (this.dischargePortHistory == "" || this.dischargePortHistory == null) {
      this.message(
        "information",
        "Information",
        "Please select discharge port name.",
        "okonly",
        { ok: "this.loading=false;" }
      );
    } else {
      var formatName = "report-Freight-Group-Export-Reports"+".xls";
      var uri = this.configService.config.BASE_API.toString()+
      "?q=" +
        "/Mreport/runReport/59/Freight-Group-Export-Reports/"
        +
        this.dischargePortHistory +
        "@" +
        this.officeCode
      var self = this;
      this.loading = true;
      $.ajax({
        url: uri,
        method: "GET",
        beforeSend: function(request) {
          request.setRequestHeader("Token", localStorage.getItem("token"));
        },
        xhrFields: {
          responseType: "blob",
        },
        success: function (data) {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = formatName;
          a.click();
          window.URL.revokeObjectURL(url);
          self.loading = false;
        },
      });
      // window.open(
      //   this.configService.config.BASE_API.toString().replace("9090", "10000").replace("5001","10000") +
      //     "/Mreport/runReport/59/Freight-Group-Export-Reports/" +
      //     this.dischargePortHistory +
      //     "@" +
      //     this.officeCode
      // );
      //window.open(BASE_API + '/Mreport/runReport/40/History-Customer-' + this.customerIdHistory + '-' + this.customerCodeHistory +'/'+this.customerIdHistory + '@' + this.valHistoryStartDate + '@' + this.valHistoryEndDate);
    }
  }

  gridEventHistory(event: any) {
    //do nothing
  }

  retrieveHistory(event: any) {
    this.gridHistory.loadData();
  }

  infoGridHistory(event: any) {
    // this.info=event;
  }

  changeEventDischargePort(event: any) {
    // console.log(event);
    this.model["error-dischargePortName"] = null;

    if (event["locationName"] != null) {
      this.model.dischargePortName = event["locationName"];
    } else {
      this.model.dischargePortName = "";
    }

    if (event["locationCode"] != null) {
      this.model.dischargePortCode = event["locationCode"];
    } else {
      this.model.dischargePortCode = "";
    }

    if (event["locationId"] != null) {
      this.model.dischargePortId = event["locationId"];
    } else {
      this.model.dischargePortId = "";
    }

    if (event["countryCode"] != null) {
      this.model.countryCode = event["countryCode"];
    } else {
      this.model.countryCode = "";
    }

    if (this.gridDetails.listStore.store.length > 0) {
      this.gridDetails.listStore.store.forEach((element) => {
        element["dischargePortName"] = String(this.model.dischargePortName)
          .toUpperCase()
          .trim();
        element["dischargePortCode"] = String(this.model.dischargePortCode)
          .toUpperCase()
          .trim();
        element["dischargePortId"] = String(this.model.dischargePortId)
          .toUpperCase()
          .trim();
      });
    }
  }

  changeEventAssignedPort(event: any) {
    if (event["locationName"] != null) {
      this.modelDetail.locationName = event["locationName"];
    } else {
      this.modelDetail.locationName = "";
    }
    if (event["locationCode"] != null) {
      this.modelDetail.locationCode = event["locationCode"];
    } else {
      this.modelDetail.locationCode = "";
    }
    if (event["locationId"] != null) {
      this.modelDetail.locationId = event["locationId"];
    } else {
      this.modelDetail.locationId = "";
    }
    this.modelDetail.locationGroup = this.model.dischargePortName;
    this.modelDetail.locationGroupCode = this.model.dischargePortCode;
    this.modelDetail.locationGroupId = this.model.dischargePortId;
  }

  markRedRow(dataNo){
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }      
    });
  }

  markDefaultRow(dataNo) {
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }      
    });
  }
}
