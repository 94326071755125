<div class="ui segment">

    <div class="ui divided items">
      <toolbar-plugin #toolbarSetting [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

      <div [style.display]="modeForm?'none':'block'">
        <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Local QLEC Master</h4>
        <!-- Begin of Grid -->
        <grid-plugin #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
        <!-- End of Grid -->
      </div>

      <div [style.display]="modeForm?'block':'none'">
        <div class="ui segment" style="padding-left:1%;padding-right:20%;">
          <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:99">
            <div class="ui medium text loader">Loading</div>
          </div>

          <div id='container'>

            <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

            <!-- Begin of Form -->
            <form class="ui form" [style.display]="form == ''?'block':'none'" >
              <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Local QLEC Master</h4>

              <div class="ui raised segment">
                <div class="one field" style="width:100%">
                    <div class="field">
                        <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                          <input type="checkbox" [(ngModel)]="model['isValid']" name="isValid" tabindex="0" class="hidden">
                          <label>Valid?</label>
                        </div>
                    </div>

                    <div class="ten wide fields">
                        <!-- <div class="required field" style="width:50%;margin-top:10px;" [class.error]="model['error-length'] != null && model['error-length'] != ''">
                            <label>Length</label>
                            <input type="text" [(ngModel)]="model['length']" name="length" maxLength="10" placeholder="Length" [disabled]="lock == true">
                            <div class = "ui basic red pointing prompt label transition error-message-hidden"
                            [class.error-message-visible]="model['error-length'] != null && model['error-length'] != ''">
                                {{model['error-length']}}
                            </div>
                        </div> -->
                        <div class="required field" style="width:50%" [class.error]="model['error-length'] != null && model['error-length'] != ''">
                            <label>Length</label>
                            <input #length list="length" name="length" maxLength="5" placeholder="Length" [(ngModel)]="model['length']"
                                  (keypress)="noKey($event)"
                                  required
                                  autocomplete="off"
                                  [disabled]="lock == true"
                                  >
                                  <datalist id="length">
                                      <option value="20'">
                                      <option value="40'">
                                      <option value="45'">
                                      <option value="HC20'">
                                      <option value="HC40'">
                                      <option value="HC45'">
                                  </datalist>
                            <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-length'] != null && model['error-length'] != ''">
                              {{model['error-length']}}
                            </div>
                          </div>
                    </div>
                    <div class="two fields">
                      <div class="field" style="width:50%" [class.error]="model['error-comparison'] != null && model['error-comparison'] != ''">
                        <label>Comparison</label>
                        <input #comparisonQlec list="comparison" name="comparison" maxLength="30" placeholder="Comparison" [(ngModel)]="model['comparison']"
                               (keypress)="noKey($event)"
                              required
                              autocomplete="off"
                              [disabled]="lock == true"
                              >
                              <datalist id="comparison">
                                  <option value="< Less than">
                                  <option value="<= Less than or Equal to">
                                  <option value="> Greater than">
                                  <option value=">= Greater than or Equal to">
                              </datalist>
                        <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-comparison'] != null && model['error-comparison'] != ''">
                          {{model['error-comparison']}}
                        </div>
                      </div>
                      <div class="field">
                          <div class="required field" [class.error]="model['error-weight'] != null && model['error-weight'] != ''">
                              <label>Weight (KGS)</label>
                              <input type="text" [(ngModel)]="model['weight']" name="weight" maxLength="10" placeholder="Weight" [disabled]="lock == true">
                              <div class = "ui basic red pointing prompt label transition error-message-hidden"
                              [class.error-message-visible]="model['error-weight'] != null && model['error-weight'] != ''">
                                  {{model['error-weight']}}
                              </div>
                          </div>
                      </div>
                    </div>
                    <div class="two fields">
                      <!-- <div class="field">
                          <div class="required field" [class.error]="model['error-status'] != null && model['error-status'] != ''">
                              <label>Status</label>
                              <input type="text" [(ngModel)]="model['status']" name="status" maxLength="1" placeholder="Status" [disabled]="lock == true">
                              <div class = "ui basic red pointing prompt label transition error-message-hidden"
                              [class.error-message-visible]="model['error-status'] != null && model['error-status'] != ''">
                                  {{model['error-status']}}
                              </div>
                          </div>
                      </div> -->
                      <div class="required field" style="width:50%" [class.error]="model['error-status'] != null && model['error-status'] != ''">
                          <label>Status</label>
                          <input #status list="status" name="status" maxLength="2" placeholder="status" [(ngModel)]="model['status']"
                                 (keypress)="noKey($event)"
                                required
                                autocomplete="off"
                                [disabled]="lock == true"
                                >
                                <datalist id="status">
                                    <option value="E">
                                    <option value="F">
                                </datalist>
                          <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-status'] != null && model['error-status'] != ''">
                            {{model['error-status']}}
                          </div>
                      </div>

                      <!-- <div class="field">
                          <div class="required field" [class.error]="model['error-type'] != null && model['error-type'] != ''">
                              <label>Type</label>
                              <input type="text" [(ngModel)]="model['type']" name="type" maxLength="10" placeholder="Type" [disabled]="lock == true">
                              <div class = "ui basic red pointing prompt label transition error-message-hidden"
                              [class.error-message-visible]="model['error-type'] != null && model['error-type'] != ''">
                                  {{model['error-type']}}
                              </div>
                          </div>
                      </div> -->
                      <div class="required field" style="width:50%" [class.error]="model['error-type'] != null && model['error-type'] != ''">
                          <label>Type</label>
                          <input #type list="type" name="type" maxLength="5" placeholder="type" [(ngModel)]="model['type']"
                                (keypress)="noKey($event)"
                                required
                                autocomplete="off"
                                [disabled]="lock == true"
                                >
                                <datalist id="type">
                                    <option value="ALL">
                                </datalist>
                          <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-type'] != null && model['error-type'] != ''">
                            {{model['error-type']}}
                          </div>
                      </div>
                    </div>
                </div>
              </div>
              <div class="ui raised segment">
                <div class="two fields">
                  <div class="field">
                    <label>Created By</label>
                    <input type="text"  [(ngModel)]="model['userCreated']" name="userCreated"  placeholder="Created By" disabled readonly style="background-color: lightgray">
                  </div>
                  <div class="field">
                    <label>Created Date</label>
                    <input type="text"  [(ngModel)]="model['dateCreated']" name="dateCreated"  placeholder="Created Date" disabled readonly style="background-color: lightgray">
                  </div>
                </div>
                <div class="two fields">
                  <div class="field">
                    <label>Last Modified By</label>
                    <input type="text"  [(ngModel)]="model['userModified']" name="userModified"  placeholder="Last Modified By" disabled readonly style="background-color: lightgray">
                  </div>
                  <div class="field">
                    <label>Last Modified Date</label>
                    <input type="text"  [(ngModel)]="model['dateModified']" name="dateModified"  placeholder="Last Modified Date" disabled readonly style="background-color: lightgray">
                  </div>
                </div>
                <div class="two fields">
                  <div class="field">
                    <label>Invalid By</label>
                    <input type="text"  [(ngModel)]="model['userInvalid']" name="userInvalid"  placeholder="Invalid By" disabled readonly style="background-color: lightgray">
                  </div>
                  <div class="field">
                    <label>Invalid Date</label>
                    <input type="text"  [(ngModel)]="model['dateInvalid']" name="dateInvalid"  placeholder="Invalid Date" disabled readonly style="background-color: lightgray">
                  </div>
                </div>
              </div>

            </form>
            <!-- End of Form -->
            <!-- Begin of History Form -->
          <form class="ui form" [style.display]="form == 'history'?'block':'none'" >
            <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">History Master QLEC</h4>
            <div class="ui raised segment">
              <div class="one fields" style="margin-top:10px;">
                <div class="field">
                  <button class="ui button" (click)="retrieveHistory($event)">Retrieve</button>
                  <button class="ui button" (click)="generateQlecHistoryReport($event)">Export</button>
                </div>
              </div>

              <div class="two fields">
                <div class="field">
                  <label>Length</label>
                  <combo-plugin #cbQlecHistory [settings]="settingQlecHistory" (change)="changeEventQlec($event)"></combo-plugin>
                </div>
              </div>

              <div class="field">
                <grid-plugin2 #gridHistory [settings]="settingGridHistory" (gridEvent)="gridEventHistory($event)" (infoGrid)="infoGridHistory($event)"></grid-plugin2>
              </div>

            </div>
          </form>
          <!-- End of History Form -->
          </div>
        </div>
      </div>

    </div>
  </div>

  <footer-plugin [info]="info"></footer-plugin>
