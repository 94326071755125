import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ComboPluginComponent, ConfigService, CookieService, GenericService, GenericUtil, GridPluginComponent, ListStore, Validator } from "sibego-ui-library";
import { BLHeader, jasperModel, specialCharUtil } from "../shared/index";

declare var $: any;
import * as FileSaver from 'file-saver';

export class SelectModel {
  code: String = "";
  name: String = "";

  constructor() {}
}
export class Kolom {
  header: string = "";
  field: string = "";
  sort: string = "false";
  ds: SelectModel[] = [];

  constructor() {}
}


export class BLDraft {
  blNo: string = "";
  columns: Kolom[] = [];
  id: string = "";
  no: number = 0;
  select: string = "";
  select_column_checkbox: string = "";
  locationName: string = "";

  constructor() {}
}

@Component({
  selector: "app-report-bl-vgm-page",
  templateUrl: "./report-bl-vgm-page.component.html",
  styleUrls: ["./report-bl-vgm-page.component.css"],
})
export class ReportBlVgmPageComponent extends Validator implements OnInit, AfterViewInit {

  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild("cbLoadPort") cbLoadPort: ComboPluginComponent;
  @ViewChild("cbDischargePort") cbDischargePort: ComboPluginComponent;
  @ViewChild("cbPOR") cbPOR: ComboPluginComponent;
  @ViewChild("cbPOD") cbPOD: ComboPluginComponent;
  @ViewChild("grid") grid: GridPluginComponent;

  dialog: any;
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingLoadPort;
  settingDischargePort;
  settingPOD;
  settingPOR;
  settingGrid;

  modeToolbar: Boolean = false;
  loading: Boolean = false;
  isCOC: Boolean = true;
  isSOC: Boolean = false;
  isSLOT: Boolean = false;
  isSOCTS: Boolean = false;
  isCOCTS: Boolean = false;
  lock: Boolean = false;
  lockButtonRetrieve: Boolean = true;
  isError: Boolean = false;
  lockHeader: boolean = false;

  cCOC: String;
  cSOC: String;
  cCOCTs: String;
  cSOCTs: String;
  cSlot: String;
  isSelection: String;
  IsContOwn: String;
  isSort: String = "";

  lengthArray: number = 0;

  disableToolbarButtons = "";
  invisibleToolbarButtons = "";
  addRemoveButton: string = "none";
  blFcPop: string = "";

  model = new BLHeader();
  sCharUtil = new specialCharUtil();
  validatorRules = {};

  officeCode =
    this.cookieService.getDefaultLocationCode() == null
      ? "*"
      : this.cookieService.getDefaultLocationCode();
  officeId =
    this.cookieService.getDefaultLocationId() == null
      ? "*"
      : this.cookieService.getDefaultLocationId();
  locationName: any;

  constructor(
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private _http: HttpClient
  ) {

    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "OK", event: "ok", icon: "print" },
        { name: "Cancel", event: "cancel", icon: "cancel" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "VGM",
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "-- Vessel --",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 150 },
        { header: "Vessel Name", field: "vesselName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "-- Voyage --",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingLoadPort = {
      id: "cbLoadPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        // {header: 'Location Id', field: 'locationId', width:100},
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingDischargePort = {
      id: "cbDiscPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        //{header: 'Location Id', field: 'locationId', width:100},
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingPOR = {
      id: "cbPOR",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        //{header: 'Location Id', field: 'locationId', width:100},
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingPOD = {
      id: "cbPOD",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        // {header: 'Location Id', field: 'locationId', width:100},
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingGrid = {
      id: "grid",
      url: "", //this.configService.config.BASE_API.toString() + '/BLReport/findBLByVessel',
      page: "10",
      columns: [
        { header: "B/L No", field: "blNo", width: 200, editType: "text" },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      enableSelectedRows: false,
      sortingColumns: "blNo",
      sortingDirection: "ASC",
      editable: true,
      height: 500,
      minHeight: 500,
      autoSize: "auto",
    };


  }

  ngOnInit() { }


  ngAfterViewInit() {
    this.officeCode = this.cookieService.getDefaultLocationCode() == null
      ? "*" : this.cookieService.getDefaultLocationCode();
    this.officeId = this.cookieService.getDefaultLocationId() == null
        ? "*" : this.cookieService.getDefaultLocationId();

    this.afterViewMode();
  }

  afterViewMode() {
    // this.userId = this.cookieService.getName();
    // this.userEmail = localStorage.getItem("logged").split("|")[1];
    // this.userIdCode = localStorage.getItem("logged").split("|")[3];
    this.lockHeader = false;
    this.lock = true;
    this.model.blOceanVesselBound = "O";
    this.isSelection = "BL";
    this.addRemoveButton = "block";

    this.cCOC = "COC";
    this.cSOC = "";
    this.cCOCTs = "";
    this.cSOCTs = "";
    this.cSlot = "";
    this.IsContOwn =
      this.cCOC +
      "-" +
      this.cSOC +
      "-" +
      this.cCOCTs +
      "-" +
      this.cSOCTs +
      "-" +
      this.cSlot;
  }

  toolbarEvent(event) {
    switch (event) {
      case "ok":
        this.handlePrint();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "close":
        this.router.navigateByUrl("/main/home");
        break;
    }
  }

  changeEventVessel(event) {
    console.log("EVENTnya :" + event.vesselCode);
    if (
      event.vesselId == null ||
      event.vesselId == "" ||
      event.vesselId == undefined
    ) {
      console.log("Vessel kosong");
      this.model.blOceanVesselId = "";
      this.model.blOceanVesselCode = "";
    } else {
      this.model.blOceanVesselId = event.vesselId;
      this.model.blOceanVesselCode = event.vesselCode;

      this.cbVoyage.disableCombo = false;
      //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/"+this.param.bOceanVesselBound+"/{query}/" + this.cookieService.getDefaultLocationCode());
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        event.vesselId +
        "/" +
        this.model.blOceanVesselBound.toUpperCase() +
        "/voyage_like={query}"
      );
      this.cbVoyage.setValue("");
    }

    this.cbVessel.setUrl(
      this.configService.config.BASE_API.toString() +
      "/MasterVessels/findByComboBoxControl/vesselName={query}"
    );
  }

  changeEventVoyage(event) {
    if (
      event.voyage == null ||
      event.voyage == "" ||
      event.voyage == undefined
    ) {
      this.model.blOceanVesselVoyage = "";
    } else {
      this.model.blOceanVesselVoyage = event.voyage;
    }
  }

  changeBound(event) {
    this.model.blOceanVesselBound = event.target.value;
    this.cbVoyage.disableCombo = false;
    this.cbVoyage.setUrl(
      this.configService.config.BASE_API.toString() +
      "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
      this.cookieService.getDefaultLocationCode() +
      "/" +
      this.model.blOceanVesselId +
      "/" +
      this.model.blOceanVesselBound.toUpperCase() +
      "/voyage_like={query}"
    );
    this.cbVoyage.setValue("");

    this.model.blOceanVesselVoyage = "";
  }


  changeContainerCOC(event) {
    if (event.target.checked) {
      this.isCOC = true;
      this.cCOC = "COC";
    } else {
      this.isCOC = false;
      this.cCOC = "";
    }
  }

  changeContainerSOC(event) {
    if (event.target.checked) {
      this.isSOC = true;
      this.cSOC = "SOC";
    } else {
      this.isSOC = false;
      this.cSOC = "";
    }
  }

  changeContainerCOCTS(event) {
    if (event.target.checked) {
      this.isCOCTS = true;
      this.cCOCTs = "COCTS";
    } else {
      this.isCOCTS = false;
      this.cCOCTs = "";
    }
  }

  changeContainerSOCTS(event) {
    if (event.target.checked) {
      this.isSOCTS = true;
      this.cSOCTs = "SOCTS";
    } else {
      this.isSOCTS = false;
      this.cSOCTs = "";
    }
  }

  changeContainerSLOT(event) {
    if (event.target.checked) {
      this.isSLOT = true;
      this.cSlot = "SLOT";
    } else {
      this.isSLOT = false;
      this.cSlot = "";
    }
  }

  changeSelection(event) {
    this.isError = false;
    if (event.target.value == "Vessel") {
      this.vesselMode();
    } else if (event.target.value == "BL") {
      this.blMode();
    } else if (event.target.value == "LP") {
      this.isSelection = "LP";
      this.addRemoveButton = "none";
      this.lockButtonRetrieve = false;
      this.cbLoadPort.disableCombo = false;
      this.disableDiscPort();
      this.disablePOD();
      this.disablePOR();
      this.grid.onClear();
      this.lock = true;
      this.lockHeader = true;
    } else if (event.target.value == "DP") {
      this.clearValidatorError();
      this.isSelection = "DP";
      this.addRemoveButton = "none";
      this.lockButtonRetrieve = false;
      this.disableLoadPort();
      this.cbDischargePort.disableCombo = false;
      this.disablePOD();
      this.disablePOR();
      this.grid.onClear();
      this.lock = true;
      this.lockHeader = true;
    } else if (event.target.value == "POD") {
      this.clearValidatorError();
      this.isSelection = "POD";
      this.addRemoveButton = "none";
      this.lockButtonRetrieve = false;
      this.disableLoadPort();
      this.disableDiscPort();
      this.disablePOR();
      this.cbPOD.disableCombo = false;
      this.grid.onClear();
      this.lock = true;
      this.lockHeader = true;
    } else if (event.target.value == "POR") {
      this.clearValidatorError();
      this.isSelection = "POR";
      this.addRemoveButton = "none";
      this.lockButtonRetrieve = false;
      this.disableLoadPort();
      this.disableDiscPort();
      this.disablePOD();
      this.cbPOR.disableCombo = false;
      this.grid.onClear();
      this.lock = true;
      this.lockHeader = true;
    }
  }

  vesselMode() {
    console.log("event vessel : " + event);
    this.isSelection = "Vessel";
    this.addRemoveButton = "none";
    this.lockButtonRetrieve = false;
    this.disableLoadPort();
    this.disableDiscPort();
    this.disablePOD();
    this.disablePOR();
    this.grid.onClear();
    this.lock = true;
    this.lockHeader = true;
    this.clearValidatorError();
  }

  disableLoadPort() {
    this.cbLoadPort.disableCombo = true;
    this.cbLoadPort.clearSelect;
    this.cbLoadPort.setValue("");
  }

  disableDiscPort() {
    this.cbDischargePort.disableCombo = true;
    this.cbDischargePort.clearSelect;
    this.cbDischargePort.setValue("");
  }

  disablePOR() {
    this.cbPOR.disableCombo = true;
    this.cbPOR.clearSelect;
    this.cbPOR.setValue("");
  }

  disablePOD() {
    this.cbPOD.disableCombo = true;
    this.cbPOD.clearSelect;
    this.cbPOD.setValue("");
  }

  handleCancel() {
    this.clearMode();
  }

  setBL() {
    this.clearValidatorError();
    this.isSelection = "BL";
    this.addRemoveButton = "block";
    this.lockButtonRetrieve = true;
    this.disableLoadPort();
    this.disableDiscPort();
    this.disablePOD();
    this.disablePOR();
    this.grid.editable = true;
    this.cbVessel.clearSelect;
    this.cbVessel.setValue("");
    this.cbVoyage.clearSelect;
    this.cbVoyage.setValue("");
    this.cbVoyage.setUrl("");
    this.model.blOceanVesselBound = "O";
    this.lock = true;
    this.lockHeader = false;
  }

  blMode() {
    this.setBL();
    this.isSelection = "BL";
    this.addRemoveButton = "block";
    this.grid.onClear();
  }

  clearMode() {
    this.cbVessel.setValue("");
    this.cbVessel.clearSelect();
    this.cbVoyage.setValue("");
    this.cbVoyage.setUrl("");
    this.cbVoyage.clearSelect();
    this.cbLoadPort.setValue("");
    this.cbLoadPort.clearSelect();
    this.cbDischargePort.setValue("");
    this.cbDischargePort.clearSelect();
    this.cbPOD.setValue("");
    this.cbPOD.clearSelect();
    this.cbPOR.setValue("");
    this.cbPOR.clearSelect();

    this.isCOC = true;
    this.isSOC = false;
    this.isSOCTS = false;
    this.isCOCTS = false;
    this.isSLOT = false;
    this.cCOC = "COC";
    this.cSOC = "";
    this.cSOCTs = "";
    this.cCOCTs = "";
    this.cSlot = "";

    this.blMode();
    this.IsContOwn =
      this.cCOC +
      "-" +
      this.cSOC +
      "-" +
      this.cCOCTs +
      "-" +
      this.cSOCTs +
      "-" +
      this.cSlot;
    this.lockButtonRetrieve = true;
    this.cbLoadPort.disableCombo = true;
    this.cbDischargePort.disableCombo = true;
    this.cbPOD.disableCombo = true;
    this.cbPOR.disableCombo = true;

    this.lock = true;
    this.lockHeader = false;
    this.grid.listStore.store.splice(0, this.grid.listStore.store.length);
    this.grid.url = "";
    this.model.blOceanVesselBound = "O";
  }


  clearValidatorError() {
    this.model["error-blOceanVesselCode"] = "";
    this.model["error-blOceanVesselVoyage"] = "";
    this.model["error-blOceanVesselBound"] = "";
    this.model["error-blLoadPortCode"] = "";
    this.model["error-blPlaceOfDeliveryCode"] = "";
    this.model["error-blPlaceOfReceiptCode"] = "";
    this.model["error-blDischargePortCode"] = "";
    this.model["error-blReceiptTypeCode"] = "";
  }

  onRetrieve() {
    this.IsContOwn =
      this.cCOC +
      "-" +
      this.cSOC +
      "-" +
      this.cCOCTs +
      "-" +
      this.cSOCTs +
      "-" +
      this.cSlot;

    if (this.isSelection == "Vessel") {
      this.setValidatorVessel();
      this.isError = this.onValidate(this.model);
      console.log("VALIDASINYA -- > " + this.onValidate(this.model));
      if (!this.isError) {
        console.log("VALIDASINYA 2 -- > " + this.onValidate(this.model));
        this.grid.url =
          this.configService.config.BASE_API.toString() +
          "/BLReport/findBLByVesselNoPaging";
        this.grid.search =
          this.officeCode +
          "=" +
          this.model.blOceanVesselId +
          "=" +
          this.sCharUtil.htmlEncode(String(this.model.blOceanVesselVoyage).trim()) +
          "=" +
          this.model.blOceanVesselBound.toUpperCase() +
          "=" +
          this.isSort +
          "=" +
          this.IsContOwn +
          "=" +
          this.model.blDeliveryTypeCode +
          "=" +
          this.model.blReceiptTypeCode +
          "==";
        //this.grid.loadData();

        /*
        this.genericService.GET(this.configService.config.BASE_API.toString() + "/BLReport/findBLByVessel/"+ this.officeCode +"="+this.model.blOceanVesselId+"="+this.model.blOceanVesselVoyage+"="+this.model.blOceanVesselBound+"="+this.model.blDeliveryTypeCode+"="+this.model.blReceiptTypeCode+"=").subscribe((resp) =>{

          let dArr =[];
          dArr= resp.json()['content'];
          let lengthArray : Number=0;
          lengthArray = dArr.length;

          console.log("RESP NYA : "+resp.json().length+" >>> "+dArr.length);
          if(lengthArray > 0){
            this.grid.listStore.store = resp.json()['content'];
            this.grid.loadData();
          }else{
            this.grid.listStore.store=[];
            this.grid.loadData();
            this.message('information','Information','No record(s) found.','okonly',{ok: 'this.loading=false;'});
          }
        })
        */
      }
    } else if (this.isSelection == "LP") {
      this.setValidatorVessel();
      this.setValidatorLoadPort();

      this.isError = this.onValidate(this.model);
      if (!this.isError) {
        this.grid.url =
          this.configService.config.BASE_API.toString() +
          "/BLReport/findBLByVesselNoPaging";
        this.grid.search =
          this.officeCode +
          "=" +
          this.model.blOceanVesselId +
          "=" +
          this.sCharUtil.htmlEncode(String(this.model.blOceanVesselVoyage).trim()) +
          "=" +
          this.model.blOceanVesselBound.toUpperCase() +
          "=" +
          this.isSort +
          "=" +
          this.IsContOwn +
          "=" +
          this.model.blDeliveryTypeCode +
          "=" +
          this.model.blReceiptTypeCode +
          "=POL-" +
          this.model.blLoadPortCode +
          "=";
        //this.grid.loadData();
      }
    } else if (this.isSelection == "DP") {
      this.setValidatorVessel();
      this.setValidatorDischargePort();
      this.isError = this.onValidate(this.model);
      if (!this.isError) {
        this.grid.url =
          this.configService.config.BASE_API.toString() +
          "/BLReport/findBLByVesselNoPaging";
        this.grid.search =
          this.officeCode +
          "=" +
          this.model.blOceanVesselId +
          "=" +
          this.sCharUtil.htmlEncode(String(this.model.blOceanVesselVoyage).trim()) +
          "=" +
          this.model.blOceanVesselBound.toUpperCase() +
          "=" +
          this.isSort +
          "=" +
          this.IsContOwn +
          "=" +
          this.model.blDeliveryTypeCode +
          "=" +
          this.model.blReceiptTypeCode +
          "=POD-" +
          this.model.blDischargePortCode +
          "=";
        //this.grid.loadData();
      }
    } else if (this.isSelection == "POR") {
      this.setValidatorVessel();
      this.setValidatorPOR();
      this.isError = this.onValidate(this.model);
      if (!this.isError) {
        this.grid.url =
          this.configService.config.BASE_API.toString() +
          "/BLReport/findBLByVesselNoPaging";
        this.grid.search =
          this.officeCode +
          "=" +
          this.model.blOceanVesselId +
          "=" +
          this.sCharUtil.htmlEncode(String(this.model.blOceanVesselVoyage).trim()) +
          "=" +
          this.model.blOceanVesselBound.toUpperCase() +
          "=" +
          this.isSort +
          "=" +
          this.IsContOwn +
          "=" +
          this.model.blDeliveryTypeCode +
          "=" +
          this.model.blReceiptTypeCode +
          "=POR-" +
          this.model.blPlaceOfReceiptCode +
          "=";
        //this.grid.loadData();
      }
    } else if (this.isSelection == "POD") {
      this.setValidatorVessel();
      this.setValidatorPOD();
      this.isError = this.onValidate(this.model);
      if (!this.isError) {
        this.grid.url =
          this.configService.config.BASE_API.toString() +
          "/BLReport/findBLByVesselNoPaging";
        this.grid.search =
          this.officeCode +
          "=" +
          this.model.blOceanVesselId +
          "=" +
          this.sCharUtil.htmlEncode(String(this.model.blOceanVesselVoyage).trim()) +
          "=" +
          this.model.blOceanVesselBound.toUpperCase() +
          "=" +
          this.isSort +
          "=" +
          this.IsContOwn +
          "=" +
          this.model.blDeliveryTypeCode +
          "=" +
          this.model.blReceiptTypeCode +
          "=PD-" +
          this.model.blPlaceOfDeliveryCode +
          "=";
        //this.grid.loadData();
      }
    }

    // if (this.reprintMode) {
    //   this.grid.search = this.grid.search + "=RM";
    // } else {
    //   this.grid.search = this.grid.search + "=";
    // }
    this.grid.search = this.grid.search + "=";

    this.grid.loadData();
  }

  changeEventLoadPort(event) {
    console.log("event Load Port : " + event);

    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blLoadPortCode = "";
    } else {
      this.model.blLoadPortCode = event.locationCode;
    }
  }

  changeEventDischargePort(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blDischargePortCode = "";
    } else {
      this.model.blDischargePortCode = event.locationCode;
    }
  }

  rowEvent(act: string) {
    var tableStore = [];
    this.grid.url = "";
    if (act == "add") {
      console.log(this.grid.listStore.store);
      if (this.grid.listStore.store.length == 0) {
        this.grid.listStore.store.forEach((element) => {
          tableStore.push(element);
        });
        tableStore.push({
          id: (tableStore.length + 1).toString(),
          blNo: "",
          no: tableStore.length + 1,
          select: "",
        });
        console.log(tableStore);
        this.grid.listStore.store = [];
        tableStore.forEach((element) => {
          this.grid.listStore.store.push(element);
        });

        this.grid.loadData(
          0,
          $("#grid_dataTables_scrollBody").prop("scrollHeight")
        );
      } else {
        this.grid.listStore.store.forEach((element) => {
          tableStore.push(element);
        });
        console.log("#1");
        console.log(tableStore);
        //tableStore.push({id:(tableStore.length+1).toString(), blNo : '',no:(tableStore.length+1), select:'', columns: this.grid.getColumns()})
        tableStore.push({
          id: (tableStore.length + 1).toString(),
          blNo: "",
          no: tableStore.length + 1,
          select: "",
          locationName: "",
        });

        console.log("#2");
        console.log(tableStore);

        console.log("** this.grid.getColumns() **");
        //console.log(this.grid.getColumns());

        this.grid.listStore.store = [];
        tableStore.forEach((element) => {
          console.log("#3");
          console.log(element);

          var bld = new BLDraft();
          bld.blNo = element["blNo"];
          bld.id = element["id"];
          bld.no = element["no"];
          bld.select = element["select"];
          bld.locationName = element["locationName"];
          bld.columns = [];

          if (element["columns"] != undefined) {
            for (const key in element["columns"]) {
              var col: Kolom = element["columns"][key];

              var x = new Kolom();
              x["field"] = col.field;
              x["header"] = col.header;
              x["index"] = col["index"];
              x["width"] = col["width"];
              x["sort"] = col.sort;

              for (const key2 in col.ds) {
                var ds: SelectModel = col.ds[key2];
                var ds2 = new SelectModel();
                ds2.code = ds.code;
                ds2.name = ds.name;
                x.ds.push(ds2);
              }

              if (col["align"] != undefined) {
                x["align"] = col["align"];
              }

              if (col["valign"] != undefined) {
                x["valign"] = col["valign"];
              }

              if (col["hide"] != undefined) {
                x["hide"] = col["hide"];
              }

              if (col["type"] != undefined) {
                x["type"] = col["type"];
              }

              if (col["editType"] != undefined) {
                x["editType"] = col["editType"];
              }

              if (col["maxChars"] != undefined) {
                x["maxChars"] = col["maxChars"];
              }

              if (col["maxCharsCombo"] != undefined) {
                x["maxCharsCombo"] = col["maxCharsCombo"];
              }

              if (col["titleCombo"] != undefined) {
                x["titleCombo"] = col["titleCombo"];
              }

              if (col["codeCombo"] != undefined) {
                x["codeCombo"] = col["codeCombo"];
              }

              if (col["urlCombo"] != undefined) {
                x["urlCombo"] = col["urlCombo"];
              }

              if (col["columnsCombo"] != undefined) {
                x["columnsCombo"] = col["columnsCombo"];
              }

              if (col["calendarType"] != undefined) {
                x["calendarType"] = col["calendarType"];
              }

              if (col["calendarformat"] != undefined) {
                x["calendarformat"] = col["calendarformat"];
              }

              if (col["customTimeFormat"] != undefined) {
                x["customTimeFormat"] = col["customTimeFormat"];
              }

              if (col["labelButton"] != undefined) {
                x["labelButton"] = col["labelButton"];
              }

              if (col["urlCombo"] != undefined) {
                x["urlCombo"] = col["urlCombo"];
              }

              if (col["titleSelect"] != undefined) {
                x["titleSelect"] = col["titleSelect"];
              }

              if (col["codeSelect"] != undefined) {
                x["codeSelect"] = col["codeSelect"];
              }

              if (col["disableButton"] != undefined) {
                x["disableButton"] = col["disableButton"];
              }

              console.log(x);
              bld.columns.push(x);
            }
          }
          //console.log(bld)
          this.grid.listStore.store.push(bld);
        });
        console.log(this.grid.listStore.store);
        this.grid.loadData(
          0,
          $("#grid_dataTables_scrollBody").prop("scrollHeight")
        );
      }
    } else if (act == "del") {
      console.log("PANJANG GRIDNYA: " + this.grid.onSelect.length);

      console.log("masuk remove");
      var seqNo = 0;
      var countCheck = 0;
      var boxCheck: number;
      var storeTemp = new ListStore();

      this.grid.listStore.store.forEach((xy) => {
        console.log("xy " + xy);
        storeTemp.addData(xy);
      });

      storeTemp.store.forEach((el) => {
        if (el["select"] == "checked") {
          boxCheck = this.grid.listStore.store.indexOf(el);
          this.grid.listStore.store.splice(boxCheck, 1);

          $('input[name="gridCount"]').val(
            parseInt($('input[name="gridCount"]').val()) - 1
          );
        } else {
        }
      });

      this.grid.listStore.store.forEach((col) => {
        seqNo = seqNo + 1;
        col.seqNo = seqNo;
      });

      var yScroll = this.grid.getScrollY();

      this.grid.loadData(0, yScroll);
      this.grid.clearSelectedValues();
    }
  }

  gridEvent(event) {
    var flgTool = 0;
    var dblFlag = false;
    var soso = [];
    console.log("eventgrid" + event);
    switch (event.split(".")[0]) {
      case "afterLoad":

        break;
      case "selected":
        this.grid.getSelectedValues().forEach((element) => {
          if (element.select == "checked") {
            flgTool = 1;
          }
        });
        if (flgTool > 0) this.disableToolbarButtons = "";

        else break;
      case "click":
        this.grid.listStore.store.forEach((yr) => {
          console.log("KLIK GRID " + yr);
          if (yr.select == "checked") {
            flgTool = 1;
          }
        });

        if (flgTool > 0) console.log(flgTool);

        else break;

      case "afterLoad":
        this.lengthArray = this.grid.listStore.store.length;

        break;
      default:
          let strEvent: string = event;
          if (strEvent != "") {
            let arr: string[] = strEvent.split(";");
            switch (arr[0].split(".")[0]) {
              case "AfterEdit":
                if (strEvent.split(";")[1] == "blNo") {
                  this.getBLStatus2(
                    strEvent.split(";")[2],
                    Number(strEvent.split(";")[0].split(".")[1])
                  );
                }
  
                this.grid.listStore.store.forEach((yr) => {
                  console.log(yr);
  
                  if (yr.select == "checked") {
                    flgTool = 1;
                    this.model.blNo = yr["blNo"].trim().toUpperCase();
                    this.model.blOfficeCode = this.officeCode;
                  } else {
                    this.model.blNo = "";
                    this.model.blOfficeCode = "";
                  }
                  soso.push(yr);
                });
  
                if (flgTool > 0)
                  //this.disableToolbarButtons = "retrieve";
                  console.log(flgTool);
                else this.disableToolbarButtons = "unlock";
                dblFlag = this.checkDuplicateInObject("blNo", soso);
                if (dblFlag == true) {
                  this.message(
                    "information",
                    "Information",
                    "Duplicate BL Reference(s) detected.",
                    "okonly",
                    { ok: "this.loading=false;" }
                  );
                  this.grid.listStore.store.splice(
                    parseInt(arr[0].split(".")[1]) - 1,
                    1
                  );
                  this.grid.loadData();
                }
  
                break;
              case "dblClick":
                // this.modeForm == 'sailingScheduleMaintenance';
                //   this.handleDoubleClickedSailingSchedule();
                break;
              default:
                break;
            }
          }
  
          break;
    }
  }

  infoGrid(event) { }

  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  setValidatorVessel() {
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
    };
  }

  setValidatorLoadPort() {
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
      blLoadPortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Load Port.",
          },
        ],
      },
    };
  }

  setValidatorDischargePort() {
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
      blDischargePortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Discharge Port.",
          },
        ],
      },
    };
  }

  setValidatorPOR() {
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
      blPlaceOfReceiptCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Place Of Receipt Code.",
          },
        ],
      },
    };
  }

  setValidatorPOD() {
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
      blPlaceOfDeliveryCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Place of Delivery Code.",
          },
        ],
      },
    };
  }

  checkDuplicateInObject(propertyName, inputArray) {
    var seenDuplicate = false,
      testObject = {};

    inputArray.map(function (item) {
      var itemPropertyName = item[propertyName].trim().toUpperCase();

      if (item[propertyName] != "") {
        if (itemPropertyName in testObject) {
          testObject[itemPropertyName].duplicate = true;
          item.duplicate = true;
          seenDuplicate = true;
        } else {
          testObject[itemPropertyName] = item;
          delete item.duplicate;
        }
      }
    });

    return seenDuplicate;
  }

  handlePrint() {
    const data = [];
    this.grid.listStore.store.forEach(ele => {
      console.log(data);
      if (ele.select == "checked") {
        data.push(ele.blNo.toUpperCase());
      }
    });
    if (data.length == 0) {
      if (this.isSelection == "BL") {
        this.message(
          "information",
          "Information",
          "Please insert bl to print",
          "okonly",
          { ok: "" }
        );
      } else {
        this.message(
          "information",
          "Information",
          "Please retrieve the data",
          "okonly",
          { ok: "" }
        );
      }
      return;
    }
    this.genericUtil.showLoader();

    const url = this.configService.config.BASE_API.toString() +
      "/?q=/Mreport/runReport/71/Vgm-Export-Reports";
    this._http.post(url,data, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': localStorage.getItem('token') }), responseType: 'blob' }).subscribe(
      (resp) => {
        this.genericUtil.hideLoader();
          const blob = new Blob([resp], { type: 'text/csv' });
          var filename = 'VGM_Report';
          FileSaver.saveAs(blob, filename);
      },
      (error) => {
        this.genericUtil.hideLoader();
      }
    )
  }
  
  changeEventPOR(event){
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blPlaceOfReceiptCode = "";
    } else {
      this.model.blPlaceOfReceiptCode = event.locationCode;
    }
  }
  
  changeEventPOD(event){
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blPlaceOfDeliveryCode = "";
    } else {
      this.model.blPlaceOfDeliveryCode = event.locationCode;
    }
  }

  getBLStatus2(blNo: String, idx: number) {
    var BLPrint: String;
    var hasAttch: boolean;
    var boxCheck: number;
    var xx: any[];
    var blPOL: String;
    // var BLFinish: String;

    if (blNo.trim() == "" || blNo.trim() == undefined) {
      blNo = "blNo";
    }

    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/BLReport/findBLNo/" +
          this.officeCode +
          "/" +
          blNo.toUpperCase()
      )
      .subscribe((resp) => {
        console.log("Masuk getBLStatus");
        if (resp.ok) {
          if (Object.keys(resp.json()["content"]).length > 0) {
            BLPrint = resp.json()["content"][0].blIsBlPrinted;
            hasAttch = resp.json()["content"][0].hasAttachment;
            blPOL = resp.json()["content"][0].blPOL;

            console.log("Status BL --> " + resp.json()["content"][0].blStatus);
            switch (resp.json()["content"][0].blStatus) {
              case "D":
                console.log("bl delete");
                this.grid.listStore.store.forEach((data) => {
                  if (data.blNo.toUpperCase() == blNo.toUpperCase()) {
                    this.message(
                      "information",
                      "Information",
                      "This BL has been deleted.",
                      "okonly",
                      { ok: "this.loading=false;" }
                    );

                    boxCheck = this.grid.listStore.store.indexOf(data);
                    console.log("BOXCEK --> " + boxCheck);
                    this.grid.listStore.store.splice(boxCheck, 1);
                    this.grid.loadData();
                  }
                });
                break;
              default:

                var xx: any[] = [];
                let pop: any[] = resp.json()["content"][0]["pop"];
                if (pop != undefined) {
                  pop.forEach((dt) => {
                    this.blFcPop = dt.blFcPop;
                    this.locationName = dt.locationName;
                    var md = new SelectModel();
                    md["code"] = this.blFcPop;
                    md["name"] = this.locationName;
                    xx.push(md);
                  });
                } else {
                  var md = new SelectModel();
                  md["code"] = "";
                  md["name"] = "";
                  xx.push(md);
                }

                this.grid.setDsCombo(xx, idx, "locationName");

                // /*
                // BLFinish = resp.json()["content"][0].blIsFinished;
                // this.grid.listStore.store.forEach((data) => {
                //   if (BLFinish == "N") {
                //     this.message(
                //       "information",
                //       "Information",
                //       "BL has not been finished.",
                //       "okonly",
                //       { ok: "this.loading=false;" }
                //     );
                //     boxCheck = this.grid.listStore.store.indexOf(data);
                //     console.log("BOXCEK --> " + idx);
                //     this.grid.listStore.store.splice(idx - 1, 1);
                //     this.grid.loadData();
                //   }
                // }); 
                // */

                console.log(this.grid.listStore.store);
                break;
            }
          } else {
            if (this.grid.listStore.store.length > 0) {
              //console.log("satu: "+Object.keys(resp.json()['content']).length );

              this.grid.listStore.store.forEach((data) => {
                if (data.blNo.toUpperCase() == blNo.toUpperCase()) {
                  this.message(
                    "information",
                    "Information",
                    "BL Not Found.",
                    "okonly",
                    { ok: "this.loading=false;" }
                  );

                  boxCheck = this.grid.listStore.store.indexOf(data);
                  //xx=data.blFcPop,data.locationName;
                  console.log("BOXCEK --> " + boxCheck);
                  this.grid.listStore.store.splice(boxCheck, 1);

                  this.grid.loadData();
                }
              });

              /*if(Object.keys(resp.json()['content']).length == 0){
              this.message('information','Information','BL Not Found.','okonly',{ok: 'this.loading=false;'});
              this.grid.onClear();
              this.grid.loadData();
            }*/
            }
          }
        }
      });
  }
}
