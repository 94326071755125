<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin
      #toolbarSetting
      [modeToolbar]="modeToolbar"
      [settings]="settingToolbar"
      [disableButtons]="disableToolbarButtons"
      [invisibleButtons]="invisibleToolbarButtons"
      (eventEmitterClick)="toolbarEvent($event)"
    ></toolbar-plugin>

    <div [style.display]="modeForm ? 'none' : 'block'">
      <!-- Begin of Grid -->
      <div class="ui segment">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 99"
        >
          <div class="ui medium text loader">Loading</div>
        </div>
        <h4
          class="ui dividing header"
          style="text-align: center; margin-top: 10px"
        >
          Master Container ISO Parameter
        </h4>
        <grid-plugin
          #grid
          [settings]="settingGrid"
          (gridEvent)="gridEvent($event)"
          (infoGrid)="infoGrid($event)"
        ></grid-plugin>
      </div>
      <!-- End of Grid -->
    </div>
    <div [style.display]="modeForm ? 'block' : 'none'">
      <div class="ui segment" style="padding-left: 1%; padding-right: 20%">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 1"
        >
          <div class="ui medium text loader">Loading</div>
        </div>

        <div id="container">
          <dialog-plugin
            [settings]="dialog"
            (eventDialog)="eventMessage($event)"
          ></dialog-plugin>

          <!-- Begin of Form -->
          <form class="ui form" [style.display]="form == '' ? 'block' : 'none'">
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              Master Container ISO Mapping
            </h4>

            <div class="field">
              <div
                class="ui test toggle checkbox"
                [class.disabled]="lock == true"
              >
                <input
                  type="checkbox"
                  [(ngModel)]="model['isValid']"
                  name="isValid"
                  tabindex="0"
                  class="hidden"
                />
                <label>Valid?</label>
              </div>
            </div>

            <div
              class="ui styled accordion field"
              style="width: 100%; height: 50%"
            >
              <div class="active title">
                <i class="icon dropdown"></i>
                General
              </div>
              <div class="active content">
                <div class="four fields">
                  <div
                    class="required field"
                    [class.error]="
                      model['error-containerIsoMappingCode'] != null &&
                      model['error-containerIsoMappingCode'] != ''
                    "
                  >
                    <label>Container ISO Code</label>
                    <input
                      type="text"
                      [(ngModel)]="model['containerIsoMappingCode']"
                      (ngModelChange)="
                        model['error-containerIsoMappingCode'] = ''
                      "
                      name="containerIsoMappingCode"
                      placeholder="Container ISO Code"
                      [disabled]="lock == true"
                      maxlength="4"
                      (keypress)="formValidation.ModifiedPattern($event, ' ')"
                    />
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-containerIsoMappingCode'] != null &&
                        model['error-containerIsoMappingCode'] != ''
                      "
                    >
                      {{ model["error-containerIsoMappingCode"] }}
                    </div>
                  </div>
                </div>

                <div class="four fields">
                  <div
                    class="required field"
                    [class.error]="
                      model['error-containerIsoMappingType'] != null &&
                      model['error-containerIsoMappingType'] != ''
                    "
                  >
                    <label>Container Type</label>
                    <combo-plugin
                      #cbContainerIsoMappingType
                      [settings]="settingContainerType"
                      [disableCombo]="lock"
                      (change)="valueType($event)"
                      style="width: 100%"
                    ></combo-plugin>
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-containerIsoMappingType'] != null &&
                        model['error-containerIsoMappingType'] != ''
                      "
                    >
                      {{ model["error-containerIsoMappingType"] }}
                    </div>
                  </div>

                  <div
                    class="required field"
                    [class.error]="
                      model['error-containerIsoMappingSize'] != null &&
                      model['error-containerIsoMappingSize'] != ''
                    "
                  >
                    <label>Container Size</label>
                    <combo-plugin
                      #cbContainerIsoMappingSize
                      [settings]="settingContainerSize"
                      [disableCombo]="lock"
                      (change)="valueSize($event)"
                      style="width: 100%"
                    ></combo-plugin>
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-containerIsoMappingSize'] != null &&
                        model['error-containerIsoMappingSize'] != ''
                      "
                    >
                      {{ model["error-containerIsoMappingSize"] }}
                    </div>
                  </div>
                </div>

                <div class="four fields">
                  <div
                    class="required field"
                    [class.error]="
                      model['error-containerIsoMappingHeight'] != null &&
                      model['error-containerIsoMappingHeight'] != ''
                    "
                  >
                    <label>Container Height</label>
                    <combo-plugin
                      #cbContainerIsoMappingHeight
                      [settings]="settingContainerHeight"
                      [disableCombo]="lock"
                      (change)="valueHeight($event)"
                      style="width: 100%"
                    ></combo-plugin>
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-containerIsoMappingHeight'] != null &&
                        model['error-containerIsoMappingHeight'] != ''
                      "
                    >
                      {{ model["error-containerIsoMappingHeight"] }}
                    </div>
                  </div>

                  <div
                    class="required field"
                    [class.error]="
                      model['error-containerIsoMappingQuality'] != null &&
                      model['error-containerIsoMappingQuality'] != ''
                    "
                  >
                    <label>Container Quality</label>
                    <combo-plugin
                      #cbContainerIsoMappingQuality
                      [settings]="settingContainerQuality"
                      [disableCombo]="lock"
                      (change)="valueQuality($event)"
                      style="width: 100%"
                    ></combo-plugin>
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-containerIsoMappingQuality'] != null &&
                        model['error-containerIsoMappingQuality'] != ''
                      "
                    >
                      {{ model["error-containerIsoMappingQuality"] }}
                    </div>
                  </div>
                </div>

                <div class="four fields">
                  <div
                    class="required field"
                    [class.error]="
                      model['error-containerIsoMappingWeight'] != null &&
                      model['error-containerIsoMappingWeight'] != ''
                    "
                  >
                    <label>Container Weight</label>
                    <input
                      type="number"
                      [(ngModel)]="model['containerIsoMappingWeight']"
                      (ngModelChange)="
                        model['error-containerIsoMappingWeight'] = ''
                      "
                      name="containerIsoMappingweight"
                      placeholder="Container Weight"
                      [disabled]="lock == true"
                      maxlength="10"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    />
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-containerIsoMappingWeight'] != null &&
                        model['error-containerIsoMappingWeight'] != ''
                      "
                    >
                      {{ model["error-containerIsoMappingWeight"] }}
                    </div>
                  </div>

                  <div class="field">
                    <div
                      class="ui test toggle checkbox"
                      [class.disabled]="lock == true"
                    >
                      <input
                        type="checkbox"
                        [(ngModel)]="
                          model['containerIsoMappingReeferIndicator']
                        "
                        name="containerIsoMappingReeferIndicator"
                        tabindex="0"
                        class="hidden"
                      />
                      <label>Reefer Indicator?</label>
                    </div>
                  </div>
                </div>

                <div class="two fields">
                  <div class="field">
                    <label>Remarks</label>
                    <textarea
                      [(ngModel)]="model['remarks']"
                      name="remarks"
                      placeholder="Input your remarks..."
                      [disabled]="lock == true"
                      maxlength="200"
                      (keypress)="formValidation.AllChar($event)"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="ui raised segment">
              <div class="three fields">
                <div class="field">
                  <label>Created By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userCreated']"
                    name="userCreated"
                    placeholder="Created By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="two field">
                  <label>Created Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateCreated']"
                    name="dateCreated"
                    placeholder="Created Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="three fields">
                <div class="field">
                  <label>Last Modified By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userModified']"
                    name="userModified"
                    placeholder="Last Modified By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Last Modified Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateModified']"
                    name="dateModified"
                    placeholder="Last Modified Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="three fields">
                <div class="field">
                  <label>Invalid By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userInvalid']"
                    name="userInvalid"
                    placeholder="Invalid By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Invalid Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateInvalid']"
                    name="dateInvalid"
                    placeholder="Invalid Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
            </div>
          </form>
          <!-- End of Form -->
          <!-- Begin of History Form -->
          <form
            class="ui form"
            [style.display]="form == 'history' ? 'block' : 'none'"
          >
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              History Master Container ISO Parameter
            </h4>
            <div class="ui raised segment">
              <a class="ui red ribbon label">Select Container ISO Parameter</a>

              <div class="active content">
                <div class="one fields" style="margin-top: 10px">
                  <div class="field">
                    <button class="ui button" (click)="retrieveHistory($event)">
                      Retrieve
                    </button>
                    <button
                      class="ui button"
                      (click)="generateContainerIsoMappingReport($event)"
                    >
                      Export
                    </button>
                  </div>
                </div>

                <div class="two fields">
                  <div
                    class="required field"
                    [class.error]="
                      model['error-isoCode'] != null &&
                      model['error-isoCode'] != ''
                    "
                  >
                    <label>Container ISO Code</label>
                    <combo-plugin
                      #cbContainerIsoMappingCodeHistory
                      [settings]="settingContainerIsoMappingHistory"
                      (change)="valueContainerIsoMappingHistory($event)"
                    ></combo-plugin>
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-isoCode'] != null &&
                        model['error-isoCode'] != ''
                      "
                    >
                      {{ model["error-isoCode"] }}
                    </div>
                  </div>
                </div>

                <div class="three fields">
                  <div class="field">
                    <label>Container Type</label>
                    <input
                      type="text"
                      [value]="valContainerType"
                      name="containerIsoMappingHistoryContainerType"
                      placeholder="Container Type"
                      [disabled]="lock == true"
                      maxlength="3"
                      readonly
                      style="background-color: lightgray"
                    />
                  </div>
                  <div class="field">
                    <label>Container Size</label>
                    <input
                      type="text"
                      [value]="valContainerSize"
                      name="containerIsoMappingHistoryContainerSize"
                      placeholder="Container Size"
                      [disabled]="lock == true"
                      maxlength="3"
                      readonly
                      style="background-color: lightgray"
                    />
                  </div>
                  <div class="field">
                    <label>Container Height</label>
                    <input
                      type="text"
                      [value]="valContainerHeight"
                      name="containerIsoMappingHistoryContainerHeight"
                      placeholder="Container Height"
                      [disabled]="lock == true"
                      maxlength="3"
                      readonly
                      style="background-color: lightgray"
                    />
                  </div>
                </div>

                <!--<div class="two fields">
                  <div class="field">
                    <label>Log Start Date</label>
                    <calendar-plugin id="logHistoryContainerIsoMappingStartDate"
                                     [pluginType]="typeContainerIsoMappingLogStartDate"
                                     [settings]="settingContainerIsoMappingLogStartDate"></calendar-plugin>
                  </div>
                  <div class="field">
                    <label>Log End Date</label>
                    <calendar-plugin id="logHistoryContainerIsoMappingEndDate"
                                     [pluginType]="typeContainerIsoMappingLogEndDate"
                                     [settings]="settingContainerIsoMappingLogEndDate"></calendar-plugin>
                  </div>
                </div>-->
                <div class="field">
                  <grid-plugin
                    #gridHistory
                    [settings]="settingGridHistory"
                    (gridEvent)="gridEventHistory($event)"
                    (infoGrid)="infoGridHistory($event)"
                  ></grid-plugin>
                </div>
              </div>
            </div>
          </form>
          <!-- End of History Form -->
        </div>
      </div>
    </div>
  </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
