<div id="dialogvesselform" class="ui small first coupled modal">
  <div class="header"><i class="warning sign big icon"></i> &nbsp; Warning</div>
  <div class="content">
    <p>Please select (1) historical data before exporting.</p>
  </div>
  <div class="actions">
    <div class="ui positive right labeled icon button" (click)="onOk()">
      OK
      <i class="checkmark icon"></i>
    </div>
  </div>
</div>

<div class="ui segment" style="padding-left: 1%; padding-right: 20%">
  <div
    class="ui inverted dimmer"
    [class.active]="loading == true"
    style="z-index: 1"
  >
    <div class="ui medium text loader">Loading</div>
  </div>

  <div id="container">
    <!-- Begin of Form -->
    <form class="ui form" [style.display]="form == '' ? 'block' : 'none'">
      <h4 class="ui dividing header" style="text-align: center">
        Master Vessel Code
      </h4>

      <div class="three fields">
        
        <div class="field">
          <label><br /></label>
          <div class="ui test toggle checkbox" [class.disabled]="true">
            <input
              type="checkbox"
              [(ngModel)]="model['isValid']"
              name="isValid"
              tabindex="0"
              class="hidden"
            />
            <label>Valid</label>
          </div>
        </div>
        <div class="field">

        </div>
        <div class="field">
          <label>IMO</label>
          <input
              type="text"
              [(ngModel)]="model['imoNo']"
              name="imo"
              placeholder="IMO"
              disabled
              readonly
            />
        </div>
      </div>
      <div class="ui styled accordion field" style="width: 100%">
        <div class="active title">
          <i class="icon dropdown"></i>
          Vessel
        </div>
        <div class="active content">
          
          <div class="two fields">
            <div
              class="required field"
              [class.error]="
                model['error-vesselName'] != null &&
                model['error-vesselName'] != ''
              "
            >
              <label>Vessel Name</label>
              <input
                type="text"
                [(ngModel)]="model['vesselName']"
                (ngModelChange)="
                  checkIfError(
                    'vesselName',
                    formValidation.regVesVoy
                  )
                "
                name="vesselName"
                placeholder="Vessel Name"
                [disabled]="lock == true"
                (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
              />
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-vesselName'] != null &&
                  model['error-vesselName'] != ''
                "
              >
                {{ model["error-vesselName"] }}
              </div>
            </div>
            
            <div
              [style.display]="modeType ? 'block' : 'none'"
              class="field"
              [class.error]="
                model['error-vesselCode'] != null &&
                model['error-vesselCode'] != ''
              "
              [class.tooltip]="
                model['vesselCode'] != '' && model['vesselCode'] != 'null'
              "
            >
              <label>Vessel Abbr. Code</label>
              <input
                type="text"
                [(ngModel)]="model['vesselCode']"
                (ngModelChange)="
                  checkIfError(
                    'vesselCode',
                    formValidation.regAlphaNumericWSpace
                  )
                "
                name="vesselCode"
                placeholder="Vessel Abbr. Code"
                [disabled]="lock == true"
                maxlength="6"
                (keypress)="formValidation.ModifiedPattern($event, ' ')"
              />
              <span class="tooltiptext">{{ model["vesselCode"] }}</span>
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-vesselCode'] != null &&
                  model['error-vesselCode'] != ''
                "
              >
                {{ model["error-vesselCode"] }}
              </div>
            </div>
            <div
              [style.display]="modeType ? 'none' : 'block'"
              class="required field"
              [class.error]="
                model['error-vesselCode'] != null &&
                model['error-vesselCode'] != ''
              "
              [class.tooltip]="
                model['vesselCode'] != 'null' && model['vesselCode'] != ''
              "
            >
              <label>Vessel Abbr. Code</label>
              <input
                type="text"
                [(ngModel)]="model['vesselCode']"
                name="vesselCode"
                placeholder="Vessel Abbr. Code"
                [disabled]="lock == true"
                maxlength="6"
                (keypress)="formValidation.ModifiedPattern($event, ' ')"
              />
              <span class="tooltiptext">{{ model["vesselCode"] }}</span>
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-vesselCode'] != null &&
                  model['error-vesselCode'] != ''
                "
              >
                {{ model["error-vesselCode"] }}
              </div>
            </div>

            <div
              [style.display]="modeType ? 'block' : 'none'"
              class="field"
              [class.error]="
                model['error-docsysCode'] != null &&
                model['error-docsysCode'] != ''
              "
            >
              <label>Vessel Code (A/C)</label>
              <input
                type="text"
                [(ngModel)]="model['docsysCode']"
                (ngModelChange)="model['error-docsysCode'] = ''"
                name="docsysCode"
                placeholder="Vessel Code (A/C)"
                [disabled]="lock == true"
                maxlength="6"
                (keypress)="formValidation.ModifiedPattern($event, ' ')"
              />
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-docsysCode'] != null &&
                  model['error-docsysCode'] != ''
                "
              >
                {{ model["error-docsysCode"] }}
              </div>
            </div>
            <div
              [style.display]="modeType ? 'none' : 'block'"
              class="required field"
              [class.error]="
                model['error-docsysCode'] != null &&
                model['error-docsysCode'] != ''
              "
            >
              <label>Vessel Code (A/C)</label>
              <input
                type="text"
                [(ngModel)]="model['docsysCode']"
                (ngModelChange)="model['error-docsysCode'] = ''"
                name="docsysCode"
                placeholder="Vessel Code (A/C)"
                [disabled]="lock == true"
                maxlength="6"
                (keypress)="formValidation.ModifiedPattern($event, ' ')"
              />
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-docsysCode'] != null &&
                  model['error-docsysCode'] != ''
                "
              >
                {{ model["error-docsysCode"] }}
              </div>
            </div>

          </div>
          
        </div>
      </div>

      <div class="ui raised segment">
        <div class="two fields">
          <div class="field">
            <label>Created By</label>
            <input
              type="text"
              [(ngModel)]="model['userCreated']"
              name="userCreated"
              placeholder="Created By"
              disabled
              readonly
              style="background-color: lightgray"
            />
          </div>
          <div class="field">
            <label>Created Date</label>
            <input
              type="text"
              [(ngModel)]="model['dateCreated']"
              name="dateCreated"
              placeholder="Created Date"
              disabled
              readonly
              style="background-color: lightgray"
            />
          </div>
        </div>
        <div class="two fields">
          <div class="field">
            <label>Last Modified By</label>
            <input
              type="text"
              [(ngModel)]="model['userModified']"
              name="userModified"
              placeholder="Last Modified By"
              disabled
              readonly
              style="background-color: lightgray"
            />
          </div>
          <div class="field">
            <label>Last Modified Date</label>
            <input
              type="text"
              [(ngModel)]="model['dateModified']"
              name="dateModified"
              placeholder="Last Modified Date"
              disabled
              readonly
              style="background-color: lightgray"
            />
          </div>
        </div>
        <div class="two fields">
          <div class="field">
            <label>Invalid By</label>
            <input
              type="text"
              [(ngModel)]="model['userInvalid']"
              name="userInvalid"
              placeholder="Invalid By"
              disabled
              readonly
              style="background-color: lightgray"
            />
          </div>
          <div class="field">
            <label>Invalid Date</label>
            <input
              type="text"
              [(ngModel)]="model['dateInvalid']"
              name="dateInvalid"
              placeholder="Invalid Date"
              disabled
              readonly
              style="background-color: lightgray"
            />
          </div>
        </div>
      </div>
    </form>
    <!-- End of Form -->
    <!-- Begin of History Form -->
    <form
      class="ui form"
      [style.display]="form == 'history' ? 'block' : 'none'"
    >
      <h4 class="ui dividing header" style="text-align: center">
        History Master Vessel Code
      </h4>

      <div class="ui raised segment">
        <a class="ui red ribbon label">Select Vessel</a>

        <div class="one fields" style="margin-top: 10px">
          <div class="field">
            <button class="ui button" (click)="retrieveHistory($event)">
              Retrieve
            </button>
            <button
              class="ui button"
              (click)="generateVesselHistoryReport($event)"
            >
              Export
            </button>
          </div>
        </div>

        <div class="two fields">
          <div class="field">
            <label>Vessel Name</label>
            <combo-plugin
              #cbVesselHistory
              [settings]="settingVesselHistory"
              (change)="changeEventVesselHistory($event)"
            ></combo-plugin>
          </div>
          <div class="field">
            <label>Vessel Abbr. Code</label>
            <input
              type="text"
              [(ngModel)]="vesselCodeHistory"
              name="vesselCodeHistory"
              placeholder="Vessel History Code"
              disabled
              readonly
              style="background-color: lightgray"
            />
          </div>
        </div>

        <div class="field">
          <grid-plugin
            #gridHistory
            [settings]="settingGridHistory"
            (gridEvent)="gridEventHistory($event)"
            (infoGrid)="infoGridHistory($event)"
          ></grid-plugin>
        </div>
      </div>
    </form>
    <!-- End of History Form -->
  </div>
</div>
