export class Depo  {
  no : number = 0;
  depoId    : string = '';
  depoCode  : string = '';
  depoName  : string = '';
  depoAddress     : string = '';
  depoPic: string= '';
  depoTelp: string= '';
  remarks:string='';
  isValid      : boolean = true;
  dateCreated  : string ='';
  userCreated  : string ='';
  dateModified : string ='';
  userModified : string ='';
  dateInvalid  : string ='';
  userInvalid  : string ='';
  officeCode : string = '';
  updateMode:number=0;
  oldValid : boolean = true;
  oldCode : string = '';

  constructor () { }
}
