<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

    <div [style.display]="modeForm?'none':'block'">
      <!-- Begin of Grid -->
      <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Master Receipt And Delivery Types</h4>
      <grid-plugin #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
      <!-- End of Grid -->
    </div>

    <div [style.display]="modeForm?'block':'none'">
      <div class="ui segment" style="padding-left:1%;padding-right:20%;">
        <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1">
          <div class="ui medium text loader">Loading</div>
        </div>

        <div id='container'>
          <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

          <!-- Begin of Form -->
          <form class="ui form" [style.display]="form == ''?'block':'none'" >
            <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Master Receipt And Delivery Types</h4>

            <div class="field">
              <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                <input type="checkbox" [(ngModel)]="model['isValid']" name="isValid" tabindex="0" class="hidden">
                <label>Valid?</label>
              </div>
            </div>

            <div class="ui styled accordion field" style="width:100%;height: 50%;">
              <div class="active title">
                <i class="icon dropdown"></i>
                General
              </div>
              <div class="active content">

                <div class="two fields">
                  <div class="required field" [class.error]="model['error-receiptAndDeliveryTypeName'] != null && model['error-receiptAndDeliveryTypeName'] != ''">
                    <label>Receipt and Delivery Type Name</label>
                    <input type="text" [(ngModel)]="model['receiptAndDeliveryTypeName']" name="receiptAndDeliveryTypeName"  placeholder="Receipt And Delivery Type Name" [disabled]="lock == true" maxlength="35">
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="model['error-receiptAndDeliveryTypeName'] != null && model['error-receiptAndDeliveryTypeName'] != ''">
                      {{model['error-receiptAndDeliveryTypeName']}}
                    </div>
                  </div>

                  <div class="required field" [class.error]="model['error-receiptAndDeliveryTypeCode'] != null && model['error-receiptAndDeliveryTypeCode'] != ''">
                    <label>Receipt and Delivery Type Code</label>
                    <input type="text" [(ngModel)]="model['receiptAndDeliveryTypeCode']" name="receiptAndDeliveryTypeCode"  placeholder="Receipt And Delivery Type Code" [disabled]="lock == true" maxlength="5">
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="model['error-receiptAndDeliveryTypeCode'] != null && model['error-receiptAndDeliveryTypeCode'] != ''">
                      {{model['error-receiptAndDeliveryTypeCode']}}
                    </div>
                  </div>
                </div>

                <div class="field">
                  <label>Remarks</label>
                  <textarea [(ngModel)]="model['remarks']" name="remarks" placeholder="Input your remarks..." [disabled]="lock == true" style="text-transform: uppercase"></textarea>
                </div>

              </div>
            </div>

            <div class="ui raised segment">
              <div class="two fields">
                <div class="field">
                  <label>Created By</label>
                  <input type="text"  [(ngModel)]="model['userCreated']" name="userCreated"  placeholder="Created By" readonly style="background-color: lightgray">
                </div>
                <div class="field">
                  <label>Created Date</label>
                  <input type="text"  [(ngModel)]="model['dateCreated']" name="dateCreated"  placeholder="Created Date" readonly style="background-color: lightgray">
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Last Modified By</label>
                  <input type="text"  [(ngModel)]="model['userModified']" name="userModified"  placeholder="Last Modified By" readonly style="background-color: lightgray">
                </div>
                <div class="field">
                  <label>Last Modified Date</label>
                  <input type="text"  [(ngModel)]="model['dateModified']" name="dateModified"  placeholder="Last Modified Date" readonly style="background-color: lightgray">
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Invalid By</label>
                  <input type="text"  [(ngModel)]="model['userInvalid']" name="userInvalid"  placeholder="Invalid By" readonly style="background-color: lightgray">
                </div>
                <div class="field">
                  <label>Invalid Date</label>
                  <input type="text"  [(ngModel)]="model['dateInvalid']" name="dateInvalid"  placeholder="Invalid Date" readonly style="background-color: lightgray">
                </div>
              </div>
            </div>

          </form>
          <!-- End of Form -->
          <!-- Begin of History Form -->
          <form class="ui form" [style.display]="form == 'history'?'block':'none'" >
            <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">History Master Receipt And Delivery Types</h4>

            <div class="ui raised segment">
              <a class="ui red ribbon label">Select ReceiptAndDeliveryTypes</a>

              <div class="active content">
                <div class="one field" style="margin-top:10px;">
                  <div class="field">
                    <button class="ui button" (click)="retrieveHistory($event)">Retrieve</button>
                    <button class="ui button" (click)="generateReceiptAndDeliveryTypeHistoryReport($event)">Export</button>
                  </div>
                </div>

                <div class="two fields">
                  <div class="required field" [class.error]="model['error-receiptAndDeliveryTypeName'] != null && model['error-receiptAndDeliveryTypeName'] != ''">
                    <label>Receipt and Delivery Type Name</label>
                    <combo-plugin #cbReceiptAndDeliveryTypeNameHistory [settings]="settingReceiptAndDeliveryTypeHistory" (change)="valueReceiptAndDeliveryTypeHistory($event)" ></combo-plugin>
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="model['error-receiptAndDeliveryTypeName'] != null && model['error-receiptAndDeliveryTypeName'] != ''">
                      {{model['error-receiptAndDeliveryTypeName']}}
                    </div>
                  </div>

                  <div class="required field" [class.error]="model['error-receiptAndDeliveryTypeCode'] != null && model['error-receiptAndDeliveryTypeCode'] != ''">
                    <label>Receipt and Delivery Type Code</label>
                    <input type="text" [value]="valReceiptAndDeliveryTypeCode" name="receiptAndDeliveryTypeHistoryCode"  placeholder="ReceiptAndDeliveryType Code" [disabled]="lock == true" maxlength="5" readonly style="background-color: lightgray">
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="model['error-receiptAndDeliveryTypeCode'] != null && model['error-receiptAndDeliveryTypeCode'] != ''">
                      {{model['error-receiptAndDeliveryTypeCode']}}
                    </div>
                  </div>
                </div>
                <!--
                                            <div class="two fields">
                                              <div class="field">
                                                <label>Log Start Date</label>
                                                <calendar-plugin id="logHistoryReceiptAndDeliveryTypeStartDate" [pluginType]="typeReceiptAndDeliveryTypeLogStartDate" [settings]="settingReceiptAndDeliveryTypeLogStartDate"></calendar-plugin>
                                              </div>
                                              <div class="field">
                                                <label>Log End Date</label>
                                                <calendar-plugin id="logHistoryReceiptAndDeliveryTypeEndDate" [pluginType]="typeReceiptAndDeliveryTypeLogEndDate" [settings]="settingReceiptAndDeliveryTypeLogEndDate"></calendar-plugin>
                                              </div>
                                            </div>
                -->
                <div class="field">
                  <grid-plugin #gridHistory [settings]="settingGridHistory" (gridEvent)="gridEventHistory($event)" (infoGrid)="infoGridHistory($event)"></grid-plugin>
                </div>
              </div>
            </div>
          </form>
          <!-- End of History Form -->
        </div>
      </div>
    </div>

  </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
