<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <form class="ui form">
    <fieldset style="border: 1px solid lightgray;">

    <div class="two fields">
      <div class="inline field" style="width:100%" [class.error]="model['error-blNo'] != null && model['error-blNo'] != ''" >
        <label>B/L No.</label>
        <input type="text" name="BlNo"  placeholder="B/L" [(ngModel)]="model['blNo']" (ngModelChange)="model['error-blNo']=''" >
        <div class = "ui basic red pointing prompt label transition error-message-hidden"
             [class.error-message-visible]="model['error-blNo'] != null && model['error-blNo'] != ''">
          {{model['error-blNo']}}
        </div>
      </div>
          </div>
    </fieldset>
    <br>
    <fieldset style="border: 1px solid lightgray;">
      <legend style="font-weight:bold;color:dodgerblue;">Generate B/L (Same Booking Number)</legend>
      <br>
      <div class="two fields">
        <div class = "field">
          <fieldset style="border: 1px solid lightgray;">
          <legend style="font-weight:bold;color:dodgerblue; size: 60px" >Please select Method: Number of additional B/L needed</legend>
            <br>
            <div class="two fields">
               <div class = "field">
                 <div class="ui radio checkbox" >
                   <input name="splitType" type="radio"  [checked]="isAZ == true" (change)="changeEventAZ($event)"  checked="checked">
                   <label>Generate B/L with suffix (A-Z)</label>
                 </div>
               </div>
               <div class = "one field">
                 <label style="float: left;">Quantity &nbsp;</label>
                 <input type="number" name="splitQty" min="0" style="width:60%" oninput="this.value = Math.abs(this.value)" onkeydown="return (event.keyCode!=13);"   placeholder="Quantity" [(ngModel)]="model['splitQty']" >
                 <input type="hidden" name="sumGridQty" [(ngModel)]="model['sumGridQty']">
               </div>
            </div>
            <div class = "field">
              <div class="ui radio checkbox" >
                <input name="splitType" type="radio"    [checked]="isNumber == true" (change)="changeEventNo($event)"  checked="checked">

                <label>Generate B/L with New Number</label>
              </div>
            </div>
          </fieldset>
        </div>
        <div class = "field">
          <fieldset style="border: 1px solid lightgray;">
          <legend style="font-weight:bold;color:dodgerblue;">Please select Method to use</legend>
            <br>
            <div class = "field">
              <div class="ui radio checkbox" >
                <input type="radio" name="method"   [checked]="freight == true" checked="checked">
                  <label>Freight in Main B/L</label>
              </div>
            </div>
            <div style = "height:30px"></div>
            <div class = "field">
              <div class="ui radio checkbox" >
                <input type="radio" name="method"  [checked]="prorate == true"  checked="checked">
                  <label>Prorate per B/L Basis</label>
              </div>
            </div>
          </fieldset>
        </div>
      </div>




     <fieldset style="border: 1px solid lightgray;">
       <legend style="font-weight:bold;color:dodgerblue;">Quantity in Main Booking Reference</legend>
       <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
       <div class="field" style="width: 100%;height: 40% ">
         <grid-plugin id="gridQty" #gridQty [settings]="settingGridQty" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)" ></grid-plugin>
       </div>

     </fieldset>
     <br>


                <fieldset style="border: 1px solid lightgray;">

                      <legend style="color:dodgerblue;margin-right:300px;" >After Split</legend>
                  <div class="inline field">
                    <div class="inline fields" align="right">
                    <label style="color:dodgerblue;" align="right">Total Quantity</label>  <input readonly type="text" name="aftersplitQty" value="{{model['aftersplitQty']}}" style="width:auto;"   placeholder="Quantity" [(ngModel)]="model['aftersplitQty']" >
                    </div>
                    <br>
                    <div class="field" >
                      <grid-plugin id="GridSplitDetail" #gridSplitDetail [settings]="settingGridSplitDetail" (gridEvent)="gridEventSplitDetail($event)" (infoGrid)="infoGrid($event)"style="height:20px" ></grid-plugin>
                    </div>


                  </div>

                  <div class="field" >
                    <grid-plugin id="gridMain" #gridMain [settings]="settingGridMain" (gridEvent)="eventGridMain($event)" (infoGrid)="infoGrid($event)"style="height:20px" ></grid-plugin>

                  </div>
                  <!--
                 <div class="field">
                   <div class="inline fields">
                     <label style="color:dodgerblue;margin-right:300px;" >After Split</label>

                     <grid-plugin id="gridSplitDetail" #gridSplitDetail [settings]="settingGridSplitDetail" (gridEvent)="eventGridSplitDetail($event)" (infoGrid)="infoGridSplitDetail($event)"></grid-plugin>


                      <label style="color:dodgerblue;">Total Quantity</label>  <input readonly type="text" name="mainGrid" value="{{model['gridMain']}}" style="width:auto;"   placeholder="Quantity" [(ngModel)]="model['gridMain']" >
                    </div>
                    <fieldset>
                      <div class="field" style="overflow-y:scroll;height:250px">
                        <table class="ui sortable selectable compact celled definition striped table" cellspacing="0" cellpadding="0"  id="gridMain" #tblSplit>
                          <thead class="full-width" style="font-size:12px;">
                          <tr>
                            <th *ngFor="let col of settingGridMain.columns" width="{{col.width}}%">{{col.header}}</th>
                          </tr>
                          </thead>
                          <tbody style="font-size:12px;">
                          <tr  *ngFor="let data of splitStore">
                            <td>{{data.blNo}<input  type="hidden" id="seqId_{{data.blNo}}" name="seqId_{{data.seqnoNew}}"/></td>
                            <td>
                              <select class="sday" name="typeSize_{{data.seqnoNew}}" id="typeSize_{{data.seqnoNew}}" (change)="chkContainer(data.seqnoNew)">
                                <option *ngFor="let typ of typeSize" [selected]="typ == data.containerTypeNew" value="{{typ}}">{{typ}}</option>
                              </select>
                            </td>
                            <td> <select class="sday" name="cnSize_{{data.seqnoNew}}" id="cnSize_{{data.seqnoNew}}"  (change)="qtyBlur(data.seqnoNew)">
                              <option *ngFor="let dataSize of cntSz" [selected]="dataSize == data.containerSizeNew" value="{{dataSize}}">{{dataSize}}</option>
                            </select></td>
                            <td><input type="number" min="0" oninput="this.value = Math.abs(this.value)" class="quantyclass" onkeydown="return (event.keyCode!=13);" name="quanty_{{data.seqnoNew}}" id="quanty_{{data.seqnoNew}}" value="{{data.qtyNew}}" (blur)="qtyBlur(data.seqnoNew)" (keyup)="qtyBlur(data.seqnoNew)" (change)="qtyBlur(data.seqnoNew)"/></td>

                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </fieldset>
                  </div>

           </fieldset>
      -->
                </fieldset>

    </fieldset>

  </form>
</form>


     <footer-plugin [info]="info"></footer-plugin>
